import React, { useState } from "react";
import ExcelJS from "exceljs";
const UsersList = ({ users }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const pagesCount = Math.ceil(users.length / itemsPerPage);

  const start = currentPage * itemsPerPage;
  const currentUsers = users.slice(start, start + itemsPerPage);

  return (
    <div>
      <h2>Utilisateurs n'ayant jamais téléchargé l'appli</h2>

      <ul>
        {currentUsers.map((user, index) => (
          // Assurez-vous d'afficher les chaînes de caractères plutôt que des objets
          <li key={index}>{`${user.firstName} ${user.lastName}`}</li>
        ))}
      </ul>
      <button
        disabled={currentPage <= 0}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        Précédent
      </button>
      <button
        disabled={currentPage >= pagesCount - 1}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        Suivant
      </button>
      <div>
        <ExportCSV participants={users}></ExportCSV>
      </div>
    </div>
  );
};

export default UsersList;

const ExportCSV = ({ participants }) => {
  const createWorkbook = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Export");

    const headers = ["Nom", "Prénom", "Email"];

    worksheet.addRow(headers);
    worksheet.addRows(
      participants.map((contact) => {
        return [contact?.lastName, contact?.firstName, contact?.email];
      })
    );

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Export.xlsx";
    link.click();
  };

  return (
    <div className="" style={{ flexDirection: "row", marginTop: 10 }}>
      <button onClick={createWorkbook} className="">
        Exporter
      </button>
    </div>
  );
};
