import { useState } from "react";
import {
  Card,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import MultipleSelectChip from "./MultipleSelect";
import api from "../api";
import { CalendarModal } from "./CalendarModal";
import confirmAction from "../ConfirmAction";
import errorSwal, { successSwal } from "../swal";

const messageTypes = [
  {
    messageType: "sms",
    labelMessageType: "SMS",
  },
  {
    messageType: "notif",
    labelMessageType: "Notif",
  },
  {
    messageType: "both",
    labelMessageType: "Les 2",
  },
];

const redirectionFilters = [
  { value: "Home", name: "Home" },
  { value: "MonHotel", name: "MonHotel" },
  { value: "Baule", name: "Baule" },
  { value: "Profil", name: "Profil" },
  { value: "Galerie", name: "Galerie" },
  { value: "Portraits", name: "Portraits" },
  { value: "Course", name: "Course" },
  { value: "Programme", name: "Programme" },
  { value: "Quizz", name: "Quizz" },
  { value: "MonDiner", name: "MonDiner" },
  { value: "Atlantia", name: "Atlantia" },
  { value: "Crep'Party", name: "Crep'Party" },
];

const filterTransports = [
  { value: "Avion", name: "Avion" },
  { value: "Train", name: "Train" },
  { value: "Voiture", name: "Voiture" },
  { value: "Je ne sais pas", name: "Je ne sais pas" },
];

export default function CreateMessage({
  filterUsers,
  filterHotels,
  filterRestaurants,
}) {
  const [showModal, setShowModal] = useState(false);
  const [messageType, setmessageType] = useState("");
  const [transportsGo, setTransportsGo] = useState();
  const [transportsBack, setTransportsBack] = useState();
  const [wantsTransfertGo, setWantsTransfertGo] = useState();
  const [wantsTransfertBack, setWantsTransfertBack] = useState();
  const [hotels, setHotels] = useState();
  const [restaurants, setRestaurants] = useState();
  const [chosenUsers, setChosenUsers] = useState();
  const [ignoredUsers, setIgnoredUsers] = useState();
  const [redirection, setRedirection] = useState();
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [date, setDate] = useState();
  const [addNews, setAddNews] = useState(false);
  const [isCaptain, setIsCaptain] = useState();
  const [participateCourse, setParticipateCourse] = useState();
  const [hasHotel, setHasHotel] = useState();

  const handleChange = (event) => {
    setmessageType(event.target.value);
  };

  const submit = async () => {
    const data = {
      title,
      redirect: redirection?.[0],
      message,
      transportsGo: transportsGo,
      transportsBack: transportsBack,
      wantsTransfertGo: wantsTransfertGo?.[0],
      wantsTransfertBack: wantsTransfertBack?.[0],
      filterUser: chosenUsers,
      ignores: ignoredUsers,
      sendAt: date,
      addNews: addNews,
      hotels,
      restaurants,
      isCaptain: isCaptain?.[0],
      participateCourse: participateCourse?.[0],
      hasHotel: hasHotel?.[0],
    };

    if (["both", "sms"].includes(messageType)) {
      try {
        await api.post("/message/createSMS", data);
        if (date) {
          successSwal("Sms programmé");
        } else {
          successSwal("Sms envoyé");
        }
      } catch (e) {
        errorSwal(e);
      }
    }
    if (["both", "notif"].includes(messageType)) {
      try {
        await api.post("/message/createNotif", data);
        if (date) {
          successSwal("Notif programmé");
        } else {
          successSwal("Notif envoyé");
        }
      } catch (e) {
        errorSwal(e);
      }
    }
    reset();
    setShowModal(false);
  };

  const filterBoolean = [
    { name: "Oui", value: true },
    { name: "Non", value: false },
  ];

  const reset = () => {
    setmessageType(undefined);
    setTransportsGo(undefined);
    setTransportsBack(undefined);
    setChosenUsers(undefined);
    setIgnoredUsers(undefined);
    setRedirection(undefined);
    setTitle(undefined);
    setMessage(undefined);
    setDate(undefined);
    setAddNews(undefined);
    setWantsTransfertGo(undefined);
    setWantsTransfertBack(undefined);
    setHotels(undefined);
    setRestaurants(undefined);
    setHasHotel(undefined);
    setIsCaptain(undefined);
    setParticipateCourse(undefined);
  };
  return (
    <>
      <Card sx={{ padding: "0px" }} style={{ zIndex: 1 }}>
        <Paper>
          <CardContent
            sx={{
              paddingX: "20%",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography variant="h3" sx={{ paddingBottom: "30px" }}>
              Nouveau message
            </Typography>
            <FormControl fullWidth sx={{ paddingBottom: "20px" }}>
              <InputLabel>Type de message</InputLabel>
              <Select
                sx={{ height: "45px" }}
                label="Type de message"
                value={messageType}
                onChange={handleChange}
              >
                {messageTypes.map((option) => (
                  <MenuItem key={option.messageType} value={option.messageType}>
                    {option.labelMessageType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {messageType && (
              <>
                {["both", "notif"].includes(messageType) && (
                  <>
                    <TextField
                      onChange={(e) => setTitle(e.target.value)}
                      label="Titre du Message"
                      required
                      fullWidth
                      placeholder="Titre du message"
                    />
                  </>
                )}

                <TextField
                  label="Message"
                  required
                  fullWidth
                  multiline
                  rows={5}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                />

                {["both", "notif"].includes(messageType) && (
                  <>
                    <MultipleSelectChip
                      selectedFilters={redirection}
                      setSelectedFilters={setRedirection}
                      filters={redirectionFilters}
                      label="Redirection"
                    />
                  </>
                )}

                <Typography variant="h3" sx={{ paddingBottom: "30px" }}>
                  Filtres
                </Typography>

                <MultipleSelectChip
                  selectedFilters={transportsGo}
                  setSelectedFilters={setTransportsGo}
                  filters={filterTransports}
                  label="Transport Aller"
                />

                <MultipleSelectChip
                  selectedFilters={transportsBack}
                  setSelectedFilters={setTransportsBack}
                  filters={filterTransports}
                  label="Transport Retour"
                />

                <MultipleSelectChip
                  selectedFilters={wantsTransfertGo}
                  setSelectedFilters={setWantsTransfertGo}
                  filters={filterBoolean}
                  label="Transfert Aller"
                  multiple={false}
                />

                <MultipleSelectChip
                  selectedFilters={wantsTransfertBack}
                  setSelectedFilters={setWantsTransfertBack}
                  filters={filterBoolean}
                  label="Transfert Retour"
                  multiple={false}
                />

                <MultipleSelectChip
                  selectedFilters={restaurants}
                  setSelectedFilters={setRestaurants}
                  filters={filterRestaurants}
                  label="Restaurant"
                />

                <MultipleSelectChip
                  selectedFilters={hotels}
                  setSelectedFilters={setHotels}
                  filters={filterHotels}
                  label="Hotel"
                />

                <MultipleSelectChip
                  selectedFilters={chosenUsers}
                  setSelectedFilters={setChosenUsers}
                  filters={filterUsers}
                  label="Choisir un utilisateur"
                />

                <MultipleSelectChip
                  selectedFilters={isCaptain}
                  setSelectedFilters={setIsCaptain}
                  filters={filterBoolean}
                  label="Est capitaine"
                  multiple={false}
                />

                <MultipleSelectChip
                  selectedFilters={participateCourse}
                  setSelectedFilters={setParticipateCourse}
                  filters={filterBoolean}
                  label="Participe à la course"
                  multiple={false}
                />

                <MultipleSelectChip
                  selectedFilters={hasHotel}
                  setSelectedFilters={setHasHotel}
                  filters={filterBoolean}
                  label="A un hôtel"
                  multiple={false}
                />

                <MultipleSelectChip
                  selectedFilters={ignoredUsers}
                  setSelectedFilters={setIgnoredUsers}
                  filters={filterUsers}
                  label="Ignorer un utilisateur"
                />

                {/* <div className="checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      checked={addNews}
                      onChange={(e) => setAddNews(e.target.checked)}
                    />
                    Ajouter News
                  </label>
                </div> */}

                <Stack spacing={3} direction="row" sx={{ marginY: "50px" }}>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#004994" }}
                    onClick={() => {
                      confirmAction({
                        action: async () => {
                          submit();
                        },
                        promptText:
                          "Voulez vous envoyer le message immédiatement?",
                      });
                    }}
                  >
                    Envoyer
                  </Button>
                  <Button
                    onClick={() => setShowModal(true)}
                    variant="contained"
                    sx={{ backgroundColor: "#74CA43" }}
                  >
                    programmer
                  </Button>
                  <Button
                    onClick={() => {
                      reset();
                    }}
                    variant="contained"
                    sx={{ backgroundColor: "#F874CC" }}
                  >
                    Annuler
                  </Button>
                </Stack>
              </>
            )}
          </CardContent>
        </Paper>
      </Card>
      <CalendarModal
        modalIsOpen={showModal}
        closeModal={() => setShowModal(false)}
        submit={submit}
        date={date}
        setDate={setDate}
      />
    </>
  );
}
