import { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { FormSubTitle } from "./FormSubtitle";
import DatePicker from "react-datepicker";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { FaDeleteLeft } from "react-icons/fa6";
import { Button } from "@mui/material";
import api from "../api";
import errorSwal from "../swal";
export function EventModal({
  modalIsOpen,
  closeModal,
  event,
  transports,
  restaurants,
  hotels,
  refreshEvents,
}) {
  const [eventData, setEventData] = useState();
  const [base64, setBase64] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageName, setImageName] = useState(null);
  useEffect(() => {
    if (!event?.from) {
      setEventData(undefined);
      return;
    }
    if (event?.conditions?.length) {
      event.hasParams = true;
    }
    const tempEvent = _.cloneDeep(event);
    tempEvent.from.time = new Date(tempEvent.from.time);
    tempEvent.to.time = new Date(tempEvent.to.time);

    setEventData(tempEvent);
  }, [event]);

  useEffect(() => {
    if (!eventData) {
      return;
    }
    if (eventData?.isMandatory && eventData?.hasParams) {
      setEventData({ ...eventData, isMandatory: false });
    }
    if (eventData?.hasParams) {
      if (!eventData?.conditions) {
        setEventData({ ...eventData, conditions: [{}] });
      }
    } else {
      if (eventData?.conditions) {
        setEventData({ ...eventData, conditions: undefined });
      }
    }
  }, [eventData]);

  const customStyles = {
    content: {
      top: "10%",
      left: "20%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "60%",
      overflow: "scroll",
      height: "80%",
      alignContent: "center",
    },
  };

  const submit = async () => {
    try {
      await api.post(event?.from ? "/event/edit" : "/event/create", {
        _id: event?._id,
        ...eventData,
        imageData: base64,
        imageName,
      });
      setEventData(undefined);
      closeModal();
      setBase64("");
      setImageUrl("");
      setImageName("");
      refreshEvents();
    } catch (e) {
      errorSwal(e);
    }
  };

  const selectPicture = (e) => {
    e.preventDefault();
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.jpeg,.png";
    input.click();
    input.onchange = async function () {
      // @ts-ignore
      if (this.files[0]) {
        // @ts-ignore
        const file = this.files[0];
        const maxSizeInBytes = 2 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
          errorSwal("La taille maximum du fichier est 2MB");
        } else {
          const reader = new FileReader();

          reader.onload = function (e) {
            const base64Content = e.target.result;
            setBase64(base64Content);
          };

          reader.onerror = function (error) {
            console.log("Error reading file:", error);
          };

          reader.readAsDataURL(file);

          console.log(file);
          setImageUrl(URL.createObjectURL(file));
          setImageName(file.name);
        }
      }
    };
  };

  if (!event) {
    return;
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={customStyles}
    >
      <div className="div-global-form">
        <FormSubTitle style={{ justifyContent: "center" }} title="Event" />

        <div>
          <label className="input-form-photo">
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Selected"
                style={{ width: "20%", alignSelf: "center" }}
              />
            ) : (
              event.image && (
                <img
                  src={event.image}
                  alt="Selected"
                  style={{ width: "20%", alignSelf: "center" }}
                />
              )
            )}
            <button className="btn-photo" onClick={selectPicture}>
              {imageUrl ? "Modifier la photo" : "Ajouter une photo"}
              <FaPlus className="icon-plus" />
            </button>
          </label>
        </div>

        <div className="row">
          <Input
            label={"Titre"}
            value={eventData?.title}
            field={"title"}
            setEventData={setEventData}
          />
          <Input
            label={"Description"}
            value={eventData?.description}
            field={"description"}
            setEventData={setEventData}
          />
        </div>

        <p>Départ</p>
        <div className="row">
          <DatePickerInput
            label={"Date"}
            value={eventData?.from?.time}
            field={"from.time"}
            setEventData={setEventData}
          />
          <Input
            label={"Adresse"}
            value={eventData?.from?.location}
            field={"from.location"}
            setEventData={setEventData}
          />
        </div>
        <p>Arrivée</p>
        <div className="row">
          <DatePickerInput
            label={"Date"}
            value={eventData?.to?.time}
            field={"to.time"}
            setEventData={setEventData}
          />
          <Input
            label={"Adresse"}
            value={eventData?.to?.location}
            field={"to.location"}
            setEventData={setEventData}
          />
        </div>

        <p>Point de rendez-vous</p>
        <div className="row">
          <DatePickerInput
            label={"Date"}
            value={eventData?.meeting?.time}
            field={"meeting.time"}
            setEventData={setEventData}
          />
          <Input
            label={"Adresse"}
            value={eventData?.meeting?.location}
            field={"meeting.location"}
            setEventData={setEventData}
          />
          <Input // Nouveau champ pour le lien de réunion
            label={"Url Maps"}
            value={eventData?.meeting?.link} // Utilisez la nouvelle propriété link
            field={"meeting.link"} // Assurez-vous de mettre à jour le champ correct dans eventData
            setEventData={setEventData}
          />
        </div>

        <div className="row">
          <Input
            label={"Moment de la journée"}
            value={eventData?.timeOfTheDay}
            field={"timeOfTheDay"}
            setEventData={setEventData}
          />
        </div>

        <div className="row">
          <CheckboxInput
            label={"Ajouter des conditions"}
            value={eventData?.hasParams}
            field={"hasParams"}
            setEventData={setEventData}
          />
        </div>
        <div className="col">
          {eventData?.conditions?.map((_, i) => (
            <ConditionBlock
              key={i}
              transports={transports}
              restaurants={restaurants}
              hotels={hotels}
              eventData={eventData}
              index={i}
              setEventData={setEventData}
            ></ConditionBlock>
          ))}
          {eventData?.conditions?.length && (
            <button
              className="submit"
              onClick={() => {
                console.log("click");
                setEventData((prev) => {
                  if (!prev || !prev.conditions) {
                    return undefined;
                  }
                  prev?.conditions?.push({});
                  return { ...prev };
                });
              }}
            >
              Ajouter condition
            </button>
          )}
        </div>
        <div className="row">
          <CheckboxInput
            label={"Est obligatoire"}
            value={eventData?.isMandatory}
            field={"isMandatory"}
            setEventData={setEventData}
          />
        </div>

        <button className="submit" onClick={submit}>
          Confirmer
        </button>
      </div>
    </Modal>
  );
}

const Input = ({ label, value, field, setEventData }) => {
  return (
    <div style={{ width: "40%", margin: 10 }}>
      <label>{label}</label>
      <input
        width={"100%"}
        style={{
          width: "100%",
          backgroundColor: "#f5f8fa",
          border: "none",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 6,
          borderRadius: 10,
        }}
        value={value}
        onChange={(e) =>
          setEventData((prev) => {
            const newEventData = { ...prev };
            _.set(newEventData, field, e.target.value);
            return newEventData;
          })
        }
      ></input>
    </div>
  );
};

const DatePickerInput = ({ label, value, field, setEventData }) => {
  return (
    <div style={{ width: "40%", margin: 10 }}>
      <label>{label}</label>
      <DatePicker
        style={{
          width: "100%",
          backgroundColor: "#f5f8fa",
          border: "none",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 6,
          borderRadius: 10,
        }}
        selected={value || ""}
        showTimeSelect
        timeIntervals={5}
        dateFormat="dd/MM/yyyy HH:mm"
        onChange={(e) =>
          setEventData((prev) => {
            const newEventData = { ...prev };
            _.set(newEventData, field, e);
            return newEventData;
          })
        }
      />
    </div>
  );
};

const CheckboxInput = ({ label, value, field, setEventData }) => {
  return (
    <div style={{ width: "40%", margin: 10 }}>
      <label>{label}</label>
      <input
        type="checkbox"
        style={{
          marginRight: 8,
        }}
        checked={value}
        onChange={(e) => {
          setEventData((prev) => {
            const newEventData = { ...prev };
            _.set(newEventData, field, e.target.checked);
            return newEventData;
          });
        }}
      />
    </div>
  );
};

const DropdownInput = ({
  label,
  options,
  selectedValue,
  field,
  setEventData,
}) => {
  return (
    <div style={{ width: "40%", margin: 10 }}>
      <label>{label}</label>
      <select
        value={selectedValue}
        style={{
          width: "100%",
          backgroundColor: "#f5f8fa",
          border: "none",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 6,
          borderRadius: 10,
        }}
        onChange={(e) => {
          setEventData((prev) => {
            const newEventData = { ...prev };
            _.set(newEventData, field, e.target.value);
            return newEventData;
          });
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const MultiSelectDropdownInput = ({
  label,
  options,
  selectedValues,
  field,
  setEventData,
}) => {
  return (
    <div style={{ width: "40%", margin: 10 }}>
      <label>{label}</label>
      <select
        multiple
        value={selectedValues}
        style={{
          width: "100%",
          backgroundColor: "#f5f8fa",
          border: "none",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 6,
          borderRadius: 10,
          height: "auto",
        }}
        onChange={(e) => {
          const selectedOptionsArray = Array.from(e.target.selectedOptions).map(
            (option) => option.value
          );
          setEventData((prev) => {
            const newEventData = { ...prev };
            _.set(newEventData, field, selectedOptionsArray);
            return newEventData;
          });
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const ConditionBlock = ({
  setEventData,
  index,
  eventData,
  restaurants,
  hotels,
}) => {
  const options = [
    { value: "transportGo", label: "Transport Aller" },
    { value: "transportBack", label: "Transport Retour" },
    { value: "restaurant", label: "Restaurant" },
    { value: "hotel", label: "Hotel" },
    { value: "wantToBookAnHotel", label: "Veut une chambre d'hôtel" },
    { value: "wantsTransfertGo", label: "Veut le transfert à l'aller" },
    { value: "wantsTransfertBack", label: "Veut le transfert au retour" },
  ];
  const [optionValues, setOptionValues] = useState([]);

  useEffect(() => {
    setOptionValues(
      getOptions({
        key: eventData.conditions[index].key,

        restaurants,
        hotels,
      })
    );
  }, [eventData.conditions[index].key]);

  return (
    <div className="row">
      <DropdownInput
        label={"Condition sur"}
        options={options}
        selectedValue={eventData.conditions[index].key}
        field={`conditions[${index}].key`}
        setEventData={setEventData}
      ></DropdownInput>

      <MultiSelectDropdownInput
        label={"Valeurs"}
        options={optionValues}
        selectedValues={eventData.conditions[index].value}
        field={`conditions[${index}].value`}
        setEventData={setEventData}
      />

      <Button
        onClick={() => {
          console.log("here");
          setEventData((prev) => {
            prev.conditions.splice(index, 1);
            return { ...prev };
          });
        }}
      >
        <FaDeleteLeft style={{ color: "red" }}></FaDeleteLeft>
      </Button>
    </div>
  );
};

const getOptions = ({ key, restaurants, hotels }) => {
  if (key == "restaurant") {
    return restaurants.map((a) => ({ label: a.name, value: a._id }));
  }

  if (key == "hotel") {
    return hotels.map((a) => ({ label: a.name, value: a._id }));
  }

  if (["transportGo", "transportBack"].includes(key)) {
    return [
      {
        value: "Voiture",
        label: "Voiture",
      },

      {
        value: "Avion",
        label: "Avion",
      },

      {
        value: "Train",
        label: "Train",
      },
      {
        value: "Je ne sais pas",
        label: "Je ne sais pas",
      },
    ];
  }

  if (
    ["wantsTransfertBack", "wantsTransfertGo", "wantToBookAnHotel"].includes(
      key
    )
  ) {
    return [
      {
        value: true,
        label: "Oui",
      },

      {
        value: false,
        label: "Non",
      },
    ];
  }

  return [];
};
