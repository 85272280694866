import "./Form.css";
import logo from "./img/logo.png";
import { useForm } from "react-hook-form";
import { useState } from "react";
import api from "./api";
import swal from "./swal";
import { useLocation, useNavigate } from "react-router-dom";

export default function MentionLegales() {
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: "center" }}>
      <img className="logo" src={logo} onClick={() => navigate("/")}></img>

      <h1 className="h1-title">Mentions légales</h1>
      <h2 className="h2-title-name">Éditeur :</h2>
      <h3 className="h3-title">
        Arkone, SAS au capital de 10 000 €<br />
        Siège social : 99, rue de la République, 92800 Puteaux, France
        <br />
        RCS Nanterre : 892 975 368
        <br />
        Directeur de la publication : Sébastien Roques
        <br />
        <br />
        <br />
        <br />
      </h3>
      <h2 className="h2-title-name">Hébergement :</h2>
      <h3 className="h3-title">
        Ce site est hébergé par OVH SAS :
        <br />
        RCS Lille : 424 761 419
        <br />2 Rue Kellermann 59100 ROUBAIX, FRANCE
        <br />
        <br />
        <br />
        <br />
      </h3>

      <h3>Contact référent Groupe Pomona : f.sambourg@groupe-pomona.fr</h3>

      <footer>
        <button className="mention" onClick={() => navigate("/")}>
          Accueil
        </button>
        <button className="mention" onClick={() => navigate("/legalnotice")}>
          Mentions Légales
        </button>
        <button
          className="mention"
          onClick={() => window.open("https://arkone.fr", "_blank")}
        >
          Conçu et développé par Arkone
        </button>
      </footer>
    </div>
  );
}
