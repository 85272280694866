import {
  Card,
  CardContent,
  Paper,
  Typography,
  Stack,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import api from "../api";
import confirmAction from "../ConfirmAction";
import { FaTrash } from "react-icons/fa6";
import { useEffect, useState } from "react";

export default function Structure({ structure, pages }) {
  const [currentStructure, setCurrentStructure] = useState([]);

  const saveLines = async () => {
    confirmAction({
      action: async () => {
        const lines = currentStructure
          ?.filter((l) => !l.isPriority)
          ?.map((l) => l.pages);
        const linesPriority = currentStructure
          ?.filter((l) => l.isPriority)
          ?.map((l) => l.pages)[0];

        await api.post("/structure/edit", {
          lines,
          linesPriority,
        });
      },
      promptText: "Voulez-vous modifier cette page ?",
      confirmText: "Informations modifiées",
    });
  };

  const setPriority = ({ isPriority, index }) => {
    setCurrentStructure((prev) => {
      if (isPriority) {
        prev.map((l) => (l.isPriority = false));
      }
      prev[index].isPriority = isPriority;
      const newLines = prev.map((l) => ({
        ...l,
        pages: l?.pages ? [...l?.pages] : undefined,
      }));
      return [...newLines];
    });
  };

  useEffect(() => {
    setCurrentStructure(structure);
  }, [structure]);

  const addLine = () => {
    setCurrentStructure((prev) => {
      if (!prev) {
        prev = [];
      }
      prev.push({
        isPriority: false,
        __id: Math.max(...prev.map((e) => e.__id), 0) + 1,
      });
      return [...prev];
    });
  };

  const removeLine = (index) => {
    setCurrentStructure((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const addItem = (index) => {
    setCurrentStructure((prev) => {
      if (!prev[index].pages) {
        prev[index].pages = [];
      }
      prev[index].pages.push({
        __id: Math.max(...prev[index].pages.map((e) => e.__id), 0) + 1,
      });
      return [...prev];
    });
  };

  const removeItem = ({ index, itemIndex }) => {
    setCurrentStructure((prev) => {
      prev[index].pages.splice(itemIndex, 1);
      return [...prev];
    });
  };

  const setItem = ({ lineIndex, itemIndex, field, value }) => {
    setCurrentStructure((prev) => {
      prev[lineIndex].pages[itemIndex][field] = value;
      return [...prev];
    });
  };

  return (
    <Card style={{ marginTop: 5 }}>
      <Paper sx={{ display: "flex" }}>
        <CardContent
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "column",
            flex: 15,
          }}
        >
          <Stack padding={2}>
            {currentStructure?.map((t, i) => (
              <Line
                key={`${i}${t?.[0]?.__id || i}`}
                index={i}
                line={t}
                addItem={addItem}
                removeItem={removeItem}
                removeLine={removeLine}
                setItem={setItem}
                pages={pages}
                setPriority={setPriority}
              ></Line>
            ))}
          </Stack>
          <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
            <Button
              onClick={() => addLine()}
              variant="contained"
              sx={{ backgroundColor: "#74CA43" }}
            >
              Ajouter une ligne
            </Button>
          </Stack>
          <Stack spacing={3} direction="row" sx={{ marginY: "50px" }}>
            <Button
              onClick={() => saveLines()}
              variant="contained"
              sx={{ backgroundColor: "#74CA43" }}
            >
              Enregistrer
            </Button>
          </Stack>
        </CardContent>
      </Paper>
    </Card>
  );
}

const Line = ({
  line,
  addItem,
  removeItem,
  removeLine,
  index,
  setItem,
  pages,
  setPriority,
}) => {
  return (
    <Stack marginBottom={2} spacing={2}>
      <Accordion style={{ padding: 10, margin: 5 }}>
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          <Stack spacing={5} direction="row" marginBottom={1}>
            <IconButton
              sx={{
                backgroundColor: "#D7DCE1",
                padding: "5px",
                borderRadius: "5px",
              }}
              aria-label="supprimer"
              onClick={() => {
                removeLine(index);
              }}
            >
              <FaTrash />
            </IconButton>
            <Typography variant="h6">
              Ligne {line.isPriority ? "Prioritaire" : index + 1}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <FormControlLabel
              control={<Switch checked={line.isPriority} />}
              label="Prioritaire"
              value={line.isPriority}
              onChange={(e) => {
                setPriority({ index, isPriority: !line.isPriority });
              }}
            />
            {line?.pages?.map((p, i) => (
              <Page
                key={p.__id}
                page={p}
                removeItem={removeItem}
                lineIndex={index}
                itemIndex={i}
                pages={pages}
                setItem={setItem}
              />
            ))}
          </Stack>
          <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
            <Button
              onClick={() => addItem(index)}
              variant="contained"
              sx={{ backgroundColor: "#74CA43" }}
            >
              Ajouter une page
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const Page = ({ page, removeItem, lineIndex, itemIndex, pages, setItem }) => {
  return (
    <Stack spacing={5} direction="row" marginBottom={1}>
      <IconButton
        sx={{
          backgroundColor: "#D7DCE1",
          padding: "5px",
          borderRadius: "5px",
        }}
        aria-label="supprimer"
        onClick={() => {
          removeItem({ index: lineIndex, itemIndex });
        }}
      >
        <FaTrash />
      </IconButton>
      <Typography variant="h6">Page {itemIndex + 1}</Typography>
      <FormControl fullWidth>
        <Select
          value={page.pageId}
          label="Page"
          placeholder="Page"
          onChange={(e) => {
            setItem({
              field: "pageId",
              value: e.target.value,
              lineIndex,
              itemIndex,
            });
          }}
        >
          {pages?.map((p) => (
            <MenuItem value={p._id}>{p.buttonName}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
