import DatePicker from "react-datepicker";
import { Modal, Paper, Stack, Button } from "@mui/material";
import _ from "lodash";
import confirmAction from "../ConfirmAction";
import errorSwal from "../swal";
export function CalendarModal({
  modalIsOpen,
  closeModal,
  submit,
  date,
  setDate,
}) {
  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={modalIsOpen}
      onClose={closeModal}
    >
      <Paper sx={{ width: "40%", padding: "30px 50px" }}>
        <div>Date d'envoi</div>
        <DatePickerInput label={""} value={date} setEventData={setDate} />
        <Stack spacing={3} direction="row" sx={{ marginY: "50px" }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#F874CC" }}
            onClick={closeModal}
          >
            Annuler
          </Button>

          <Button
            variant="contained"
            sx={{ backgroundColor: "#004994" }}
            onClick={() => {
              if (!date) {
                errorSwal("La date d'envoi est manquante");
              } else {
                closeModal();
                confirmAction({
                  action: async () => {
                    submit();
                  },
                  promptText: "Voulez vous programmer le message ?",
                  confirmText: "Le message a bien été programmé",
                });
              }
            }}
          >
            programmer
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
}

const DatePickerInput = ({ label, value, setEventData }) => {
  return (
    <div style={{ width: "40%", margin: 10 }}>
      <label>{label}</label>
      <DatePicker
        style={{
          width: "100%",
          backgroundColor: "#f5f8fa",
          border: "none",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 6,
          borderRadius: 10,
        }}
        selected={value || ""}
        showTimeSelect
        timeIntervals={5}
        dateFormat="dd/MM/yyyy HH:mm"
        onChange={(e) => setEventData(e)}
      />
    </div>
  );
};
