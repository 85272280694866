import { create } from "zustand";
import { persist } from "zustand/middleware";
import api from "./api";

const useAuthStore = create()(
  persist(
    (set, get) => ({
      currentUser: null,
      isConnected: !!get()?.currentUser,
      login: (currentUser) => set({ currentUser }),
      logout: () => set({ currentUser: null }),
    }),
    {
      name: "auth-store",
    }
  )
);

const token = useAuthStore.getState().currentUser?.token;
if (token) {
  api.defaults.headers.common.Authorization = `${token}`;
}

useAuthStore.subscribe((state) => {
  if (state.currentUser === null) {
    delete api.defaults.headers.common.Authorization;
  } else {
    api.defaults.headers.common.Authorization = `${state.currentUser.token}`;
  }
});

export default useAuthStore;
