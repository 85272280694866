import { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import "./Backoffice.css";
import { FaPenToSquare, FaDeleteLeft, FaRegCopy } from "react-icons/fa6";
import api from "./api";
import moment from "moment";
import { EventModal } from "./EventPage/EventModal";
import { ConfirmModal } from "./EventPage/ConfirmModal";
import errorSwal from "./swal";
import "moment/locale/fr"; // Importez les données locales françaises pour moment

const EventCard = ({
  event,
  setEventEditing,
  transports,
  restaurants,
  hotels,
  setShowModalDelete,
  setShowModalDuplicate,
}) => {
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    setBase64Image(""); // Réinitialiser l'image à chaque changement d'événement

    const getImage = async () => {
      if (event.image) {
        try {
          const pictureRes = await api.get(`programPictures/${event.image}`);
          setBase64Image(`data:image/jpeg;base64,${pictureRes.data}`);
        } catch (e) {
          console.error("Erreur lors de la récupération de l'image : ", e);
        }
      }
    };

    getImage();
  }, [event]);

  return (
    <tr className="transition-colors duration-200 hover:bg-gray-200">
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          <p>{event?.timeOfTheDay}</p>
        </div>
      </td>
      <td
        className={`px-6 py-4 p-5 fs-small ${
          event?.timeOfTheDay ? "text-gray" : ""
        }`}
      >
        <div className="text-sm text-gray-900 text-center">
          <p>{event?.from?.location}</p>
          <p>
            {moment(event?.from?.time)
              .locale("fr")
              .format("DD/MM/YYYY à HH[h]mm")}
          </p>
        </div>
      </td>
      <td
        className={`px-6 py-4 p-5 fs-small ${
          event?.timeOfTheDay ? "text-gray" : ""
        }`}
      >
        <div className="text-sm text-gray-900 text-center">
          <p>{event?.to?.location}</p>{" "}
          <p>
            {moment(event?.to?.time)
              .locale("fr")
              .format("DD/MM/YYYY à HH[h]mm")}
          </p>
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          <p>{event?.title}</p>
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          <p>{event?.description}</p>
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          <p>
            {event?.meeting?.time
              ? moment(event.meeting.time)
                  .locale("fr")
                  .format("DD/MM/YYYY à HH[h]mm")
              : ""}
          </p>
          <p>{event?.meeting?.location || ""}</p>
          <p>{event?.meeting?.link || ""}</p>
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {base64Image && (
            <img
              src={base64Image}
              alt=""
              style={{
                maxWidth: 200,
              }}
            />
          )}
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {event.conditions.map((c, i) => (
            <p key={i}>
              {c.key} :{" "}
              {c.value
                .map((v) =>
                  getConditionsValue({
                    key: c.key,
                    val: v,
                    transports,
                    restaurants,
                    hotels,
                  })
                )
                .join(", ")}
            </p>
          ))}
        </div>
      </td>

      <td
        className="px-4 py-4 text-right text-sm font-medium row"
        style={{ gap: 10 }}
      >
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={async () => {
            setEventEditing(event);
          }}
          title="Modifiez les informations ."
        >
          <FaPenToSquare />
        </button>
        <button
          className="text-red-600 hover:text-red-900 mr-2 p-5"
          onClick={() => {
            setShowModalDelete(event._id);
          }}
          title="Supprimer."
        >
          <FaDeleteLeft />
        </button>

        <button
          className="text-green-600 hover:text-red-900 mr-2 p-5"
          onClick={async () => {
            setShowModalDuplicate(event._id);
          }}
          title="Duppliquer"
        >
          <FaRegCopy />
        </button>
      </td>
    </tr>
  );
};

const EventList = ({
  events,
  filteredEvents,
  setFilteredEvents,
  setEventEditing,
  transports,
  restaurants,
  hotels,
  setShowModalDelete,
  setShowModalDuplicate,
}) => {
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const refreshGroupSearch = async () => {
    let newList = [];
    if (search !== "") {
      newList = events?.filter(
        (group) =>
          group?.company?.toLowerCase().includes(search.toLowerCase()) ||
          group?.city?.toLowerCase().includes(search.toLowerCase()) ||
          group?.email?.toLowerCase().includes(search.toLowerCase()) ||
          group?.role?.toLowerCase().includes(search.toLowerCase())
      );
    } else if (events && events.length) {
      newList = events;
    }
    setFilteredEvents(newList);
  };

  useEffect(() => {
    refreshGroupSearch();
  }, [search]);

  const columns = useMemo(
    () => [
      {
        Header: "Moment",
        accessor: "a",
      },
      {
        Header: "Départ",
        accessor: (row) => moment(row.from.time).format("YYYY-MM-DD HH:mm:ss"), // Utilisez un format qui permet le tri correct
        sortType: "basic", // ou 'datetime', si vous avez un comparateur personnalisé
      },
      {
        Header: "Arrivée",
        accessor: (row) => moment(row.to.time).format("YYYY-MM-DD HH:mm:ss"), // Utilisez un format qui permet le tri correct
        sortType: "basic", // ou 'datetime', si vous avez un comparateur personnalisé
      },
      {
        Header: "Titre",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: (row) => row?.description || "-", // Assurez-vous que 'description' correspond au champ correct de vos données d'événement
      },
      {
        Header: "Meeting",
        accessor: (row) => {
          const meetingTime = row.meeting?.time
            ? moment(row.meeting.time)
                .locale("fr")
                .format("DD/MM/YYYY à HH[h]mm")
            : "-";
          const meetingLocation = row.meeting?.location
            ? row.meeting.location
            : "-";
          return `${meetingTime}, ${meetingLocation}`;
        },
      },
      {
        Header: "Image",
        accessor: "d",
      },
      {
        Header: "Conditions",
        accessor: "e",
      },
      {
        Header: "Actions",
        accessor: "f",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredEvents,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="flex flex-col body-backoffice">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full ">
            <div className="row" style={{ marginRight: 20 }}>
              <label htmlFor="search" style={{ flex: 2 }}>
                <input
                  id="Recherche"
                  placeholder="Recherche"
                  type="text"
                  className="search-bar"
                  onChange={handleSearch}
                />
              </label>

              <div style={{ flex: 1 }}>
                <button
                  onClick={() => setEventEditing({})}
                  className="button-Backoffice"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Nouveau
                </button>
              </div>
            </div>
          </div>
          <div className="shadow overflow-hidden border-gray-200 sm:rounded-lg table">
            <table
              className="min-w-full divide-y divide-gray-200"
              style={{ width: "100%" }}
              {...getTableProps()}
            >
              <thead className="bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider p-5"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-gray-200"
                {...getTableBodyProps()}
              >
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <EventCard
                      setShowModalDuplicate={setShowModalDuplicate}
                      setShowModalDelete={setShowModalDelete}
                      transports={transports}
                      restaurants={restaurants}
                      hotels={hotels}
                      key={key}
                      event={row.original}
                      setEventEditing={setEventEditing}
                    />
                  );
                })}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="button-backoffice"
              >
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="button-backoffice"
              >
                {"<"}
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="button-backoffice"
              >
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="button-backoffice"
              >
                {">>"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>

              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="ml-4 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Afficher {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Event() {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [transports, setTransports] = useState([]);
  const [eventEditing, setEventEditing] = useState();
  const [showModalDelete, setShowModalDelete] = useState();
  const [showModalDuplicate, setShowModalDuplicate] = useState();
  const refreshEvents = async () => {
    try {
      const events = await api.get("/event/get");
      setEvents(events.data);
      setFilteredEvents(events.data);
    } catch (error) {
      errorSwal("Une erreur s'est produite lors de la récupération des events");
      console.error("Error fetching data: ", error);
    }
  };

  const refreshRestaurants = async () => {
    try {
      const restaurants = await api.get("/admin/restaurants");
      setRestaurants(restaurants.data);
    } catch (error) {
      errorSwal(
        "Une erreur s'est produite lors de la récupération des restaurants"
      );
      console.error("Error fetching data: ", error);
    }
  };

  const refreshHotels = async () => {
    try {
      const hotels = await api.get("/admin/hotels");
      setHotels(hotels.data);
    } catch (error) {
      errorSwal("Une erreur s'est produite lors de la récupération des hôtels");
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    refreshEvents();
    refreshHotels();
    refreshRestaurants();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {/* <LeftMenu></LeftMenu> */}
      <div style={{ flex: 6, marginTop: 40 }}>
        <ConfirmModal
          modalIsOpen={showModalDelete != undefined}
          closeModal={() => setShowModalDelete(undefined)}
          confirm={async () => {
            try {
              await api.post("/event/delete", { id: showModalDelete });
              setShowModalDelete(undefined);
              refreshEvents();
            } catch (e) {
              errorSwal(e);
            }
          }}
          text={"Voulez vous vraiment supprimer cet Event ?"}
        ></ConfirmModal>
        <ConfirmModal
          modalIsOpen={showModalDuplicate != undefined}
          closeModal={() => setShowModalDuplicate(undefined)}
          confirm={async () => {
            try {
              await api.post("/event/duplicate", { id: showModalDuplicate });
              setShowModalDuplicate(undefined);
              refreshEvents();
            } catch (e) {
              errorSwal(e);
            }
          }}
          text={"Voulez vous vraiment dupliquer cet Event ?"}
        ></ConfirmModal>
        <EventModal
          hotels={hotels}
          restaurants={restaurants}
          modalIsOpen={eventEditing != undefined}
          closeModal={() => setEventEditing(undefined)}
          event={eventEditing}
          refreshEvents={refreshEvents}
        ></EventModal>
        <EventList
          setShowModalDuplicate={setShowModalDuplicate}
          setShowModalDelete={setShowModalDelete}
          setEventEditing={setEventEditing}
          events={events}
          filteredEvents={filteredEvents}
          setFilteredEvents={setFilteredEvents}
          restaurants={restaurants}
          hotels={hotels}
        />
      </div>
    </div>
  );
}

const getConditionsValue = ({ key, val, restaurants, hotels, transports }) => {
  if (key == "restaurant") {
    return restaurants?.find((a) => a._id === val)?.name;
  }

  if (key == "hotel") {
    return hotels?.find((a) => a._id === val)?.name;
  }

  if (["transportGo", "transportBack"].includes(key)) {
    return {
      Voiture: "Voiture",
      Avion: "Avion",
      Train: "Train",
      "Je ne sais pas": "Je ne sais pas",
    }[val];
  }

  if (
    ["wantsTransfertBack", "wantsTransfertGo", "wantToBookAnHotel"].includes(
      key
    )
  ) {
    return {
      true: "Oui",
      false: "Non",
    }[val];
  }
  return [];
};
