import Modal from "react-modal";
import swal, { successSwal } from "../swal";
import api from "../api";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";
export function AddAlbumsModal({
  modalIsOpen,
  refresh,
  closeModal,
  currentFilters,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [filters, setFilters] = useState([]);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await api.post("/album/create", {
        imagesData: imagesData.map((d) => d.split("base64,")[1]),
        filters: filters?.map((f) => f.value),
      });
      successSwal("Photo(s) ajouté");
      closeModal?.();
      refresh();
    } catch (e) {
      swal(e);
    } finally {
      setIsLoading(false);
    }
  };

  const customStyles = {
    content: {
      top: "10%",
      left: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "40%",
      overflow: "scroll",
      height: "50%",
      alignContent: "center",
    },
  };

  const selectPictures = (e) => {
    e.preventDefault();
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = ".jpg,.jpeg,.png";
    input.click();
    input.onchange = async function () {
      console.log(this.files);
      const maxSizeInBytes = 2 * 1024 * 1024;
      if ([...this.files].some((f) => f.size > maxSizeInBytes)) {
        swal("La taille maximum d'un fichier est 2MB");
      }

      [...this.files].map((f) => {
        if (f.size > maxSizeInBytes) {
          return;
        }
        const reader = new FileReader();

        reader.onload = function (e) {
          const base64Content = e.target.result;
          setImagesData((prev) => {
            prev.push(base64Content);
            return [...prev];
          });
        };

        reader.onerror = function (error) {
          console.log("Error reading file:", error);
        };

        reader.readAsDataURL(f);
      });
    };
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div style={{ textAlign: "center" }}>
        <div className="line-form">
          <div className="div-line-form2">
            <button
              disabled={isLoading}
              className="submit"
              onClick={selectPictures}
            >
              Sélectionner des photos
            </button>
          </div>
          <div className="div-line-form2">
            <p>{imagesData?.length || 0} photo(s) sélectionnée(s)</p>
          </div>
        </div>

        <div style={{ width: "80%", marginBottom: 80, marginLeft: "10%" }}>
          <CreatableSelect
            options={currentFilters}
            isMulti
            onChange={(selectedOptions) => {
              setFilters(selectedOptions);
            }}
            onCreateOption={(inputValue) => {
              const newOption = {
                label: inputValue,
                value: inputValue.toLowerCase(),
              };
              setFilters([...filters, newOption]);
            }}
            value={filters}
            placeholder={"Choisissez des filtres"}
          />
        </div>

        <div>
          <button disabled={isLoading} className="submit" onClick={onSubmit}>
            Valider
          </button>
        </div>
      </div>
    </Modal>
  );
}
