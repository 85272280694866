import { useEffect, useState } from "react";
import "./Backoffice.css";
import api from "./api";
import LeftMenu from "./Admin/LeftMenu";
import errorSwal from "./swal";
import StatsGraphs from "./Admin/StatsGraphs";
import UsersList from "./EventPage/UsersList";

export default function StatsApp() {
  const [appStats, setAppStats] = useState();

  const getAppData = async () => {
    try {
      const { data } = await api.get("/statsApp");
      setAppStats(data);
    } catch (error) {
      errorSwal(
        "Une erreur s'est produite lors de la récupération des statistiques de l'application"
      );
      console.error("Error fetching app stats: ", error);
    }
  };

  useEffect(() => {
    getAppData();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LeftMenu></LeftMenu>
      <div style={{ flex: 6 }}>
        <div className="row" style={{ gap: 20, margin: 20 }}>
          <Card>
            <h2>Statistiques de l'Application : </h2>
            <p>
              Nombre d'utilisateurs connectés : {appStats?.totalUsersConnected}
            </p>
            <p>Nombre total d'utilisations : {appStats?.totalAppUses}</p>
            <p>
              Utilisateurs n'ayant jamais téléchargé l'app :{" "}
              {appStats?.usersNeverDownloaded.length}
            </p>
            <p>Nombre d'ouvertures de l'app : {appStats?.appOpenings}</p>
            {/* Nouveau paragraphe pour le nombre de visiteurs uniques par jour, si nécessaire */}
          </Card>
          <StatsGraphs appStats={appStats} />
          <UsersList users={appStats?.usersNeverDownloaded || []} />
        </div>
      </div>
    </div>
  );
}

export const Card = (props: any) => {
  return <div className="card-stat">{props.children}</div>;
};
