import "./Form.css";
import Headers from "./Header.js";
import Form from "./Form.js";
export default function Home() {
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <Headers></Headers>
      </div>

      <div className="citation-container">
        <p className="citation-italic">
          "Je vous donne rendez-vous le 25 mars à 17h00 pour la nouvelle édition
          de la « Convention Pomona » au cours de laquelle l’ensemble du Comité
          Exécutif et moi-même ferons le point sur la situation, les enjeux et
          les priorités de notre Groupe"
        </p>
        <p className="citation">Eric Dumont – Président du Directoire </p>
        <hr></hr>
      </div>
      {/* <Form></Form> */}

      <footer>
        {/* <a className="mention" href="/">
          Accueil
        </a> */}
        <a className="mention" href="/cgu">
          Cgu
        </a>

        <a className="mention" href="/legalnotice">
          Mentions Légales
        </a>

        <a className="mention" href="/https://arkone.fr">
          Conçu et développé par Arkone
        </a>
      </footer>
    </div>
  );
}
