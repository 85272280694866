export function FormSubTitle({ title, style }) {
  return (
    <div
      className="row form-subtitle-bloc"
      style={{ ...style, whiteSpace: "pre-line" }}
    >
      <p>{title}</p>
    </div>
  );
}
