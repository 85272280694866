import errorSwal, { confirmationSwal, successSwal } from "./swal";
export default async ({ action, promptText, confirmText }) => {
  const { isConfirmed } = await confirmationSwal(promptText, "");
  if (isConfirmed) {
    try {
      await action();
      successSwal(confirmText);
    } catch (e) {
      errorSwal(e);
    }
  }
};
