import {
  Card,
  CardContent,
  Paper,
  Typography,
  Box,
  Stack,
  TextField,
  Button,
} from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import api from "../api";
import confirmAction from "../ConfirmAction";
import { useEffect, useMemo, useState } from "react";

export default function Structure({ restaurants, colors }) {
  const [currentColors, setCurrentColors] = useState([]);

  const saveColors = async () => {
    confirmAction({
      action: async () => {
        await api.post("/colors/edit", {
          colors: currentColors,
        });
      },
      promptText: "Voulez-vous modifier cette page ?",
      confirmText: "Informations modifiées",
    });
  };

  useEffect(() => {
    setCurrentColors(colors);
  }, [colors]);

  const setItem = ({ field, value, key }) => {
    setCurrentColors((prev) => {
      const curr = prev.find((p) => p.colorKey === key);
      curr[field] = value;
      return [...prev];
    });
  };

  return (
    <Card style={{ marginTop: 5 }}>
      <Paper sx={{ display: "flex" }}>
        <CardContent
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "column",
            flex: 15,
          }}
        >
          <Stack padding={2}>
            {colors?.map((t, i) => (
              <ColorBlock
                key={t.colorKey}
                color={t}
                restaurants={restaurants}
                setItem={setItem}
              ></ColorBlock>
            ))}
          </Stack>

          <Stack spacing={3} direction="row" sx={{ marginY: "50px" }}>
            <Button
              onClick={() => saveColors()}
              variant="contained"
              sx={{ backgroundColor: "#74CA43" }}
            >
              Enregistrer
            </Button>
          </Stack>
        </CardContent>
      </Paper>
    </Card>
  );
}

const ColorBlock = ({ color, restaurants, setItem }) => {
  const keys = [
    "primary",
    "secondary",
    "third",
    "fourth",
    "primaryWaiting",
    "secondaryWaiting",
    "thirdWaiting",
    "fourthWaiting",
  ];

  const name = useMemo(
    () => restaurants?.find((r) => r._id === color.colorKey)?.name,
    [color, restaurants]
  );

  return (
    <Stack marginBottom={2} spacing={2}>
      <Accordion style={{ padding: 10, margin: 5 }}>
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          <Stack spacing={5} direction="row" marginBottom={1}>
            <Typography variant="h6">
              {name ? `Restaurant : ${name}` : "Default"}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {keys.map((k) => (
              <Stack
                direction={"row"}
                spacing={5}
                alignContent={"center"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <TextField
                  key={k}
                  onChange={(e) =>
                    setItem({
                      field: k,
                      value: e.target.value,
                      key: color.colorKey,
                    })
                  }
                  label={k}
                  required
                  fullWidth
                  placeholder={k}
                  value={color[k]}
                />
                <Box
                  style={{
                    width: 80,
                    height: 80,
                    backgroundColor: color[k],
                    borderRadius: 10,
                  }}
                ></Box>
              </Stack>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
