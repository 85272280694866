import { useState } from "react";
import api from "./api";
import useAuthStore from "./useAuthStore";
import { useNavigate } from "react-router-dom";
import errorSwal from "./swal";

export const ButtonOrange = ({ buttonText, onClick, isLoading, style }) => {
  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className="button-orange"
      style={style}
    >
      {buttonText}
    </button>
  );
};

export default function AdminLogin() {
  return (
    <div>
      <LogIn></LogIn>
    </div>
  );
}

function LogIn() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useAuthStore((state) => state.login);
  const loginClick = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const res = await api.get("/login", {
        params: { email, password },
      });
      login({ ...res.data, role: "admin" });
      navigate("/backoffice");
    } catch (e) {
      errorSwal(e);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bloc-jaune">
      <div className="connexion">
        <form style={{ width: "100%" }}>
          <h2 style={{ textDecoration: "none" }}> Connexion</h2>
          <div>
            <label className="label-login">
              Email
              <input
                className="input-30"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </label>
          </div>

          <div style={{ marginTop: "20px" }}>
            <label className="label-login">
              Password
              <input
                className="input-30"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </label>
          </div>
          <div className="input-connexion">
            <ButtonOrange
              isLoading={isLoading}
              onClick={loginClick}
              buttonText="Connexion"
            ></ButtonOrange>
          </div>
        </form>
      </div>
    </div>
  );
}
