import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./Backoffice.css";
import api from "./api";
import Modal from "react-modal";
import moment from "moment";
import swal, { successSwal } from "./swal";
import DatePicker from "react-datepicker";
import "./Transport.css";

const transports = {
  requiredKeys: [
    "transportGo",
    "transportBack",
    "arrivesAt",
    "arrivesBefore",
    "leavesAfter",
    "leavesAt",
    "wantsTransfertGo",
    "wantsTransfertBack",
  ],
  title: "Quel(s) moyen(s) de transport utiliserez-vous ? ",
  textDifferentTransport:
    "J’utilise un moyen de transport différent à l’Aller et au Retour",
  values: {
    Avion: "Avion",
    Train: "Train",
    Voiture: "Voiture",
  },
  dataGo: [
    {
      displayValues: [[{ key: "transportGo", value: "Avion" }]],
      title: "Précisez votre heure d’arrivée",
      options: [
        [
          {
            type: "input",
            key: "arrivesAt",
            triggerKey: "arrivesBefore",
          },
        ],
      ],
    },

    {
      displayValues: [[{ key: "transportGo", value: "Train" }]],
      title:
        "Heure d'arrivée prévue à la gare de La Baule-Escoublac le lundi 25 mars :",
      options: [
        [
          {
            type: "radio",
            errorKey: "“Je souhaite bénéficier du transfert”",
            key: "arrivesOnTimeTrain",
            options: [
              {
                title:
                  "J’arrive à 15h49 à la gare de La Baule-Escoublac. Un transfert est prévu pour desservir tous les hôtels depuis la gare.",
                value: true,
              },
              {
                title:
                  "J’arrive à un autre horaire. Nous vous remercions d’organiser votre transfert et vous donnons directement rendez-vous à votre hôtel entre 15h et 17h00.",
                value: false,
              },
            ],
          },
        ],
      ],
    },

    {
      displayValues: [[{ key: "transportGo", value: "Avion" }]],
      options: [
        [
          {
            type: "text",
            title:
              "Nous prévoyons des transferts depuis l’aéroport Nantes Atlantique entre 13h30 et 14h30. Nous vous informerons ultérieurement des horaires précis.",
          },
        ],
      ],
    },

    {
      displayValues: [
        [
          { key: "transportGo", value: "Train" },
          { key: "arrivesOnTimeTrain", value: true },
        ],
        [{ key: "transportGo", value: "Avion" }],
      ],
      title: "Je souhaite bénéficier du transfert :",
      options: [
        [
          {
            type: "radio",
            key: "wantsTransfertGo",
            errorKey: "“Je souhaite bénéficier du transfert”",
            options: [
              {
                title: "OUI",
                value: true,
              },
              {
                title: "NON",
                value: false,
              },
            ],
          },
        ],
      ],
    },
  ],
  dataBack: [
    {
      displayValues: [[{ key: "transportBack", value: "Avion" }]],
      title:
        "Heure de départ prévue de l'aéroport Nantes Atlantique le mardi 26 mars :",
      options: [
        [
          {
            type: "input",
            key: "leavesAt",
            triggerKey: "leavesAfter",
          },
          {
            type: "checkbox",
            title: "Je prolonge mon séjour",
            key: "leavesAfter",
            triggerKey: "leavesAt",
          },
        ],
      ],
    },
    {
      displayValues: [[{ key: "transportBack", value: "Train" }]],
      title:
        "Heure de départ prévue de la gare de La Baule-Escoublac le mardi 26 mars :",
      options: [
        [
          {
            type: "input",
            key: "leavesAt",
            triggerKey: "leavesAfter",
          },
          {
            type: "checkbox",
            title: "Je prolonge mon séjour",
            key: "leavesAfter",
            triggerKey: "leavesAt",
          },
        ],
      ],
    },

    {
      displayValues: [
        [
          { key: "transportBack", value: "Train" },
          { key: "leavesAfter", value: false },
        ],
      ],
      options: [
        [
          {
            type: "text",
            title:
              "Pour votre retour, un transfert est prévu à 15h45 du Palais des Congrès Atlantia pour vous rendre à la gare de La Baule-Escoublac (arrivée prévue avant 16h15).",
          },
        ],
      ],
    },
    {
      displayValues: [
        [
          { key: "transportBack", value: "Avion" },
          { key: "leavesAfter", value: false },
        ],
      ],
      options: [
        [
          {
            type: "text",
            title:
              "Pour votre retour, un transfert est prévu à 15h45 du Palais des Congrès Atlantia pour vous rendre à l'aéroport Nantes Atlantique (arrivée prévue avant 17h30).",
          },
        ],
      ],
    },
    {
      displayValues: [
        [
          { key: "transportBack", value: "Train" },
          { key: "leavesAfter", value: false },
        ],
        [
          { key: "transportBack", value: "Avion" },
          { key: "leavesAfter", value: false },
        ],
      ],
      title: "Je souhaite bénéficier du transfert :",
      options: [
        [
          {
            type: "radio",
            key: "wantsTransfertBack",
            errorKey: "“Je souhaite bénéficier du transfert”",
            options: [
              {
                title: "OUI",
                value: true,
              },
              {
                title: "NON",
                value: false,
              },
            ],
          },
        ],
      ],
    },
  ],
};
function renderTextWithLineBreaks(text) {
  return text.split("\n").map((line, index, array) => (
    <React.Fragment key={index}>
      {line}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
}

export default function TransportModalAdmin({
  modalIsOpen,
  closeModal,
  participant,
}) {
  const customStyles = {
    content: {
      top: "10%",
      left: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "50%",
      overflow: "scroll",
      height: "80%",
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      transportGo: "",
      transportBack: "",
      differentAllerRetour: false,
      arrivesAt: "",
      needParking: false,
      arrivesBefore: false,
      leavesAfter: false,
      leavesAt: "",
      wantsTransfertGo: undefined,
      wantsTransfertBack: undefined,
    },
  });

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const errorMessage =
        errors[errorKeys[0]]?.message || "Erreur dans le formulaire";
      swal(errorMessage);
    }
  }, [errors]);

  useEffect(() => {
    if (!participant) {
      return;
    }
    setValue("transportGo", participant.transportGo);
    setValue("transportBack", participant.transportBack);
    setValue(
      "differentAllerRetour",
      participant.transportBack !== participant.transportGo
    );
    if (participant.arrivesAt) {
      const dateArrives = new Date();
      const [hoursArrives, minutesArrives] = participant.arrivesAt
        ?.split(":")
        .map(Number);
      if (hoursArrives !== undefined && minutesArrives !== undefined)
        dateArrives.setHours(hoursArrives, minutesArrives, 0, 0);
      setValue("arrivesAt", dateArrives);
    }
    setValue("needParking", participant.needParking);
    setValue("arrivesBefore", participant.arrivesBefore);
    setValue("leavesAfter", participant.leavesAfter);
    setValue("arrivesOnTimeTrain", participant.arrivesOnTimeTrain);
    setValue("wantsTransfertGo", participant.wantsTransfertGo);
    setValue("wantsTransfertBack", participant.wantsTransfertBack);
    if (participant.leavesAt) {
      const dateTemp = new Date();
      const [hours, minutes] = participant.leavesAt?.split(":").map(Number);
      if (hours !== undefined && minutes !== undefined)
        dateTemp.setHours(hours, minutes, 0, 0);
      setValue("leavesAt", dateTemp);
    }
  }, [participant]);

  const onSubmit = async (data) => {
    try {
      let errorMessage = "";

      if (!data.transportGo) {
        errorMessage =
          "Le champ 'Quel moyen de transport prenez-vous à l'aller ?' est obligatoire.";
        swal(errorMessage);
        return;
      }

      if (
        (data.transportBack === "Train" || data.transportBack === "Avion") &&
        !data.leavesAfter &&
        !data.leavesAt
      ) {
        errorMessage =
          "Veuillez fournir l'heure de départ ou cocher 'Je prolonge mon séjour' pour le retour en train ou en avion.";
        swal(errorMessage);
        return;
      }

      await api.post("/admin/editTransportsWeb", {
        _id: participant._id,
        ...data,
        arrivesAt: data?.arrivesAt
          ? moment(data?.arrivesAt).format("HH:mm")
          : undefined,
        leavesAt: data?.leavesAt
          ? moment(data?.leavesAt).format("HH:mm")
          : undefined,
      });

      successSwal("Vos informations ont bien été enregistrées");
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire : ", error);
      swal(
        "Une erreur s'est produite lors de la modification de vos informations."
      );
    }
  };

  const onSubmitAttempt = async () => {
    const result = await trigger();
    if (!result) {
      const errorKeys = Object.keys(errors);
      if (errorKeys.length > 0) {
        const firstErrorKey = errorKeys[0];
        const errorMessage =
          errors[firstErrorKey]?.message || "Erreur dans le formulaire";
        swal(errorMessage);
      }
    } else {
      onSubmit(watch());
    }
  };

  const renderOption = (option) => {
    switch (option.type) {
      case "input":
        return (
          <Controller
            control={control}
            name={option.key}
            render={({ field }) => (
              <div className="transport-line-form">
                <div className="transport-div-line-form2">
                  <DatePicker
                    selected={field?.value}
                    onChange={(date) => field.onChange(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption="Heure"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    className="transport-input-form"
                  />
                </div>
              </div>
            )}
          />
        );
      case "checkbox":
        return (
          <label className="transport-label-check">
            {option.title && renderTextWithLineBreaks(option.title)}
            <Controller
              control={control}
              name={option.key}
              render={({ field }) => (
                <input
                  type="checkbox"
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </label>
        );
      case "radio":
        return option.options.map((radioOption, index) => (
          <label
            className="transport-label-check"
            key={index}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Controller
              control={control}
              name={option.key}
              rules={{
                validate: (value) =>
                  value !== undefined ||
                  `Ce champ ${option.errorKey} est obligatoire`,
              }}
              render={({ field }) => (
                <input
                  type="radio"
                  value={radioOption.value}
                  checked={field.value === radioOption.value}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val === "false") val = false;
                    if (val === "true") val = true;
                    field.onChange(val);
                  }}
                />
              )}
            />
            {radioOption.title}
          </label>
        ));
      case "text":
        return (
          <label className="transport-label-check">
            {option.title && renderTextWithLineBreaks(option.title)}
          </label>
        );
      default:
        return null;
    }
  };

  const differentAllerRetour = watch("differentAllerRetour");
  const transportGo = watch("transportGo");
  const transportBack = watch("transportBack");

  const leavesAfter = watch("leavesAfter");
  const leavesAt = watch("leavesAt");
  const arrivesBefore = watch("arrivesBefore");
  const arrivesAt = watch("arrivesAt");

  useEffect(() => {
    if (leavesAfter) {
      setValue("leavesAt", "");
    }
  }, [leavesAfter]);

  useEffect(() => {
    if (leavesAt) {
      setValue("leavesAfter", false);
    }
  }, [leavesAt]);

  useEffect(() => {
    if (arrivesBefore) {
      setValue("arrivesAt", "");
    }
  }, [arrivesBefore]);

  useEffect(() => {
    if (arrivesAt) {
      setValue("arrivesBefore", false);
    }
  }, [arrivesAt]);

  useEffect(() => {
    if (transportGo && !differentAllerRetour) {
      setValue("transportBack", transportGo);
    }
  }, [transportGo, differentAllerRetour]);

  const shouldDisplayOption = (displayValues, transportType) => {
    return displayValues.some((options) => {
      return options.every(({ key, value }) => {
        if (value === "notNull") {
          return watch(key) !== undefined && watch(key) !== null;
        }
        return watch(key) === value;
      });
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit, onSubmitAttempt)}>
          <div className="transport-form-div">
            <div className="transport-line-form">
              <p className="transport-text-form">{transports.title}</p>
            </div>

            <div>
              {differentAllerRetour && (
                <p
                  className="transport-text-form"
                  style={{ marginTop: 40, textDecoration: "underline" }}
                >
                  Aller
                </p>
              )}
              {Object.entries(transports.values).map(([value, title]) => (
                <label className="transport-label-check" key={value}>
                  <Controller
                    control={control}
                    name="transportGo"
                    rules={{ required: "Ce champ est obligatoire" }}
                    render={({ field }) => (
                      <input
                        type="radio"
                        value={value}
                        checked={field.value === value}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  {title}
                </label>
              ))}
            </div>

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "start",
                gap: "40px",
              }}
            >
              <label
                className="transport-label-check"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {transports.textDifferentTransport}
              </label>
              <label
                className="transport-label-check"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={"differentAllerRetour"}
              >
                Non
                <Controller
                  control={control}
                  name="differentAllerRetour"
                  render={({ field }) => (
                    <input
                      type="radio"
                      value={false}
                      checked={field.value === false}
                      onChange={(e) => field.onChange(false)}
                    />
                  )}
                />
              </label>
              <label
                className="transport-label-check"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={"differentAllerRetour"}
              >
                Oui
                <Controller
                  control={control}
                  name="differentAllerRetour"
                  render={({ field }) => (
                    <input
                      style={{ display: "flex" }}
                      type="radio"
                      value={true}
                      checked={field.value === true}
                      onChange={(e) => field.onChange(true)}
                    />
                  )}
                />
              </label>
            </div>

            {!differentAllerRetour && transportGo !== "Voiture" && (
              <p
                className="transport-text-form"
                style={{ marginTop: 40, textDecoration: "underline" }}
              >
                Aller
              </p>
            )}
            {transports.dataGo.map(
              (section, index) =>
                (shouldDisplayOption(section.displayValues, transportGo) ||
                  shouldDisplayOption(
                    section.displayValues,
                    transportBack
                  )) && (
                  <React.Fragment key={index}>
                    <p className="transport-text-form">{section.title}</p>
                    {section.options.map((optionGroup, groupIndex) => (
                      <div
                        key={groupIndex}
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "center",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        {optionGroup.map((option, optionIndex) => (
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "space-around",
                              flex: 1,
                            }}
                            key={optionIndex}
                          >
                            {renderOption(option)}
                          </div>
                        ))}
                      </div>
                    ))}
                  </React.Fragment>
                )
            )}

            {differentAllerRetour && (
              <div>
                <p
                  className="transport-text-form"
                  style={{ marginTop: 40, textDecoration: "underline" }}
                >
                  Retour
                </p>

                {Object.entries(transports.values).map(([value, title]) => (
                  <label className="transport-label-check" key={value}>
                    <Controller
                      control={control}
                      name="transportBack"
                      rules={{ required: "Ce champ est obligatoire" }}
                      render={({ field }) => (
                        <input
                          type="radio"
                          value={value}
                          checked={field.value === value}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      )}
                    />
                    {title}
                  </label>
                ))}
              </div>
            )}
            {!differentAllerRetour && transportBack !== "Voiture" && (
              <p
                className="transport-text-form"
                style={{ marginTop: 40, textDecoration: "underline" }}
              >
                Retour
              </p>
            )}
            {transports.dataBack.map(
              (section, index) =>
                (shouldDisplayOption(section.displayValues, transportGo) ||
                  shouldDisplayOption(
                    section.displayValues,
                    transportBack
                  )) && (
                  <React.Fragment key={index}>
                    <p className="transport-text-form">{section.title}</p>
                    {section.options.map((optionGroup, groupIndex) => (
                      <div
                        key={groupIndex}
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "center",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        {optionGroup.map((option, optionIndex) => (
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "space-around",
                              flex: 1,
                            }}
                            key={optionIndex}
                          >
                            {renderOption(option)}
                          </div>
                        ))}
                      </div>
                    ))}
                  </React.Fragment>
                )
            )}

            <button type="submit" className="transport-submit">
              Valider
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
