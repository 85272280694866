import { useLocation } from "react-router";
import logoHorizontal from "../img/logo-convention.png";
import "./LeftMenu.css";
import useAuthStore from "../useAuthStore";
export default function LeftMenu() {
  const location = useLocation();
  const { currentUser, logout } = useAuthStore();
  console.log(currentUser);
  return (
    <div className="div-menu-left">
      <div className="menu-logo-container">
        <img className="menu-logo" src={logoHorizontal}></img>
      </div>

      <a
        href="/backoffice"
        className={location.pathname === "/backoffice" ? "active-link" : "link"}
      >
        Listing
      </a>

      <a
        href="/stats-page"
        className={location.pathname === "/stats-page" ? "active-link" : "link"}
      >
        Stats Application
      </a>
    </div>
  );
}
