import Swal from "sweetalert2";

const errorSwal = (text) =>
  Swal.fire({
    icon: "error",
    iconColor: "#FC6E51",
    title: "",
    text: text,
    confirmButtonColor: "#005F8A",
    confirmButtonText: "OK",
  });

export const successSwal = (text) =>
  Swal.fire({
    icon: "success",
    iconColor: "#005F8A",
    title: "",
    text: text,
    confirmButtonColor: "#005F8A",
    confirmButtonText: "OK",
  });

export const confirmationSwal = (title, text) =>
  Swal.fire({
    title,
    text,
    icon: "warning",
    iconColor: "#FC6E51",
    showCancelButton: true,
    confirmButtonColor: "#005F8A",
    cancelButtonColor: "#FC6E51",
    cancelButtonText: "Annuler",
    confirmButtonText: "Oui",
  });

export const redirectSwalFr = ({ text, url }) =>
  Swal.fire({
    icon: "error",
    iconColor: "#FC6E51",
    title: "",
    html: `${text} <br><a href="${url}">Cliquez ici pour aller sur le bon formulaire</a>`,
    confirmButtonColor: "#005F8A",
    confirmButtonText: "OK",
  });

export const redirectSwalEN = ({ text, url }) =>
  Swal.fire({
    icon: "error",
    iconColor: "#FC6E51",
    title: "",
    html: `${text} <br><a href="${url}">Click here to go to the correct form</a>`,
    confirmButtonColor: "#005F8A",
    confirmButtonText: "OK",
  });

export const confirmDeleteSwal = () =>
  Swal.fire({
    title: "Supprimer ?",
    text: "Voulez vous vraiment supprimer ce participant ?",
    icon: "warning",
    iconColor: "#FC6E51",
    showCancelButton: true,
    confirmButtonColor: "#005F8A",
    cancelButtonColor: "#FC6E51",
    confirmButtonText: "Oui, supprimer",
  });

export const editEmailSwal = async () =>
  Swal.fire({
    title: "Modifier une adresse email",
    input: "email",
    inputLabel: "Nouvelle adresse email :",
    inputPlaceholder: "Entrer la nouvelle adresse email",
    confirmButtonColor: "#005F8A",
  });

export default errorSwal;
