import "./Form.css";
import { useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "./api";
import swal, { successSwal } from "./swal";
import sizeChartImage from "../src/img/Tailles.jpg";
import useAuhthStore from "./useAuthStore.js";
import { FaEye } from "react-icons/fa";
export default function Form({ id, closeModal }) {
  const { login, currentUser } = useAuhthStore();

  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      users: [
        {
          sendEmail: "true",
          _id: undefined,
          firstName: undefined,
          lastName: undefined,
          password: undefined,
          email: undefined,
          passwordConfirm: undefined,
          isParticipating: undefined,
          wantToBookAnHotel: undefined,
          phoneNumber: undefined,
          size: undefined,
          remarks: undefined,
          conquestFields: undefined,
          companySection: undefined,
          city: undefined,
          wantsDoubleRoom: false,
          guestName: undefined,
          hasRegistered: undefined,
          isSuperAdmin: false,
          medicalInfo: undefined,
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "users",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const userFields = {
        firstName: "Prénom",
        lastName: "Nom",
        email: "Email",
        phoneNumber: "Numéro de téléphone",
        companySection: "Branche/Direction fonctionnelle",
        city: "Site/Succursale",
        isParticipating: "Participation",
        // conquestFields: "L'esprit de conquête en 2/3 mots",
      };
      const conditionalField = {
        password: {
          text: "Mot de passe",
          condition: (u) => u.isParticipating === "true" && !u.hasRegistered,
        },
        passwordConfirm: {
          text: "Confirmation mot de passe",
          condition: (u) => u.isParticipating === "true" && !u.hasRegistered,
        },

        wantToBookAnHotel: {
          text: "Hébergement",
          condition: (u) => u.isParticipating === "true",
        },
        size: {
          text: "Taille",
          condition: (u) => u.isParticipating === "true",
        },

        wantsDoubleRoom: {
          text: "Chambre double",
          condition: (u) =>
            u.isParticipating === "true" && u.wantToBookAnHotel === "true",
        },
        guestName: {
          text: "Partager ma chambre",
          condition: (u) =>
            u.isParticipating === "true" &&
            u.wantsDoubleRoom === "true" &&
            ["true", true].includes(u.wantToBookAnHotel),
        },
      };
      const validFields = {
        phoneNumber: {
          validator: (val) => val.split(" ").join("").length < 10,
          text: "Numéro de téléphone",
        },
      };
      for (const user of data.users) {
        for (const field in userFields) {
          if (user[field] === undefined || user[field] === "") {
            swal(
              `L'information "${
                userFields[field]
              }" est manquante. Vérifier votre saisie${
                field === "email"
                  ? " et si le problème persiste, contacter f.sambourg@groupe-pomona.fr."
                  : "."
              }`
            );
            setIsLoading(false);
            return;
          }
        }
        for (const field in conditionalField) {
          console.log(conditionalField);
          if (
            (user[field] === undefined || user[field] === "") &&
            conditionalField[field].condition(user)
          ) {
            swal(
              `L'information  "${conditionalField[field].text}" est manquante. Vérifier votre saisie`
            );
            setIsLoading(false);
            return;
          }
        }
        for (const field in validFields) {
          if (user[field] != undefined && user[field] != "") {
            if (validFields[field].validator(user[field])) {
              swal(
                `L'information  "${validFields[field].text}" est invalide. Vérifier votre saisie`
              );
              setIsLoading(false);
              return;
            }
          }
        }
        if (user.password !== user.passwordConfirm && !user?.hasRegistered) {
          swal(`Les mots de passe sont différents`);
          return;
        }
      }
      data.users = data?.users.map((u) => ({
        ...u,
        isParticipating: ["true", true].includes(u.isParticipating),
        wantsDoubleRoom: ["true", true].includes(u.wantsDoubleRoom),
        wantToBookAnHotel: ["true", true].includes(u.wantToBookAnHotel),
        sendEmail: ["true", true].includes(u.sendEmail),
        isSuperAdmin: ["true", true].includes(u.isSuperAdmin),
        conquestFields: "-",
      }));
      await api.post("/inscription", { ...data });
      console.log(isParticipating, "isParticipating");
      if (["true", true].includes(isParticipating)) {
        successSwal(
          "Merci pour votre inscription. Vous allez recevoir un email de confirmation."
        );
      } else {
        successSwal(
          "Merci pour votre réponse. Vous allez recevoir un email de confirmation."
        );
      }
      closeModal?.();
    } catch (e) {
      swal(e);
    } finally {
      setIsLoading(false);
    }
  };

  const setUserFieldValue = (index, field, value) => {
    setValue(`users[${index}].${field}`, value);
  };

  const isParticipating = watch(`users[0].isParticipating`);
  const wantsDoubleRoom = watch(`users[0].wantsDoubleRoom`);
  const wantToBookAnHotel = watch(`users[0].wantToBookAnHotel`);
  const hasRegistered = watch(`users[0].hasRegistered`);
  useEffect(() => {
    const getInfo = async () => {
      try {
        let user;

        if (id) {
          user = await api.get("/getUserInfo", { params: { id } });
        } else if (currentUser?.tokenUser) {
          user = await api.get("/getInfo", {
            headers: { Authorization: currentUser?.tokenUser },
          });
        }

        if (!user) {
          return;
        }
        setUserFieldValue(0, "_id", user.data._id);
        setUserFieldValue(0, "hasRegistered", user.data.status == 2);

        setUserFieldValue(0, "firstName", user.data.firstName);
        setUserFieldValue(0, "lastName", user.data.lastName);
        setUserFieldValue(0, "email", user.data.email);
        setUserFieldValue(0, "medicalInfo", user.data.medicalInfo);
        setUserFieldValue(
          0,
          "isParticipating",
          user.data.isParticipating ? "true" : "false"
        );
        setUserFieldValue(
          0,
          "wantToBookAnHotel",
          user.data.wantToBookAnHotel ? "true" : "false"
        );
        setUserFieldValue(
          0,
          "isSuperAdmin",
          user.data.isSuperAdmin ? "true" : "false"
        );
        setUserFieldValue(
          0,
          "phoneNumber",
          formatPhoneNumber(user.data.phoneNumber)
        );
        setUserFieldValue(0, "size", user.data.size);
        setUserFieldValue(0, "remarks", user.data.remarks);
        setUserFieldValue(0, "conquestFields", user.data.conquestFields);
        setUserFieldValue(0, "companySection", user.data.companySection);
        setUserFieldValue(0, "city", user.data.city);
        setUserFieldValue(
          0,
          "wantsDoubleRoom",
          user.data.wantsDoubleRoom === true ||
            user.data.wantsDoubleRoom === "true"
        );
        setUserFieldValue(0, "guestName", user.data.guestName);
      } catch (e) {
        swal(e);
      }
    };
    getInfo();
  }, [currentUser]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      login({ tokenUser: token });
    }
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    let phoneNumber = value.replace(/[^\d+]/g, "");

    return phoneNumber;
  };

  return (
    <div style={{ textAlign: "center" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-div">
          {fields.map((field, index) => (
            <div key={index}>
              <div className="line-form">
                <p className="text-form">Informations personnelles</p>
              </div>
              <div className="line-form">
                <div className="div-line-form2">
                  <label className="label-check">Nom</label>
                  <Controller
                    name={`users[${index}].lastName`}
                    control={control}
                    defaultValue={field.lastName}
                    render={({ field }) => (
                      <input {...field} className="input-form" type="text" />
                    )}
                  />
                </div>
                <div className="div-line-form">
                  <label className="label-check">Prénom</label>
                  <Controller
                    name={`users[${index}].firstName`}
                    control={control}
                    defaultValue={field.firstName}
                    render={({ field }) => (
                      <input {...field} className="input-form" type="text" />
                    )}
                  />
                </div>
              </div>
              <div className="line-form">
                <div className="div-line-form2">
                  <label className="label-check">Email</label>
                  <Controller
                    name={`users[${index}].email`}
                    control={control}
                    defaultValue={field.email}
                    render={({ field }) => (
                      <input {...field} className="input-form" type="email" />
                    )}
                  />
                </div>
                <div className="div-line-form">
                  <label className="label-check">
                    Numéro de téléphone portable
                  </label>
                  <Controller
                    name={`users[${index}].phoneNumber`}
                    control={control}
                    defaultValue={field.phoneNumber}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="input-form"
                        type="text"
                        value={formatPhoneNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatPhoneNumber(e.target.value))
                        }
                      />
                    )}
                  />
                </div>
              </div>
              {!hasRegistered && (
                <div className="line-form">
                  <div className="div-line-form2">
                    <label className="label-check">
                      Mot de passe (au moins 8 caractères){" "}
                    </label>
                    <Controller
                      name={`users[${index}].password`}
                      control={control}
                      defaultValue={field.password}
                      render={({ field }) => (
                        <div className="password-container">
                          <input
                            {...field}
                            className="input-form-password"
                            type={passwordShown ? "text" : "password"}
                          />
                          <i class="eye-icon" onClick={togglePassword}>
                            <FaEye />
                          </i>
                        </div>
                      )}
                    />
                  </div>
                  <div className="div-line-form">
                    <label className="label-check">
                      Confirmez le mot de passe
                    </label>
                    <Controller
                      name={`users[${index}].passwordConfirm`}
                      control={control}
                      defaultValue={field.passwordConfirm}
                      render={({ field }) => (
                        <div className="password-container">
                          <input
                            {...field}
                            className="input-form-password"
                            type={passwordShown ? "text" : "password"}
                          />
                          <i class="eye-icon" onClick={togglePassword}>
                            <FaEye />
                          </i>
                        </div>
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="line-form">
                <div className="div-line-form">
                  <label className="label-check">Branche</label>
                  <Controller
                    name={`users[${index}].companySection`}
                    control={control}
                    defaultValue={field.companySection}
                    render={({ field }) => (
                      <select
                        {...field}
                        defaultValue={field.companySection}
                        placeholder="Branche"
                        className="input-form"
                        type="text"
                      >
                        {[
                          <option
                            className="option-disabled"
                            disabled
                            selected
                            value={""}
                          >
                            Sélectionnez votre Branche/Direction fonctionnelle
                          </option>,
                          ...[
                            "Biofinesse",
                            "Conseil de Surveillance",
                            "DAF",
                            "DDCOM",
                            "DIS",
                            "Délice & Création",
                            "DQRSE",
                            "DRH",
                            "DSI",
                            "EpiSaveurs",
                            "PassionFroid",
                            "Pomona Export",
                            "Pomona Import",
                            "Pomona Foodtrade",
                            "Pomona Iberia",
                            "Pomona Suisse",
                            "Présidence",
                            "Relais d'Or",
                            "Refaly",
                            "Saveurs d'Antoine",
                            "TerreAzur",
                          ].map((val, i) => (
                            <option key={i} value={val}>
                              {val}
                            </option>
                          )),
                        ]}
                      </select>
                    )}
                  />
                </div>
                <div className="div-line-form">
                  <label className="label-check">Site/Succursale </label>
                  <Controller
                    name={`users[${index}].city`}
                    control={control}
                    defaultValue={field.city}
                    render={({ field }) => (
                      <input
                        {...field}
                        // placeholder="Ville"
                        className="input-form"
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="line-form-solo">
                <div style={{ width: "100%", flexDirection: "column" }}>
                  <label className="label-check">Remarque particulière</label>
                  <Controller
                    name={`users[${index}].remarks`}
                    control={control}
                    defaultValue={field.remarks}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        placeholder="Remarque particulière"
                        className="input-form-solo"
                        type="textarea"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="line-form">
                <p className="text-form">événement</p>
              </div>
              {/* <div className="line-form-solo">
                <div
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <label className="label-check-2">
                    Nous souhaitons recueillir votre avis. <br />
                    Selon vous, comment l'<i>Esprit de conquête</i> peut se
                    traduire pour le Groupe ? Saisir 2/3 mots.
                  </label>
                  <Controller
                    name={`users[${index}].conquestFields`}
                    control={control}
                    defaultValue={field.conquestFields}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        placeholder="L'esprit de conquête en 2/3 mots"
                        className="input-form-solo"
                        type="textarea"
                      />
                    )}
                  />
                </div>
              </div> */}
              <div className="line-form">
                <label className="label-check">
                  Je participerai :
                  <Controller
                    name={`users[${index}].isParticipating`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <label>
                          <input
                            {...field}
                            type="radio"
                            value="true"
                            checked={field.value === "true"}
                          />
                          Oui
                        </label>
                        <label>
                          <input
                            {...field}
                            type="radio"
                            value="false"
                            checked={field.value === "false"}
                          />
                          Non
                        </label>
                      </>
                    )}
                  />
                </label>
              </div>
              {isParticipating === "true" && (
                <div className="line-form">
                  <div className="div-line-form">
                    <label className="label-check">
                      Indiquez votre taille (Haut du corps)
                      <div className="size-info">
                        <button
                          type="button"
                          className="info-button"
                          onClick={() => {
                            setShowSizeChart((val) => !val);
                          }} // pour mobile
                          onMouseOver={() => setShowSizeChart(true)} // pour ordinateur
                        >
                          ?
                        </button>
                      </div>
                      {showSizeChart && (
                        <div className="size-chart-popup">
                          <img src={sizeChartImage} alt="Tableau des tailles" />
                          <span>Fermer</span>
                        </div>
                      )}
                    </label>
                    <Controller
                      name={`users[${index}].size`}
                      control={control}
                      defaultValue={field.companySection}
                      placeholder="Comment venez vous ?"
                      render={({ field }) => (
                        <>
                          <select
                            {...field}
                            className="input-form"
                            type="text"
                            style={{ width: "100%" }}
                          >
                            {[
                              <option value="" disabled selected></option>,
                              ...[
                                "FEMME-S",
                                "FEMME-M",
                                "FEMME-L",
                                "FEMME-XL",
                                "FEMME-XXL",
                                "FEMME-3XL",
                                "HOMME-S",
                                "HOMME-M",
                                "HOMME-L",
                                "HOMME-XL",
                                "HOMME-XXL",
                                "HOMME-3XL",
                              ].map((val, i) => (
                                <option key={i} value={val}>
                                  {val}
                                </option>
                              )),
                            ]}
                          </select>
                        </>
                      )}
                    />
                  </div>
                </div>
              )}
              {isParticipating === "true" && (
                <div className="line-form-solo">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <label className="label-check">
                      Je souhaite réserver une chambre d'hôtel pour la nuit du
                      25 au 26 mars :
                      <Controller
                        name={`users[${index}].wantToBookAnHotel`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <label>
                              <input
                                {...field}
                                type="radio"
                                value="true"
                                checked={field.value === "true"}
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                {...field}
                                type="radio"
                                value="false"
                                checked={field.value === "false"}
                              />
                              Non
                            </label>
                          </>
                        )}
                      />
                    </label>
                  </div>
                </div>
              )}

              {isParticipating === "true" && wantToBookAnHotel === "true" && (
                <div className="line-form-double">
                  <label>
                    <label className="label-check-2">
                      Nous vous offrons la possibilité d’être surclassé(e) en
                      chambre double au sein d’un hôtel 5 étoiles face à la
                      plage.<br></br> Pour cela, il vous suffit de former un
                      binôme avec un(e) autre participant(e) invité(e) à la
                      convention.<br></br>
                    </label>
                  </label>
                  <Controller
                    name={`users[${index}].wantsDoubleRoom`}
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <label className="label-check">
                        <input
                          type="checkbox"
                          name={name}
                          ref={ref}
                          onChange={(e) => onChange(e.target.checked)} // Mise à jour avec la valeur checked
                          onBlur={onBlur}
                          checked={value === "true" || value === true}
                        />
                        Cochez cette case si vous acceptez d’être logé(e) en
                        chambre double (dotée de 2 lits simples)<br></br>et
                        indiquez le nom de votre binôme que vous aurez
                        informé(e) au préalable et qui renseignera à son tour
                        votre nom.
                      </label>
                    )}
                  />

                  {wantsDoubleRoom && (
                    <div className="div-line-form">
                      <label className="label-check">
                        Avec qui souhaitez-vous partager la chambre ? (Votre
                        binôme devra également renseigner votre nom)
                      </label>
                      <Controller
                        name={`users[${index}].guestName`}
                        control={control}
                        defaultValue={field.guestName}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              defaultValue={undefined}
                              className="input-form"
                              type="text"
                              style={{ width: "100%" }}
                            />
                          </>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
              {id && (
                <div className="line-form">
                  <label className="label-check">
                    Envoyer le mail de confirmation
                  </label>
                  <Controller
                    name={`users[${index}].sendEmail`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <label>
                          <input
                            {...field}
                            type="radio"
                            value="true"
                            checked={field.value === "true"}
                          />
                          Oui
                        </label>
                        <label>
                          <input
                            {...field}
                            type="radio"
                            value="false"
                            checked={field.value === "false"}
                          />
                          Non
                        </label>
                      </>
                    )}
                  />
                </div>
              )}
              {id && (
                <>
                  <div className="line-form-solo">
                    <div style={{ width: "100%", flexDirection: "column" }}>
                      <label className="label-check">
                        Contre-indications médicales
                      </label>
                      <Controller
                        name={`users[${index}].medicalInfo`}
                        control={control}
                        defaultValue={field.medicalInfo}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            placeholder="Contre-indications médicales"
                            className="input-form-solo"
                            type="textarea"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="line-form">
                    <label className="label-check">
                      Est-un super admin ?
                      <Controller
                        name={`users[${index}].isSuperAdmin`}
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="checkbox"
                            checked={
                              field.value === "true" || field.value === true
                            }
                          />
                        )}
                      />
                    </label>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        <div>
          <button disabled={isLoading} className="submit">
            Valider
          </button>
        </div>
      </form>
    </div>
  );
}
