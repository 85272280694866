import Modal from "react-modal";
import swal, { successSwal } from "./swal";
import api from "./api";
import { useEffect, useState } from "react";

const teams = [
  "Amsterdam",
  "Anvers",
  "Athènes",
  "Atlanta",
  "Barcelone",
  "Berlin",
  "Brisbane",
  "Helsinki",
  "Londres",
  "Los Angeles",
  "Melbourne",
  "Mexico",
  "Montreal",
  "Moscou",
  "Munich",
  "Paris",
  "Pékin",
  "Rio de Janeiro",
  "Rome",
  "Saint-Louis",
  "Séoul",
  "Stockholm",
  "Sydney",
  "Tokyo",
];

export function HotelRestaurantModal({
  participant,
  modalIsOpen,
  refresh,
  closeModal,
}) {
  const [hotel, setHotel] = useState();
  const [restaurant, setRestaurant] = useState();
  const [team, setTeam] = useState();
  const [isCaptain, setIsCaptain] = useState();
  const [table, setTable] = useState();

  useEffect(() => {
    setHotel(participant?.hotel);
    setRestaurant(participant?.restaurant);
    setTeam(participant?.team);
    setIsCaptain(participant?.isCaptain);
    setTable(participant?.table);
  }, [participant]);

  const submit = async () => {
    try {
      await api.post("/editHotelRestaurant", {
        participantId: participant._id,
        hotel,
        restaurant,
        team,
        isCaptain,
        table,
        teamNumber: teams.indexOf(team) + 1,
      });
      successSwal("Informations modifiées");
      closeModal?.();
      refresh();
    } catch (e) {
      swal(e);
    }
  };

  const customStyles = {
    content: {
      top: "10%",
      left: "10%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "80%",
      overflow: "scroll",
      alignContent: "center",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div style={{ textAlign: "center" }}>
        <div className="line-form">
          <div className="div-line-form">
            <label className="label-check">Hotel</label>
            <select
              placeholder="Hotel"
              className="input-form"
              type="text"
              value={hotel}
              onChange={(e) => setHotel(e.target.value)}
            >
              <option
                className="option-disabled"
                disabled
                selected
                value={undefined}
              >
                Sélectionnez un hôtel
              </option>
              <option value="VillaCaroline"> Villa Caroline </option>
              <option value="GoldenTulip"> GOLDEN TULIP </option>
              <option value="WESTOTEL"> WESTOTEL </option>
              <option value="LeRoyal"> Le Royal </option>
              <option value="Mercure"> Mercure </option>
              <option value="CastelMarieLouise"> Castel Marie-Louise </option>
              <option value="BestWestern"> Best Western Garden & Spa </option>
              <option value="HotelDesDunes"> Hôtel des Dunes </option>
              <option value="LHermitage"> L’Hermitage </option>
            </select>
          </div>

          <div className="div-line-form">
            <label className="label-check">Restaurant</label>
            <select
              value={restaurant}
              placeholder="Hotel"
              className="input-form"
              type="text"
              onChange={(e) => setRestaurant(e.target.value)}
            >
              <option className="option-disabled" disabled selected>
                Sélectionnez un restaurant
              </option>
              <option value="ponton">Le Ponton</option>
              <option value="barbarossa">Barbarossa</option>
            </select>
          </div>
        </div>

        <div className="line-form">
          <div className="div-line-form">
            <label className="label-check">Equipe</label>
            <select
              placeholder="Hotel"
              className="input-form"
              type="text"
              value={team}
              onChange={(e) => setTeam(e.target.value)}
            >
              <option
                className="option-disabled"
                disabled
                selected
                value={undefined}
              >
                Sélectionnez une équipe
              </option>
              {teams.map((t) => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </select>
          </div>

          <div className="div-line-form">
            <label className="label-check">Est capitaine</label>
            <select
              value={isCaptain}
              placeholder="Hotel"
              className="input-form"
              type="text"
              onChange={(e) => setIsCaptain(e.target.value)}
            >
              <option className="option-disabled" disabled selected>
                Sélectionnez une option
              </option>
              <option value={true}>Oui</option>
              <option value={false}>Non</option>
            </select>
          </div>
        </div>

        <div className="line-form">
          <div className="div-line-form">
            <label className="label-check">Table</label>
            <select
              placeholder="Hotel"
              className="input-form"
              type="text"
              value={table}
              onChange={(e) => setTable(e.target.value)}
            >
              <option
                className="option-disabled"
                disabled
                selected
                value={undefined}
              >
                Sélectionnez une table
              </option>
              {Array(66)
                .fill(0)
                .map((t, i) => (
                  <option key={t} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div>
          <button onClick={submit} className="submit">
            Valider
          </button>
        </div>
      </div>
    </Modal>
  );
}
