import { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import "./Backoffice.css";
import { FaTrash } from "react-icons/fa6";
import api from "./api";
import moment from "moment";
import confirmAction from "./ConfirmAction";
import { AddAlbumsModal } from "./Albums/AddAlbumsModal";
const AlbumCard = ({ contact, refresh }) => {
  const [base64, setBase64] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const pictureRes = await api.get(contact.image, {
          responseType: "base64",
        });
        setBase64(`data:image/jpeg;base64,${pictureRes.data}`);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, []);

  return (
    <tr className="transition-colors duration-200 hover:bg-gray-200 quizzRow">
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {moment(contact?.createdAt).format("YYYY-MM-DD HH:mm")}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.filters.join(" ")}
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.downloadCount || 0}
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          <img src={base64} alt="" style={{ width: 100 }}></img>
        </div>
      </td>
      <td
        className="px-4 py-4 text-right text-sm font-medium"
        style={{ display: "flex", justifyContent: "center", gap: 5 }}
      >
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={async (e) => {
            confirmAction({
              action: async () => {
                await api.post("/album/delete", { id: contact._id });
                refresh();
              },
              promptText: `Voulez vous supprimer la photo ?`,
              confirmText: "Photo Supprimé",
            });
          }}
          title="Supprimer"
        >
          <FaTrash />
        </button>
      </td>
    </tr>
  );
};

const AlbumList = ({ albums, refresh, setShowModalAdd }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date de création",
        accessor: "createdAt",
      },
      {
        Header: "Filtres",
        accessor: "filters",
      },
      {
        Header: "Nombre de téléchargement(s)",
        accessor: "downloadCount",
      },
      {
        Header: "Photo",
        accessor: "e",
      },
      {
        Header: "Actions",
        accessor: "d",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: albums,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="flex flex-col body-backoffice">
        <div className="-my-2 overflow-x-auto">
          <div
            className="button-Backoffice-div"
            style={{ flexDirection: "row" }}
          >
            <button
              onClick={() => setShowModalAdd(true)}
              className="button-Backoffice"
              style={{ maxWidth: "200px" }}
            >
              Ajouter
            </button>
          </div>
          <div className="py-2 align-middle inline-block min-w-full ">
            <div className="shadow overflow-hidden border-gray-200 sm:rounded-lg table">
              <table
                className="min-w-full divide-y divide-gray-200"
                style={{ width: "100%" }}
                {...getTableProps()}
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider p-5"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <AlbumCard
                        key={key}
                        contact={row.original}
                        refresh={refresh}
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<"}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">>"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>

                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="ml-4 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Afficher {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Quizz() {
  const [albums, setAlbums] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(false);

  const refresh = async () => {
    try {
      const data = await api.get("/album/get");
      setAlbums(data.data);
      setCurrentFilters(
        [...new Set(data.data.flatMap((album) => album.filters))].map(
          (filter) => ({ value: filter, label: filter })
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <AddAlbumsModal
        modalIsOpen={showModalAdd}
        refresh={refresh}
        closeModal={() => setShowModalAdd(false)}
        currentFilters={currentFilters}
      />
      {/* <SurveyGroupModal
        modalIsOpen={surveyGroupEditing !== undefined}
        refresh={refresh}
        closeModal={() => setSurveyGroupEditing(undefined)}
        surveyGroup={surveyGroupEditing}
      /> */}
      {/* <SurveyModal
        modalIsOpen={surveyEditing !== undefined}
        refresh={() => {
          fetchSurveys();
          refresh();
        }}
        closeModal={() => setSurveyEditing(undefined)}
        survey={surveyEditing}
        surveyGroup={selectedSurveyGroup}
      /> */}
      <div style={{ flex: 6, marginTop: 40 }}>
        <AlbumList
          albums={albums}
          refresh={refresh}
          setShowModalAdd={setShowModalAdd}
        />
      </div>
    </div>
  );
}
