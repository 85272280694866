import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import api from "./api";
import Page from "./ControlPanel/Page";
import Structure from "./ControlPanel/Structure";
import Colors from "./ControlPanel/Colors";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ControlPanel() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const [pages, setPages] = useState([]);
  const [structure, setStructure] = useState();
  const [restaurants, setRestaurants] = useState();
  const [colors, setColors] = useState();

  const refreshPages = async () => {
    let cpt = 0;
    const res = await api.get("/pages/get", {});
    for (const page of res.data) {
      for (const text of page.texts) {
        text.__id = cpt++;
      }
    }
    setPages(res.data);
  };

  const refreshRestaurants = async () => {
    const res = await api.get("/admin/restaurants", {});
    setRestaurants(res.data);
  };

  const refreshColors = async () => {
    const res = await api.get("/colors/get", {});
    setColors(res.data);
  };

  const refreshStructure = async () => {
    const res = await api.get("/structure/get", {});

    const structure = [];
    let cpt = 0;
    if (res.data?.linesPriority?.length) {
      structure.push({
        isPriority: true,
        __id: cpt++,
        pages: res.data.linesPriority,
      });
    }
    if (res.data?.lines?.length) {
      for (const l of res.data.lines) {
        structure.push({
          isPriority: false,
          __id: cpt++,
          pages: l,
        });
      }
    }
    setStructure(structure);
  };

  useEffect(() => {
    refreshRestaurants();
    refreshColors();
    refreshStructure();
    refreshPages();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {/* <LeftMenu></LeftMenu> */}
      <Container maxWidth="lg">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={handleChange} variant="scrollable">
            <Tab
              style={{ color: "#74CA43" }}
              label={"COULEURS"}
              {...a11yProps(0)}
            />
            <Tab
              style={{ color: "#74CA43" }}
              label={"STRUCTURE"}
              {...a11yProps(1)}
            />
            {pages.map((p, i) => (
              <Tab
                label={`${p.buttonName} ${
                  p.pageValue ? `(${p.pageValue})` : ""
                }`}
                {...a11yProps(i + 2)}
              />
            ))}
            <Tab label="Nouvelle Page" {...a11yProps(pages.length + 2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabIndex} index={0}>
          <Colors colors={colors} restaurants={restaurants}></Colors>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <Structure structure={structure} pages={pages}></Structure>
        </CustomTabPanel>
        {pages.map((p, i) => (
          <CustomTabPanel key={i} value={tabIndex} index={i + 2}>
            <Page page={p} refreshPages={refreshPages}></Page>
          </CustomTabPanel>
        ))}
        <CustomTabPanel value={tabIndex} index={pages.length + 2}>
          <Page refreshPages={refreshPages}></Page>
        </CustomTabPanel>
      </Container>
    </Box>
  );
}
