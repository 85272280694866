import useAuthStore from "./useAuthStore";
import { Navigate } from "react-router-dom";
const AuthContainer = ({ children, requireAuth }) => {
  const { currentUser } = useAuthStore();
  if (requireAuth && (!currentUser || !currentUser.token)) {
    return <Navigate to="/login-back" />;
  }
  return <>{children}</>;
};
export default AuthContainer;
