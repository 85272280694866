import "./Form.css";
import React, { useState, useEffect } from "react";
import logoConvention from "../src/img/logo-convention.png";

export default function Headers() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const calculateTimeLeft = () => {
    const eventDate = new Date("March 25, 2024 17:00:00");
    const difference = eventDate - currentTime;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const timeLeft = calculateTimeLeft();

  return (
    <div className="header">
      <h1 className="h1-header">Convention Pomona 2024</h1>
      <h2 className="h2-header">La Baule-Escoublac</h2>
      <h3 className="h3-header">25 - 26 mars 2024</h3>

      <div className="timer">
        {Object.keys(timeLeft).length > 0 && (
          <>
            <div className="bloc">
              <h1 className="h1-bloc">{timeLeft.days}</h1>
              <p>jours</p>
            </div>
            <div className="bloc">
              <h1 className="h1-bloc">{timeLeft.hours}</h1>
              <p>heures</p>
            </div>
            <div className="bloc">
              <h1 className="h1-bloc">{timeLeft.minutes}</h1>
              <p>min</p>
            </div>
            <div className="bloc">
              <h1 className="h1-bloc">{timeLeft.seconds}</h1>
              <p>sec</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
