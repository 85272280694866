import Modal from "react-modal";
import swal, { successSwal } from "../swal";
import api from "../api";
import { useEffect, useState } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { Button } from "@material-ui/core";
export function SurveyModal({
  modalIsOpen,
  refresh,
  closeModal,
  survey,
  surveyGroup,
}) {
  const [currentSurvey, setCurrentSurvey] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentSurvey({ ...survey });
  }, [survey]);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await api.post("/survey/createoredit", {
        ...currentSurvey,
        surveyGroupId: surveyGroup._id,
      });
      successSwal("Question ajoutée");
      closeModal?.();
      refresh();
    } catch (e) {
      swal(e);
    } finally {
      setIsLoading(false);
    }
  };

  const addProposition = () => {
    setCurrentSurvey((prev) => {
      if (!prev.propositions) {
        prev.propositions = [];
      }
      prev.propositions.push({});
      prev.propositions = [...prev.propositions];
      return { ...prev };
    });
  };

  const removeProposition = (index) => {
    setCurrentSurvey((prev) => {
      prev.propositions = [...prev.propositions.filter((_, i) => i !== index)];
      return { ...prev };
    });
  };

  const customStyles = {
    content: {
      top: "10%",
      left: "10%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "80%",
      overflow: "scroll",
      height: "80%",
      alignContent: "center",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div style={{ textAlign: "center" }}>
        <div className="line-form">
          <div className="div-line-form2" style={{ width: "100%" }}>
            <label className="label-check">Question</label>
            <textarea
              value={currentSurvey?.question}
              onChange={(e) =>
                setCurrentSurvey({ ...currentSurvey, question: e.target.value })
              }
              className="input-form"
              type="text"
            />
          </div>
        </div>
        <div className="line-form">
          <div className="div-line-form" style={{ width: "100%" }}>
            <label className="label-check">Type</label>
            <select
              style={{ width: "100%" }}
              placeholder="Type"
              className="input-form"
              type="text"
              value={currentSurvey?.type}
              onChange={(e) =>
                setCurrentSurvey({ ...currentSurvey, type: e.target.value })
              }
            >
              {[
                <option
                  className="option-disabled"
                  disabled
                  selected
                  value={""}
                >
                  Sélectionnez le type de la question
                </option>,
                ...["mcq", "input"].map((val, i) => (
                  <option key={i} value={val}>
                    {{ mcq: "Choix multiple", input: "Libre" }[val]}
                  </option>
                )),
              ]}
            </select>
          </div>
        </div>

        {currentSurvey?.type === "mcq" ? (
          <>
            <div>
              {currentSurvey?.propositions?.map((prop, index) => (
                <Proposition
                  index={index}
                  key={index}
                  currentSurvey={currentSurvey}
                  setCurrentSurvey={setCurrentSurvey}
                  removeProposition={removeProposition}
                />
              ))}
            </div>
            <button className="submit" onClick={addProposition}>
              Ajouter un proposition
            </button>
          </>
        ) : (
          <></>
        )}

        <div className="line-form">
          <div>
            <label className="label-check">
              <input
                type="checkbox"
                checked={currentSurvey?.isMandatory}
                onChange={(e) => {
                  setCurrentSurvey({
                    ...currentSurvey,
                    isMandatory: e.target.checked,
                  });
                }}
              />
              Cette question est obligatoire
            </label>
          </div>
        </div>

        <div>
          <button disabled={isLoading} className="submit" onClick={onSubmit}>
            Valider
          </button>
        </div>
      </div>
    </Modal>
  );
}

const Proposition = ({
  index,
  currentSurvey,
  setCurrentSurvey,
  removeProposition,
}) => {
  return (
    <div
      style={{
        width: "60%",
        marginLeft: "20%",
        marginTop: 10,
        marginBottom: 20,
      }}
    >
      <div className="div-line-form2" style={{ width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button onClick={() => removeProposition(index)}>
            <FaRegTrashCan color="red" scale={2} />
          </Button>

          <label className="label-check">Proposition {index + 1}</label>
        </div>

        <input
          value={currentSurvey?.propositions?.[index]?.text}
          onChange={(e) =>
            setCurrentSurvey((prev) => {
              prev.propositions[index].text = e.target.value;
              return { ...prev };
            })
          }
          className="input-form"
          type="text"
        />
      </div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div>
          <label className="label-check">
            <input
              type="checkbox"
              checked={currentSurvey?.propositions?.[index]?.shouldShowComment}
              onChange={(e) =>
                setCurrentSurvey((prev) => {
                  prev.propositions[index].shouldShowComment = e.target.checked;
                  if (!e.target.checked) {
                    prev.propositions[index].comment = undefined;
                  }
                  return { ...prev };
                })
              }
            />
            Afficher la question supplémentaire
          </label>
        </div>
        <div>
          <label className="label-check">
            <input
              type="checkbox"
              checked={currentSurvey?.propositions?.[index]?.isCorrect}
              onChange={(e) =>
                setCurrentSurvey((prev) => {
                  prev.propositions[index].isCorrect = e.target.checked;
                  return { ...prev };
                })
              }
            />
            Bonne réponse
          </label>
        </div>
      </div>

      {currentSurvey?.propositions?.[index]?.shouldShowComment && (
        <div className="line-form">
          <div className="div-line-form2" style={{ width: "100%" }}>
            <label className="label-check">Question supplémentire</label>
            <textarea
              value={currentSurvey?.propositions[index].comment}
              onChange={(e) =>
                setCurrentSurvey((prev) => {
                  prev.propositions[index].comment = e.target.value;
                  return { ...prev };
                })
              }
              className="input-form"
              type="text"
            />
          </div>
        </div>
      )}
    </div>
  );
};
