import React, { useState } from "react";
import { Bar, Pie } from "react-chartjs-2"; // Importer seulement Bar puisque nous n'utilisons plus Doughnut
import "chart.js/auto";
import DatePicker from "react-datepicker";
import Select from "react-select";

const generateColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const red = Math.floor(Math.random() * 255);
    const green = Math.floor(Math.random() * 255);
    const blue = Math.floor(Math.random() * 255);
    colors.push(`rgba(${red}, ${green}, ${blue}, 0.8)`); // Utilisation d'une opacité de 0.8
  }
  return colors;
};

const sortDataAndApplyColors = (dataObject) => {
  const entries = Object.entries(dataObject || {});
  const sortedEntries = entries.sort((a, b) => b[1] - a[1]);
  const labels = sortedEntries.map((entry) => entry[0]);
  const data = sortedEntries.map((entry) => entry[1]);
  const backgroundColors = generateColors(sortedEntries.length);
  return { labels, data, backgroundColors };
};
const sortDataAndApplyColorsDate = (dataObject) => {
  const entries = Object.entries(dataObject || {});

  // Tri par clé en convertissant les clés en dates
  const sortedEntries = entries.sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
    return dateA - dateB;
  });

  const labels = sortedEntries.map((entry) => entry[0]);
  const data = sortedEntries.map((entry) => entry[1]);
  const backgroundColors = generateColors(sortedEntries.length);

  return { labels, data, backgroundColors };
};
const aggregateVisitsByDate = (dataObject, selectedDate) => {
  const formattedDate = selectedDate.toISOString().split("T")[0];
  const pageVisits = {};

  for (const [page, dates] of Object.entries(dataObject)) {
    if (dates[formattedDate]) {
      pageVisits[page] = dates[formattedDate];
    }
  }

  const labels = Object.keys(pageVisits);
  const data = Object.values(pageVisits);
  const backgroundColors = generateColors(labels.length);

  return { labels, data, backgroundColors };
};

const StatsGraphs = ({ appStats }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPage, setSelectedPage] = useState(null);
  const sortedBarData = sortDataAndApplyColors(appStats?.pageVisits); // Préparer les options pour le sélecteur de pages
  const pageOptions = Object.keys(appStats?.pageVisitsByDate || {}).map(
    (page) => ({ value: page, label: page })
  );

  const sortedUniqueVisitorsData = sortDataAndApplyColors(
    appStats?.uniqueVisitorsPerPage
  );
  const uniqueVisitorsPerDayData = sortDataAndApplyColorsDate(
    appStats?.uniqueVisitorsPerDay
  );

  const barChartData = {
    labels: sortedBarData.labels,
    datasets: [
      {
        label: "Visites par page",
        data: sortedBarData.data,
        backgroundColor: sortedBarData.backgroundColors,
        borderColor: sortedBarData.backgroundColors.map((color) =>
          color.replace("0.8", "1")
        ), // Augmentation de l'opacité pour les bordures
        borderWidth: 1,
      },
    ],
  };
  const downloadData = {
    labels: ["Téléchargé", "Pas Téléchargé"],
    datasets: [
      {
        label: "Statut de Téléchargement",
        data: [
          appStats?.totalUsersConnected,
          appStats?.usersNeverDownloaded.length,
        ], // Utilisez les données fournies
        backgroundColor: [
          "rgba(102, 178, 255, 0.8)",
          "rgba(255, 178, 102, 0.8)",
        ], // Couleurs douces pour téléchargé et pas téléchargé
        borderColor: ["rgba(102, 178, 255, 1)", "rgba(255, 178, 102, 1)"],
        borderWidth: 1,
        innerWidth: "100%",
      },
    ],
  };
  // Utilisation d'un deuxième Bar chart pour les visiteurs uniques par page
  const uniqueVisitorsBarChartData = {
    labels: sortedUniqueVisitorsData.labels,
    datasets: [
      {
        label: "Visiteurs uniques par page",
        data: sortedUniqueVisitorsData.data,
        backgroundColor: sortedUniqueVisitorsData.backgroundColors,
        borderColor: sortedUniqueVisitorsData.backgroundColors.map((color) =>
          color.replace("0.8", "1")
        ), // Augmentation de l'opacité pour les bordures
        borderWidth: 1,
      },
    ],
  };
  const uniqueVisitorsPerDayChartData = {
    labels: uniqueVisitorsPerDayData.labels,
    datasets: [
      {
        label: "Visiteurs uniques par jour",
        data: uniqueVisitorsPerDayData.data,
        backgroundColor: uniqueVisitorsPerDayData.backgroundColors,
        borderColor: uniqueVisitorsPerDayData.backgroundColors.map((color) =>
          color.replace("0.8", "1")
        ), // Augmentation de l'opacité pour les bordures
        borderWidth: 1,
      },
    ],
  };
  const connectionTimesData = appStats?.connectionTimes || {};
  const connectionHoursLabels = [];
  const connectionHoursData = [];
  const connectionHoursColors = generateColors(24); // Générer des couleurs pour 24 heures

  for (let hour = 0; hour < 24; hour++) {
    connectionHoursLabels.push(`${hour}:00`);
    connectionHoursData.push(connectionTimesData[hour] || 0);
  }

  const connectionHoursChartData = {
    labels: connectionHoursLabels,
    datasets: [
      {
        label: "Connexions par heure",
        data: connectionHoursData,
        backgroundColor: connectionHoursColors,
        borderColor: connectionHoursColors.map((color) =>
          color.replace("0.8", "1")
        ),
        borderWidth: 1,
      },
    ],
  };

  const pageVisitsBySelectedDateData = aggregateVisitsByDate(
    appStats?.pageVisitsByDate || {},
    selectedDate
  );

  const barChartDataBySelectedDate = {
    labels: pageVisitsBySelectedDateData.labels,
    datasets: [
      {
        label: `Visites par page le ${
          selectedDate.toISOString().split("T")[0]
        }`,
        data: pageVisitsBySelectedDateData.data,
        backgroundColor: pageVisitsBySelectedDateData.backgroundColors,
        borderColor: pageVisitsBySelectedDateData.backgroundColors.map(
          (color) => color.replace("0.8", "1")
        ),
        borderWidth: 1,
      },
    ],
  };
  const visitsByDateForSelectedPage = selectedPage
    ? appStats.pageVisitsByDate[selectedPage.value]
    : {};
  const sortedDataForSelectedPage = sortDataAndApplyColorsDate(
    visitsByDateForSelectedPage
  );

  const barChartDataForSelectedPage = {
    labels: sortedDataForSelectedPage.labels,
    datasets: [
      {
        label: `Visites pour la page "${
          selectedPage ? selectedPage.label : ""
        }" par date`,
        data: sortedDataForSelectedPage.data,
        backgroundColor: sortedDataForSelectedPage.backgroundColors,
        borderColor: sortedDataForSelectedPage.backgroundColors.map((color) =>
          color.replace("0.8", "1")
        ),
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <h2>Graphique à Barres - Visites par page</h2>
          <Bar data={barChartData} />
        </div>

        <div style={{ flex: 1, marginLeft: "10px" }}>
          <h2>Graphique à Barres - Visiteurs uniques par page</h2>
          <Bar data={uniqueVisitorsBarChartData} />{" "}
          {/* Changement de Doughnut à Bar */}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <h2>Graphique à Barres - Visiteurs uniques par jour</h2>
          <Bar data={uniqueVisitorsPerDayChartData} />
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <h2>Graphique à Barres - Connexions par heure</h2>
          <Bar data={connectionHoursChartData} />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <div style={{ marginBottom: "20px" }}>
            <h2>Sélectionnez une date</h2>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>
              Graphique à Barres - Visites par page pour la date sélectionnée
            </h2>
            <Bar data={barChartDataBySelectedDate} />
          </div>
        </div>

        <div style={{ flex: 1, marginLeft: "10px" }}>
          {" "}
          <div style={{ marginBottom: "20px" }}>
            <h2>Sélectionnez une page</h2>
            <Select
              options={pageOptions}
              value={selectedPage}
              onChange={setSelectedPage}
            />
          </div>
          {selectedPage && (
            <div style={{ marginBottom: "20px" }}>
              <h2>
                Graphique à Barres - Visites par date pour la page sélectionnée
              </h2>
              <Bar data={barChartDataForSelectedPage} />
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <h2>Diagramme en Camembert - Téléchargements de l'Application</h2>
          <Pie data={downloadData} />
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <p>f</p>
        </div>
      </div>
    </div>
  );
};

export default StatsGraphs;
