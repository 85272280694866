import {
  Card,
  CardContent,
  Paper,
  Typography,
  Stack,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { IoMdArrowRoundUp } from "react-icons/io";
import { IoMdArrowRoundDown } from "react-icons/io";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import _ from "lodash";
import api from "../api";
import confirmAction from "../ConfirmAction";
import { FaTrash } from "react-icons/fa6";
import { useEffect, useState } from "react";

function isValidJSON(text) {
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

const isString = (text) => typeof text === "string";

export default function Page({ page, refreshPages }) {
  const [currentPage, setCurrentPage] = useState(page || {});

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [page]);

  const setField = ({ field, value }) => {
    setCurrentPage((prev) => {
      prev[field] = value;
      return { ...prev };
    });
  };

  const createPage = async () => {
    confirmAction({
      action: async () => {
        await api.post("/pages/create", {
          ...currentPage,
        });
        refreshPages();
      },
      promptText: "Voulez-vous ajouter cette page ?",
      confirmText: "Page ajoutée",
    });
  };

  const editPage = async () => {
    confirmAction({
      action: async () => {
        await api.post("/pages/edit", {
          currentPage,
        });
        refreshPages();
      },
      promptText: "Voulez-vous modifier cette page ?",
      confirmText: "Informations modifiées",
    });
  };

  const addBlock = () => {
    setCurrentPage((prev) => {
      if (!prev.texts) {
        prev.texts = [];
      }
      prev.texts.push({
        __id: Math.max(...prev.texts.map((e) => e.__id), 0) + 1,
      });
      return { ...prev };
    });
  };

  const removeBlock = (index) => {
    setCurrentPage((prev) => {
      prev.texts.splice(index, 1);
      return { ...prev };
    });
  };

  const setBlock = ({ index, field, value, format }) => {
    setCurrentPage((prev) => {
      if (format && isValidJSON(value)) {
        prev.texts[index][field] = JSON.parse(value);
      } else {
        prev.texts[index][field] = value;
      }
      return { ...prev };
    });
  };

  const moveBlock = ({ currIndex, nextIndex }) => {
    setCurrentPage((prev) => {
      const temp = _.cloneDeep(prev.texts[currIndex]);
      prev.texts[currIndex] = _.cloneDeep(prev.texts[nextIndex]);
      prev.texts[nextIndex] = temp;
      return { ..._.cloneDeep(prev) };
    });
  };

  const addItem = (blockIndex) => {
    setCurrentPage((prev) => {
      if (!prev.texts[blockIndex].elements) {
        prev.texts[blockIndex].elements = [];
      }
      prev.texts[blockIndex].elements.push({
        __id:
          Math.max(...prev.texts[blockIndex].elements.map((e) => e.__id), 0) +
          1,
      });
      return { ...prev };
    });
  };

  const removeItem = ({ blockIndex, itemIndex }) => {
    setCurrentPage((prev) => {
      prev.texts[blockIndex].elements.splice(itemIndex, 1);
      return { ...prev };
    });
  };

  const setItem = ({ blockIndex, itemIndex, field, value, format }) => {
    setCurrentPage((prev) => {
      if (format && isValidJSON(value)) {
        prev.texts[blockIndex].elements[itemIndex][field] = JSON.parse(value);
      } else {
        prev.texts[blockIndex].elements[itemIndex][field] = value;
      }
      return { ...prev };
    });
  };

  const moveItem = ({ blockIndex, currIndex, nextIndex }) => {
    setCurrentPage((prev) => {
      const temp = _.cloneDeep(prev.texts[blockIndex].elements[currIndex]);
      prev.texts[blockIndex].elements[currIndex] = _.cloneDeep(
        prev.texts[blockIndex].elements[nextIndex]
      );
      prev.texts[blockIndex].elements[nextIndex] = temp;
      return { ..._.cloneDeep(prev) };
    });
  };

  const addSubItem = ({ blockIndex, itemIndex, subKey }) => {
    setCurrentPage((prev) => {
      if (!prev.texts[blockIndex].elements[itemIndex][subKey]) {
        prev.texts[blockIndex].elements[itemIndex][subKey] = [];
      }
      prev.texts[blockIndex].elements[itemIndex][subKey].push({
        __id:
          Math.max(
            ...prev.texts[blockIndex].elements[itemIndex][subKey].map(
              (e) => e.__id
            ),
            0
          ) + 1,
      });
      return { ...prev };
    });
  };

  const removeSubItem = ({ blockIndex, itemIndex, subIndex, subKey }) => {
    setCurrentPage((prev) => {
      prev.texts[blockIndex].elements[itemIndex][subKey].splice(subIndex, 1);
      return { ...prev };
    });
  };

  const setSubItem = ({
    blockIndex,
    itemIndex,
    field,
    value,
    subKey,
    subIndex,
  }) => {
    setCurrentPage((prev) => {
      prev.texts[blockIndex].elements[itemIndex][subKey][subIndex][field] =
        value;
      return { ...prev };
    });
  };

  const moveSubItem = ({
    blockIndex,
    itemIndex,
    subKey,
    currIndex,
    nextIndex,
  }) => {
    setCurrentPage((prev) => {
      const temp = _.cloneDeep(
        prev.texts[blockIndex].elements[itemIndex][subKey][currIndex]
      );
      prev.texts[blockIndex].elements[itemIndex][subKey][currIndex] =
        _.cloneDeep(
          prev.texts[blockIndex].elements[itemIndex][subKey][nextIndex]
        );
      prev.texts[blockIndex].elements[itemIndex][subKey][nextIndex] = temp;
      return { ..._.cloneDeep(prev) };
    });
  };

  const addSubSubItem = ({ blockIndex, itemIndex, subKey, subIndex }) => {
    setCurrentPage((prev) => {
      if (!prev.texts[blockIndex].elements[itemIndex][subKey][subIndex].items) {
        prev.texts[blockIndex].elements[itemIndex][subKey][subIndex].items = [];
      }
      prev.texts[blockIndex].elements[itemIndex][subKey][subIndex].items.push({
        __id:
          Math.max(
            ...prev.texts[blockIndex].elements[itemIndex][subKey][
              subIndex
            ].items.map((e) => e.__id),
            0
          ) + 1,
      });
      return { ...prev };
    });
  };

  const removeSubSubItem = ({
    blockIndex,
    itemIndex,
    subIndex,
    subKey,
    subSubIndex,
  }) => {
    setCurrentPage((prev) => {
      prev.texts[blockIndex].elements[itemIndex][subKey][subIndex].items.splice(
        subSubIndex,
        1
      );
      return { ...prev };
    });
  };

  const setSubSubItem = ({
    blockIndex,
    itemIndex,
    field,
    value,
    subKey,
    subIndex,
    subSubIndex,
  }) => {
    setCurrentPage((prev) => {
      prev.texts[blockIndex].elements[itemIndex][subKey][subIndex].items[
        subSubIndex
      ][field] = value;
      return { ...prev };
    });
  };

  return (
    <Card style={{ marginTop: 5 }}>
      <Paper sx={{ display: "flex" }}>
        <CardContent
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "column",
            flex: 15,
          }}
        >
          <Stack spacing={3} direction="row">
            <TextField
              onChange={(e) =>
                setField({ field: "pageKey", value: e.target.value })
              }
              label="Page Key"
              required
              fullWidth
              placeholder="Page Key"
              value={currentPage.pageKey}
            />
            <TextField
              onChange={(e) =>
                setField({ field: "pageValue", value: e.target.value })
              }
              label="Page Value"
              required
              fullWidth
              placeholder="Page Value"
              value={currentPage.pageValue}
            />
          </Stack>
          <TextField
            onChange={(e) =>
              setField({ field: "buttonName", value: e.target.value })
            }
            label="Nom du bouton"
            required
            fullWidth
            placeholder="Nom du bouton"
            value={currentPage.buttonName}
          />
          <Stack spacing={3} direction="row">
            <TextField
              onChange={(e) =>
                setField({ field: "nav", value: e.target.value })
              }
              label="Redirection"
              required
              fullWidth
              placeholder="Redirection"
              value={currentPage.nav}
            />
            <TextField
              onChange={(e) =>
                setField({ field: "icon", value: e.target.value })
              }
              label="Icon"
              required
              fullWidth
              placeholder="Icon"
              value={currentPage.icon}
            />
          </Stack>

          <Stack direction="row">
            <FormControlLabel
              control={<Switch defaultChecked={!currentPage.waiting} />}
              label="Afficher la page"
              value={!currentPage.waiting}
              onChange={(e) => {
                setField({ field: "waiting", value: e.target.value });
              }}
            />

            <TextField
              onChange={(e) =>
                setField({ field: "toastMessage", value: e.target.value })
              }
              label="Message Toast"
              required
              fullWidth
              placeholder="Message Toast"
              value={currentPage.toastMessage}
            />
          </Stack>

          <Stack direction="row">
            <FormControl fullWidth>
              <Select
                value={currentPage.isSmall}
                label="Taille"
                onChange={(e) => {
                  setField({ field: "isSmall", value: e.target.value });
                }}
              >
                <MenuItem value={true}>Petit</MenuItem>
                <MenuItem value={false}>Normal</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <Select
                value={currentPage.color}
                label="Couleur Header"
                onChange={(e) => {
                  setField({ field: "color", value: e.target.value });
                }}
              >
                <MenuItem value={"primary"}>primary</MenuItem>
                <MenuItem value={"primaryWaiting"}>primaryWaiting</MenuItem>
                <MenuItem value={"secondary"}>secondary</MenuItem>
                <MenuItem value={"secondaryWaiting"}>secondaryWaiting</MenuItem>
                <MenuItem value={"third"}>third</MenuItem>
                <MenuItem value={"fourth"}>fourth</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <TextField
            onChange={(e) =>
              setField({ field: "title", value: e.target.value })
            }
            label="Titre de la page"
            required
            fullWidth
            placeholder="Titre de la page"
            value={currentPage.title}
          />
          <Typography variant="h5">Contenu</Typography>
          <Stack padding={2}>
            {currentPage?.texts?.map((t, i) => (
              <Block
                blocks={currentPage?.texts}
                key={t.__id}
                block={t}
                index={i}
                setBlock={setBlock}
                removeBlock={removeBlock}
                addItem={addItem}
                removeItem={removeItem}
                setItem={setItem}
                addSubItem={addSubItem}
                removeSubItem={removeSubItem}
                setSubItem={setSubItem}
                addSubSubItem={addSubSubItem}
                removeSubSubItem={removeSubSubItem}
                setSubSubItem={setSubSubItem}
                moveBlock={moveBlock}
                moveItem={moveItem}
                moveSubItem={moveSubItem}
              ></Block>
            ))}
          </Stack>

          <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
            <Button
              onClick={() => addBlock()}
              variant="contained"
              sx={{ backgroundColor: "#74CA43" }}
            >
              Ajouter un block
            </Button>
          </Stack>
          <Stack spacing={3} direction="row" sx={{ marginY: "50px" }}>
            {!currentPage?._id && (
              <Button
                onClick={() => createPage()}
                variant="contained"
                sx={{ backgroundColor: "#74CA43" }}
              >
                Ajouter
              </Button>
            )}
            {currentPage?._id && (
              <Button
                onClick={() => editPage()}
                variant="contained"
                sx={{ backgroundColor: "#74CA43" }}
              >
                Modifier
              </Button>
            )}
          </Stack>
        </CardContent>
      </Paper>
    </Card>
  );
}

const Block = ({
  blocks,
  block,
  setBlock,
  index,
  removeBlock,
  addItem,
  removeItem,
  setItem,
  addSubItem,
  removeSubItem,
  setSubItem,
  addSubSubItem,
  removeSubSubItem,
  setSubSubItem,
  moveBlock,
  moveItem,
  moveSubItem,
}) => {
  return (
    <Stack marginBottom={2} spacing={2} direction={"row"} width={"100%"}>
      <Stack direction={"column"} justifyContent={"start"} gap={2}>
        {index > 0 && (
          <IconButton
            sx={{
              backgroundColor: "#D7DCE1",
              padding: "5px",
              borderRadius: "5px",
            }}
            aria-label="supprimer"
            onClick={() => {
              moveBlock({
                currIndex: index,
                nextIndex: index - 1,
              });
            }}
          >
            <IoMdArrowRoundUp />
          </IconButton>
        )}
        {index < blocks.length - 1 && (
          <IconButton
            sx={{
              backgroundColor: "#D7DCE1",
              padding: "5px",
              borderRadius: "5px",
            }}
            aria-label="supprimer"
            onClick={() => {
              moveBlock({
                currIndex: index,
                nextIndex: index + 1,
              });
            }}
          >
            <IoMdArrowRoundDown />
          </IconButton>
        )}
      </Stack>
      <Accordion style={{ padding: 10, margin: 5, width: "100%" }}>
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          <Stack spacing={5} direction="row" marginBottom={1}>
            <IconButton
              sx={{
                backgroundColor: "#D7DCE1",
                padding: "5px",
                borderRadius: "5px",
              }}
              aria-label="supprimer"
              onClick={() => {
                removeBlock(index);
              }}
            >
              <FaTrash />
            </IconButton>
            <Typography variant="h6">Block {index + 1}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <TextField
              onChange={(e) =>
                setBlock({
                  field: "style",
                  value: e.target.value,
                  index,
                  format: true,
                })
              }
              label="Style"
              required
              fullWidth
              placeholder="style"
              value={
                !isString(block.style)
                  ? JSON.stringify(block.style)
                  : block.style
              }
            />

            <Stack padding={2}>
              {block?.elements?.map((t, i) => (
                <BlockItem
                  key={t.__id}
                  item={t}
                  items={block?.elements}
                  blockIndex={index}
                  itemIndex={i}
                  addItem={addItem}
                  removeItem={removeItem}
                  setItem={setItem}
                  addSubItem={addSubItem}
                  removeSubItem={removeSubItem}
                  setSubItem={setSubItem}
                  addSubSubItem={addSubSubItem}
                  removeSubSubItem={removeSubSubItem}
                  setSubSubItem={setSubSubItem}
                  moveItem={moveItem}
                  moveSubItem={moveSubItem}
                ></BlockItem>
              ))}
            </Stack>
            <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
              <Button
                onClick={() => addItem(index)}
                variant="contained"
                sx={{ backgroundColor: "#74CA43" }}
              >
                Ajouter un Item
              </Button>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const BlockItem = ({
  item,
  items,
  blockIndex,
  itemIndex,
  removeItem,
  setItem,
  removeSubItem,
  setSubItem,
  addSubItem,
  addSubSubItem,
  removeSubSubItem,
  setSubSubItem,
  moveItem,
  moveSubItem,
}) => {
  return (
    <Stack marginBottom={2} spacing={2} direction={"row"}>
      <Stack direction={"column"} justifyContent={"start"} gap={2}>
        {itemIndex > 0 && (
          <IconButton
            sx={{
              backgroundColor: "#D7DCE1",
              padding: "5px",
              borderRadius: "5px",
            }}
            aria-label="supprimer"
            onClick={() => {
              moveItem({
                blockIndex,
                currIndex: itemIndex,
                nextIndex: itemIndex - 1,
              });
            }}
          >
            <IoMdArrowRoundUp />
          </IconButton>
        )}
        {itemIndex < items.length - 1 && (
          <IconButton
            sx={{
              backgroundColor: "#D7DCE1",
              padding: "5px",
              borderRadius: "5px",
            }}
            aria-label="supprimer"
            onClick={() => {
              moveItem({
                blockIndex,
                currIndex: itemIndex,
                nextIndex: itemIndex + 1,
              });
            }}
          >
            <IoMdArrowRoundDown />
          </IconButton>
        )}
      </Stack>
      <Accordion style={{ padding: 10, margin: 5, width: "100%" }}>
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          <Stack spacing={5} direction="row" marginBottom={1}>
            <IconButton
              sx={{
                backgroundColor: "#D7DCE1",
                padding: "5px",
                borderRadius: "5px",
              }}
              aria-label="supprimer"
              onClick={() => {
                removeItem({ blockIndex, itemIndex });
              }}
            >
              <FaTrash />
            </IconButton>
            <Typography variant="h7">
              Item {itemIndex + 1} {item?.type && `(${item?.type})`}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <Select
                value={item.type}
                label="Type"
                placeholder="Type"
                onChange={(e) => {
                  setItem({
                    field: "type",
                    value: e.target.value,
                    blockIndex,
                    itemIndex,
                  });
                }}
              >
                <MenuItem value={"title"}>title</MenuItem>
                <MenuItem value={"subtitle"}>subtitle</MenuItem>
                <MenuItem value={"image"}>image</MenuItem>
                <MenuItem value={"description"}>description</MenuItem>
                <MenuItem value={"video"}>video</MenuItem>
                <MenuItem value={"button"}>button</MenuItem>
                <MenuItem value={"clickableLine"}>clickableLine</MenuItem>
                <MenuItem value={"map"}>map</MenuItem>
                <MenuItem value={"list"}>list</MenuItem>
                <MenuItem value={"tabs"}>tabs</MenuItem>
                <MenuItem value={"survey"}>survey</MenuItem>
                <MenuItem value={"key"}>key</MenuItem>
                <MenuItem value={"avatars"}>avatars</MenuItem>
                <MenuItem value={"images"}>images</MenuItem>
              </Select>
            </FormControl>

            {["description", "subtitle", "title", "image"].includes(
              item.type
            ) && (
              <TextField
                onChange={(e) =>
                  setItem({
                    field: "text",
                    value: e.target.value,
                    blockIndex,
                    itemIndex,
                  })
                }
                label="Text"
                required
                fullWidth
                placeholder="Text"
                value={item.text}
              />
            )}

            {["video"].includes(item.type) && (
              <TextField
                onChange={(e) =>
                  setItem({
                    field: "url",
                    value: e.target.value,
                    blockIndex,
                    itemIndex,
                  })
                }
                label="URL"
                required
                fullWidth
                placeholder="URL"
                value={item.url}
              />
            )}

            {["button"].includes(item.type) && (
              <>
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "link",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Lien"
                  required
                  fullWidth
                  placeholder="Lien"
                  value={item.link}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "text",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Texte"
                  required
                  fullWidth
                  placeholder="Text"
                  value={item.text}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "styleText",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      format: true,
                    })
                  }
                  label="Style bouton"
                  required
                  fullWidth
                  placeholder="Style bouton"
                  value={
                    !isString(item.styleText)
                      ? JSON.stringify(item.styleText)
                      : item.styleText
                  }
                />
              </>
            )}

            {["clickableLine"].includes(item.type) && (
              <>
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "title",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Titre"
                  required
                  fullWidth
                  placeholder="Titre"
                  value={item.title}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "link",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Lien"
                  required
                  fullWidth
                  placeholder="link"
                  value={item.link}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "icon",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Icon"
                  required
                  fullWidth
                  placeholder="Icon"
                  value={item.icon}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "iconColor",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Couleur Icon"
                  required
                  fullWidth
                  placeholder="Couleur Icon"
                  value={item.iconColor}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "address1",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Adresse 1"
                  required
                  fullWidth
                  placeholder="Adresse 1"
                  value={item.address1}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "address2",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Adresse 2"
                  required
                  fullWidth
                  placeholder="Adresse 2"
                  value={item.address2}
                />
              </>
            )}

            {["key"].includes(item.type) && (
              <>
                <Stack padding={2}>
                  {item?.elements?.map((t, i) => (
                    <SubBlockItem
                      key={t.__id}
                      type={item.type}
                      subItem={t}
                      subItems={item?.elements}
                      blockIndex={blockIndex}
                      itemIndex={itemIndex}
                      index={i}
                      removeSubItem={removeSubItem}
                      setSubItem={setSubItem}
                      subKey={"elements"}
                      addSubSubItem={addSubSubItem}
                      removeSubSubItem={removeSubSubItem}
                      setSubSubItem={setSubSubItem}
                      moveSubItem={moveSubItem}
                    ></SubBlockItem>
                  ))}
                </Stack>
                <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
                  <Button
                    onClick={() =>
                      addSubItem({
                        blockIndex,
                        itemIndex,
                        subKey: "elements",
                      })
                    }
                    variant="contained"
                    sx={{ backgroundColor: "#74CA43" }}
                  >
                    Ajouter un Sous-Item
                  </Button>
                </Stack>
              </>
            )}

            {["list"].includes(item.type) && (
              <>
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "separatorColor",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Couleur du separateur"
                  required
                  fullWidth
                  placeholder="Couleur du separateur"
                  value={item.separatorColor}
                />
                <Stack padding={2}>
                  {item?.items?.map((t, i) => (
                    <SubBlockItem
                      key={t.__id}
                      type={item.type}
                      subItem={t}
                      subItems={item?.items}
                      blockIndex={blockIndex}
                      itemIndex={itemIndex}
                      index={i}
                      removeSubItem={removeSubItem}
                      setSubItem={setSubItem}
                      subKey={"items"}
                      addSubSubItem={addSubSubItem}
                      removeSubSubItem={removeSubSubItem}
                      setSubSubItem={setSubSubItem}
                      moveSubItem={moveSubItem}
                    ></SubBlockItem>
                  ))}
                </Stack>
                <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
                  <Button
                    onClick={() =>
                      addSubItem({
                        blockIndex,
                        itemIndex,
                        subKey: "items",
                      })
                    }
                    variant="contained"
                    sx={{ backgroundColor: "#74CA43" }}
                  >
                    Ajouter un Sous-Item
                  </Button>
                </Stack>
              </>
            )}

            {["map"].includes(item.type) && (
              <>
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "icon",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Icon"
                  required
                  fullWidth
                  placeholder="Icon"
                  value={item.icon}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "markerTitle",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Nom du marqueur"
                  required
                  fullWidth
                  placeholder="Nom du marqueur"
                  value={item.markerTitle}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "link",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Lien"
                  required
                  fullWidth
                  placeholder="Lien"
                  value={item.link}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "latitude",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Latitude"
                  required
                  fullWidth
                  placeholder="Latitude"
                  value={item.latitude}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "longitude",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Longitude"
                  required
                  fullWidth
                  placeholder="Longitude"
                  value={item.longitude}
                />
              </>
            )}

            {["tabs"].includes(item.type) && (
              <>
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "selectedColor",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Couleur sélectionné"
                  required
                  fullWidth
                  placeholder="Couleur sélectionné"
                  value={item.selectedColor}
                />
                <TextField
                  onChange={(e) =>
                    setItem({
                      field: "unSelectedColor",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                    })
                  }
                  label="Couleur non sélectionné"
                  required
                  fullWidth
                  placeholder="Couleur non sélectionné"
                  value={item.unSelectedColor}
                />
                <Stack padding={2}>
                  {item?.items?.map((t, i) => (
                    <SubBlockItem
                      key={t.__id}
                      type={item.type}
                      subItem={t}
                      subItems={item?.items}
                      blockIndex={blockIndex}
                      itemIndex={itemIndex}
                      index={i}
                      removeSubItem={removeSubItem}
                      setSubItem={setSubItem}
                      subKey={"items"}
                      addSubSubItem={addSubSubItem}
                      removeSubSubItem={removeSubSubItem}
                      setSubSubItem={setSubSubItem}
                      moveSubItem={moveSubItem}
                    ></SubBlockItem>
                  ))}
                </Stack>
                <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
                  <Button
                    onClick={() =>
                      addSubItem({
                        blockIndex,
                        itemIndex,
                        subKey: "items",
                      })
                    }
                    variant="contained"
                    sx={{ backgroundColor: "#74CA43" }}
                  >
                    Ajouter un Sous-Item
                  </Button>
                </Stack>
              </>
            )}

            <TextField
              onChange={(e) =>
                setItem({
                  field: "style",
                  value: e.target.value,
                  blockIndex,
                  itemIndex,
                  format: true,
                })
              }
              label="Style"
              required
              fullWidth
              placeholder="style"
              value={
                !isString(item.style) ? JSON.stringify(item.style) : item.style
              }
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const SubBlockItem = ({
  type,
  subItem,
  subItems,
  blockIndex,
  itemIndex,
  index,
  removeSubItem,
  setSubItem,
  subKey,
  addSubSubItem,
  removeSubSubItem,
  setSubSubItem,
  moveSubItem,
}) => {
  return (
    <Stack marginBottom={2} spacing={2} direction={"row"}>
      <Stack direction={"column"} justifyContent={"start"} gap={2}>
        {index > 0 && (
          <IconButton
            sx={{
              backgroundColor: "#D7DCE1",
              padding: "5px",
              borderRadius: "5px",
            }}
            aria-label="supprimer"
            onClick={() => {
              moveSubItem({
                subKey,
                itemIndex,
                blockIndex,
                currIndex: index,
                nextIndex: index - 1,
              });
            }}
          >
            <IoMdArrowRoundUp />
          </IconButton>
        )}
        {index < subItems.length - 1 && (
          <IconButton
            sx={{
              backgroundColor: "#D7DCE1",
              padding: "5px",
              borderRadius: "5px",
            }}
            aria-label="supprimer"
            onClick={() => {
              moveSubItem({
                subKey,
                itemIndex,
                blockIndex,
                currIndex: index,
                nextIndex: index + 1,
              });
            }}
          >
            <IoMdArrowRoundDown />
          </IconButton>
        )}
      </Stack>
      <Accordion style={{ padding: 10, margin: 5, width: "100%" }}>
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          <Stack spacing={5} direction="row" marginBottom={1}>
            <IconButton
              sx={{
                backgroundColor: "#D7DCE1",
                padding: "5px",
                borderRadius: "5px",
              }}
              aria-label="supprimer"
              onClick={() => {
                removeSubItem({
                  blockIndex,
                  itemIndex,
                  subIndex: index,
                  subKey,
                });
              }}
            >
              <FaTrash />
            </IconButton>
            <Typography variant="h7">Sous-Item {index + 1}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {["key"].includes(type) && (
              <>
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "icon",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Icon"
                  required
                  fullWidth
                  placeholder="Icon"
                  value={subItem.icon}
                />
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "text",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Texte"
                  required
                  fullWidth
                  placeholder="Texte"
                  value={subItem.text}
                />
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "backgroundColor",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Couleur Fond"
                  required
                  fullWidth
                  placeholder="Couleur Fond"
                  value={subItem.backgroundColor}
                />
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "metric",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Metric"
                  required
                  fullWidth
                  placeholder="Metric"
                  value={subItem.metric}
                />
              </>
            )}

            {["list"].includes(type) && (
              <>
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "title",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Titre"
                  required
                  fullWidth
                  placeholder="Titre"
                  value={subItem.title}
                />
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "icon",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Icon"
                  required
                  fullWidth
                  placeholder="Icon"
                  value={subItem.icon}
                />
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "iconColor",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Couleur Icon"
                  required
                  fullWidth
                  placeholder="Couleur Icon"
                  value={subItem.iconColor}
                />
                <TextField
                  onChange={(e) =>
                    setSubItem({
                      field: "link",
                      value: e.target.value,
                      blockIndex,
                      itemIndex,
                      subKey,
                      subIndex: index,
                    })
                  }
                  label="Lien"
                  required
                  fullWidth
                  placeholder="Lien"
                  value={subItem.link}
                />
              </>
            )}

            {["tabs"].includes(type) && (
              <>
                <FormControl fullWidth>
                  <Select
                    value={subItem.type}
                    label="Type"
                    placeholder="Type"
                    onChange={(e) => {
                      setSubItem({
                        field: "type",
                        value: e.target.value,
                        blockIndex,
                        itemIndex,
                        subKey,
                        subIndex: index,
                      });
                    }}
                  >
                    <MenuItem value={"map"}>map</MenuItem>
                    <MenuItem value={"list"}>list</MenuItem>
                  </Select>
                </FormControl>

                {["map"].includes(subItem.type) && (
                  <>
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "keyDisplay",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Clef Affichage"
                      required
                      fullWidth
                      placeholder="Clef Affichage"
                      value={subItem.keyDisplay}
                    />
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "icon",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Icon"
                      required
                      fullWidth
                      placeholder="Icon"
                      value={subItem.icon}
                    />
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "markerTitle",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Nom du marqueur"
                      required
                      fullWidth
                      placeholder="Nom du marqueur"
                      value={subItem.markerTitle}
                    />
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "link",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Lien"
                      required
                      fullWidth
                      placeholder="Lien"
                      value={subItem.link}
                    />
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "latitude",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Latitude"
                      required
                      fullWidth
                      placeholder="Latitude"
                      value={subItem.latitude}
                    />
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "longitude",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Longitude"
                      required
                      fullWidth
                      placeholder="Longitude"
                      value={subItem.longitude}
                    />
                  </>
                )}

                {["list"].includes(subItem.type) && (
                  <>
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "keyDisplay",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Clef Affichage"
                      required
                      fullWidth
                      placeholder="Clef Affichage"
                      value={subItem.keyDisplay}
                    />
                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "icon",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Icon"
                      required
                      fullWidth
                      placeholder="Icon"
                      value={subItem.icon}
                    />

                    <TextField
                      onChange={(e) =>
                        setSubItem({
                          field: "separatorColor",
                          value: e.target.value,
                          blockIndex,
                          itemIndex,
                          subKey,
                          subIndex: index,
                        })
                      }
                      label="Couleur Separateur"
                      required
                      fullWidth
                      placeholder="Couleur Separateur"
                      value={subItem.separatorColor}
                    />

                    <Stack padding={2}>
                      {subItem?.items?.map((t, i) => (
                        <SubSubBlockItem
                          key={t.__id}
                          subsubItem={t}
                          blockIndex={blockIndex}
                          itemIndex={itemIndex}
                          index={i}
                          subKey={"items"}
                          subIndex={index}
                          addSubSubItem={addSubSubItem}
                          removeSubSubItem={removeSubSubItem}
                          setSubSubItem={setSubSubItem}
                        ></SubSubBlockItem>
                      ))}
                    </Stack>

                    <Stack spacing={3} direction="row" sx={{ marginY: "20px" }}>
                      <Button
                        onClick={() =>
                          addSubSubItem({
                            blockIndex,
                            itemIndex,
                            subKey: "items",
                            subIndex: index,
                          })
                        }
                        variant="contained"
                        sx={{ backgroundColor: "#74CA43" }}
                      >
                        Ajouter un Sous-Sous-Item
                      </Button>
                    </Stack>
                  </>
                )}
              </>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const SubSubBlockItem = ({
  subsubItem,
  blockIndex,
  itemIndex,
  index,
  subKey,
  subIndex,
  removeSubSubItem,
  setSubSubItem,
}) => {
  return (
    <Stack marginBottom={2} spacing={2}>
      <Accordion style={{ padding: 10, margin: 5 }}>
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          <Stack spacing={5} direction="row" marginBottom={1}>
            <IconButton
              sx={{
                backgroundColor: "#D7DCE1",
                padding: "5px",
                borderRadius: "5px",
              }}
              aria-label="supprimer"
              onClick={() => {
                removeSubSubItem({
                  blockIndex,
                  itemIndex,
                  subIndex,
                  subKey,
                  subSubIndex: index,
                });
              }}
            >
              <FaTrash />
            </IconButton>
            <Typography variant="h7">Sous-Sous-Item {index + 1}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <>
              <TextField
                onChange={(e) =>
                  setSubSubItem({
                    field: "title",
                    value: e.target.value,
                    blockIndex,
                    itemIndex,
                    subKey,
                    subIndex,
                    subSubIndex: index,
                  })
                }
                label="Titre"
                required
                fullWidth
                placeholder="Titre"
                value={subsubItem.title}
              />
              <TextField
                onChange={(e) =>
                  setSubSubItem({
                    field: "icon",
                    value: e.target.value,
                    blockIndex,
                    itemIndex,
                    subKey,
                    subIndex,
                    subSubIndex: index,
                  })
                }
                label="Icon"
                required
                fullWidth
                placeholder="Icon"
                value={subsubItem.icon}
              />
              <TextField
                onChange={(e) =>
                  setSubSubItem({
                    field: "iconColor",
                    value: e.target.value,
                    blockIndex,
                    itemIndex,
                    subKey,
                    subIndex,
                    subSubIndex: index,
                  })
                }
                label="Couleur Icon"
                required
                fullWidth
                placeholder="Couleur Icon"
                value={subsubItem.iconColor}
              />
              <TextField
                onChange={(e) =>
                  setSubSubItem({
                    field: "link",
                    value: e.target.value,
                    blockIndex,
                    itemIndex,
                    subKey,
                    subIndex,
                    subSubIndex: index,
                  })
                }
                label="Lien"
                required
                fullWidth
                placeholder="Lien"
                value={subsubItem.link}
              />
            </>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
/* 
DONE : 
description
subtitle
title
image
video
button 
clickableLine
key
list
map

REMOVED :
avatars
images



TODO
 tabs -> faires les subsub items pour le parking
 survey
 
    
    */
