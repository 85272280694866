import Modal from "react-modal";
import swal, { successSwal } from "../swal";
import api from "../api";
import { useEffect, useState } from "react";
export function SurveyGroupModal({
  modalIsOpen,
  refresh,
  closeModal,
  surveyGroup,
}) {
  const [name, setName] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setName(surveyGroup?.name);
  }, [surveyGroup]);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await api.post("/surveygroup/createoredit", {
        name,
        _id: surveyGroup?._id,
      });
      successSwal("Quiz ajouté");
      closeModal?.();
      refresh();
    } catch (e) {
      swal(e);
    } finally {
      setIsLoading(false);
    }
  };

  const customStyles = {
    content: {
      top: "10%",
      left: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "40%",
      overflow: "scroll",
      height: "30%",
      alignContent: "center",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div style={{ textAlign: "center" }}>
        <div className="line-form">
          <div className="div-line-form2">
            <label className="label-check">Nom</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-form"
              type="text"
            />
          </div>
        </div>

        <div>
          <button disabled={isLoading} className="submit" onClick={onSubmit}>
            Valider
          </button>
        </div>
      </div>
    </Modal>
  );
}
