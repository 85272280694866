import { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ColorfulChip from "./ColorChips";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";

export default function MultipleSelectChip({
  filters,
  label,
  selectedFilters,
  setSelectedFilters,
  multiple = true,
}) {
  const dic = useMemo(() => {
    return filters?.reduce((prev, curr) => {
      prev[curr?.value] = curr?.name;
      return prev;
    }, {});
  }, [filters]);

  const handleChange = (event) => {
    if (!multiple) {
      const value = event.target.value;
      setSelectedFilters([value[1] || value[0]]);
    } else {
      const value = event.target.value;
      setSelectedFilters(value);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-chip-label">{label}</InputLabel>
      <Select
        multiple
        value={selectedFilters || []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              return (
                <ColorfulChip
                  key={value}
                  label={dic?.[value]}
                  color={"light-grey"}
                />
              );
            })}
          </Box>
        )}
      >
        {filters?.map((filter) => (
          <MenuItem key={filter.name} value={filter.value}>
            {filter.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
