import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "./api"; // Assurez-vous que ce module exporte correctement la fonction pour appeler votre API
import "./ResetPassword.css";
import logoConvention from "../src/img/logo-convention.png";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Récupération du token depuis l'URL
  const token = new URLSearchParams(window.location.search).get("token");

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      // Appel à l'API pour réinitialiser le mot de passe
      await api.post(
        "/resetPassword",
        { password: data.password },
        { headers: { Authorization: token } }
      );
      setSuccess(true);
    } catch (error) {
      setError(
        "Erreur lors de la réinitialisation du mot de passe :",
        error.message
      );
    }
  };

  return (
    <body className="reset-password-body">
      <div className="reset-password-container">
        <img src={logoConvention} alt="Logo" className="reset-logo" />
        {success ? (
          <div className="reset-success-message">
            Mot de passe réinitialisé avec succès.
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="reset-password-form"
          >
            <div className="form-group">
              <label className="form-label">Nouveau mot de passe</label>
              <input
                type="password"
                {...register("password", {
                  required: "Ce champ est requis",
                  minLength: {
                    value: 8,
                    message:
                      "Le mot de passe doit contenir au moins 8 caractères", // Message d'erreur pour la longueur minimale
                  },
                })}
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
              />
              {errors.password && (
                <div className="invalid-feedback">
                  {errors.password.message}
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="form-label">
                Confirmer le nouveau mot de passe
              </label>
              <input
                type="password"
                {...register("confirmPassword", { required: true })}
                className={`form-control ${
                  errors.confirmPassword ? "is-invalid" : ""
                }`}
              />
              {errors.confirmPassword && (
                <div className="invalid-feedback">Ce champ est requis</div>
              )}
            </div>
            {error && <div className="reset-error-message">{error}</div>}
            <button type="submit" className="reset-submit-button">
              Réinitialiser le mot de passe
            </button>
          </form>
        )}
      </div>
    </body>
  );
};

export default ResetPassword;
