import "./Form.css";
import logo from "./img/logo.png";
import { useForm } from "react-hook-form";
import { useState } from "react";
import api from "./api";
import swal from "./swal";
import { useLocation, useNavigate } from "react-router-dom";

export default function MentionLegales() {
  const navigate = useNavigate();
  return (
    <div>
      <div style={{ marginRight: "20%", marginLeft: "20%" }}>
        <img
          className="logo-cgu"
          src={logo}
          onClick={() => navigate("/")}
        ></img>
        <h1 className="h1-title">CONDITIONS GENERALES D'UTILISATION </h1>
        <p className="text-cgu">
          Convention Pomona 2024 est une application éditée indépendamment par
          ARKONE SAS, au capital de 10 000 euros, dont le siège social est situé
          au 99 rue de la République, 92800 Puteaux, France immatriculée au
          Registre du Commerce et des Sociétés de Paris sous le numéro
          892975368.<br></br>
          <br></br>
          Les présentes Conditions Générales d’Utilisation (ci-après « CGU »)
          ont pour objet de fixer les règles d’utilisation de l’application
          Convention Pomona 2024 et du site du Convention Pomona 2024 par les
          Utilisateurs.
          <br></br>
          <br></br>
          L’utilisation de l’application suppose l’acceptation par l’Utilisateur
          des présentes CGU. En installant l’Application sur votre téléphone et
          en y accédant, vous acceptez l’intégralité des présentes CGU.<br></br>
          <br></br>
          Pour toute question relative aux CGU ou, à l’utilisation de
          l’Application et du site web, vous pouvez nous écrire à l’adresse
          suivante : arkone-france@outlook.fr<br></br>
          <br></br>
          L’Editeur est libre de modifier les CGU à tout moment. Il est donc
          conseillé à l’Utilisateur de se référer régulièrement à la dernière
          version des CGU.<br></br>
          <br></br>
        </p>

        <h2 className="h2-title-name">1. DEFINITIONS</h2>
        <p className="text-cgu">
          On désignera par la suite :<br></br>
          <br></br>« Site » : le site Convention Pomona 2024 et l’ensemble de
          ses pages et écrans.<br></br>« Application » : l’application
          Convention Pomona 2024 et l’ensemble de ses pages et écrans.<br></br>«
          Base de données » : la base de données des informations de l’événement
          utilisée dans le Site.
          <br></br>« Editeur » : la personne morale ou physique, responsable de
          l’édition et du contenu du Site.<br></br>« Utilisateur » : la personne
          utilisant le Site.<br></br>« Partenaires » : les entreprises : Franco
          American Image au capital de 99 170 euros, dont le siège social est
          situé au 99 rue de la république, 92800 Puteaux, France immatriculée
          au Registre du Commerce et des Sociétés de Nanterre sous le numéro 411
          032 105 et le Groupe Pomona, société anonyme au capital social de 6
          567 380,00 € , dont le siège social est situé au 3 AV DU DOCTEUR
          TENINE 92160 ANTONY, France immatriculée au Registre du Commerce et
          des Sociétés de Nanterre sous le numéro 552 044 992, responsables de
          l’événement pour lesquels nous agissons en qualité de sous-traitant.
          <br></br>
        </p>

        <h2 className="h2-title-name"> 2. OBJET</h2>
        <p className="text-cgu">
          Le site et l'application mobile ont pour but de présenter, de
          permettre l’inscription et l'accompagnement sur la Convention Pomona
          2024.
          <br />
          <br></br>
        </p>

        <h2 className="h2-title-name"> 3. CONDITIONS D’UTILISATION</h2>
        <h3 className="h3-title">
          3.1 Accès au Site Web et à l’application mobile
        </h3>
        <p className="text-cgu">
          L’utilisation du Site, de l’Application et de leurs services est
          gratuite et limitée à la France.<br></br>
          L’accès au formulaire est restreint aux personnes ayant été invitées
          et disposant d’un lien pour y accéder.<br></br>
          Une fois l’inscription réalisée depuis ce lien, il est possible de
          modifier ses informations en se connectant avec l’email et le mot de
          passe fourni lors de l’inscription.<br></br>
          L’Utilisateur s’engage à conserver secret son mot de passe et à ne pas
          le divulguer sous quelque forme que ce soit. Il appartient à
          l’Utilisateur de prendre toutes les dispositions nécessaires
          permettant de protéger ses propres données contre toute atteinte. En
          cas de problèmes techniques ou pour des raisons de maintenance,
          l’accessibilité à l’Application pourra être perturbée et/ou suspendue.
          <br></br>
          L’Editeur utilisera les coordonnées communiquées par l’Utilisateur
          lors de sa souscription à un compte personnel. L’Utilisateur s’engage
          à fournir une adresse mail valide lui appartenant. A défaut l’Editeur
          ne sera pas en mesure de lui adresser les différentes informations et
          alertes prévues dans le cadre des présentes Conditions et ne pourra en
          être tenu responsable. Sous réserve des éventuelles pannes et
          interventions de maintenance nécessaires au bon fonctionnement de la
          Plateforme ainsi que des cas de force majeure, l’Editeur s’efforce de
          permettre l’accès au Site 24 heures sur 24, 7 jours sur 7.<br></br>
          Toutefois, l’Editeur ne peut garantir une disponibilité du Site et des
          services et se réserve le droit d’interrompre temporairement ou
          définitivement l’accès à tout ou partie du Site ou tout ou partie des
          services, à tout moment, sans préavis.<br></br>
          La responsabilité de l’Editeur ne saurait être engagée de ces faits.
          En cas d’interruption, l’Utilisateur reconnaît et accepte que
          l’Editeur n’est pas responsable des conséquences qui peuvent en
          découler pour lui-même ou tout tiers.<br></br>
        </p>
        <h3 className="h3-title"> 3.2 Propriété intellectuelle</h3>
        <p className="text-cgu">
          L’usage exclusif des marques, des logos, des logiciels, des éléments
          graphiques, des bases de données, et plus généralement de tous
          contenus proposés par l’Application sont la propriété de L’Editeur, ou
          de ses Partenaires et sont donc protégés par le droit d’auteur et le
          droit des marques
        </p>
        <h3 className="h3-title"> 3.3 Principes de sécurité</h3>
        <p className="text-cgu">
          L’Utilisateur est entièrement responsable de l’usage, de la
          conservation et de la confidentialité de son mot de passe et s’engage,
          dans le cas où il connaît ou suspecte une utilisation frauduleuse de
          son compte, à changer son code personnel et en informer l’Editeur.
          <br></br>
          L’Editeur se réserve la faculté de suspendre l’accès de l’Utilisateur
          en cas de risque concernant la sécurité du système informatique ou de
          présomption d’utilisation non autorisée ou frauduleuse du service.
          <br></br>
          L’Utilisateur est entièrement responsable de l’usage, de la
          conservation et de la confidentialité de son mot de passe, et s’engage
          à informer l’Editeur de toute suspicion d’utilisation frauduleuse de
          son Espace en ligne.<br></br>
          L’Utilisateur s’engage à changer son mot de passe dès lors qu’il
          connaît ou qu’il suspecte une utilisation non autorisée de son compte.
          <br></br>
          L’Editeur se réserve le droit de ne pas donner suite aux demandes
          abusives de modifications du mot de passe formulées par l’Utilisateur.
          <br></br>
          <strong>Opposition et Désinscription</strong>
          <br></br>
          En cas de perte ou de vol de son mot de passe ou si l’Utilisateur
          pense que celui-ci est connu d’un tiers, il doit par tous moyens
          avertir l’Editeur qui procédera à la neutralisation de son accès aux
          services à distance.
        </p>
        <h3 className="h3-title"> 3.4 Droits et Obligations de l’Editeur</h3>
        <p className="text-cgu">
          L’Utilisateur admet expressément utiliser le Site à ses propres
          risques et sous sa responsabilité exclusive.
        </p>
        <h3 className="h3-title">3.5 Autres</h3>
        <p className="text-cgu">
          L’Editeur n’assume aucune responsabilité quant aux dommages
          éventuellement causés en cas d’utilisation frauduleuse du Site, en cas
          de divulgation du mot de passe à une tierce personne, en cas de
          communication d’informations inexactes ou incomplètes par
          l’Utilisateur lors de l’utilisation des services à distance.
          <br></br>
          L’Utilisateur est le seul responsable des coûts additionnels ou
          accessoires qui résultent de son usage de la Plateforme, ceci incluant
          notamment tout coût de communication, d’acquisition de logiciel et/ou
          d’équipement, de réparation ou de stockage de données, de location ou
          d’utilisation d’une licence, etc. En aucun cas la prise en charge de
          tels coûts ne peut être demandée à l’Editeur ou constituer un
          préjudice indemnisable.<br></br>
          De même, la responsabilité de l’Editeur ne saurait être engagée si
          l’utilisation de ces sites ou applications, par l’Utilisateur, lui
          causait un préjudice.<br></br>
        </p>

        <h2 className="h2-title-name">4. COLLECTE DES DONNEES</h2>
        <p className="text-cgu">
          Conformément au Règlement Général de Protection des Données (RGPD), la
          collecte et le traitement des données respectent les principes
          suivants : Licéité, loyauté et transparence, finalités limitées,
          conservation des données réduites dans le temps ,intégrité et
          confidentialité des données collectées et traitées.<br></br>
          <br></br>
          L’Utilisateur peut utiliser son droit d’accès, de rectification,
          d’effacement et à la portabilité des données les concernant et peut
          également, pour des motifs légitimes, s’opposer au traitement et, dans
          les cas prévus par la loi, demander la limitation du traitement de ces
          données.<br></br>
          <br></br>
        </p>

        <h3 className="h3-title"> 4.1 Systèmes de collectes de données</h3>
        <p className="text-cgu">
          <strong>Site web</strong> <br></br>
          Lors de la création d’un compte sur le site web, les données suivantes
          sont collectées pour l’Utilisateur :<br></br>
          Nom, prénom, mot de passe, email, numéro de téléphone,
          branche/direction fonctionnelle, Site/Succursale, moyen de transport
          pour se rendre à l’évènement, choix pour l’évènement.<br></br>
          Ces données sont collectées pour permettre à l’Utilisateur de pouvoir
          participer à la Convention et se connecter à l'application de
          l'événement.<br></br>
          <strong>Application</strong>
          <br></br>
          Lors de l'ajout d'un avatar sur l'application, l'avatar est collecté
          pour l’Utilisateur et associé à son compte.<br></br>Lors de la
          navigation, nous récupérons des statistiques d'utilisation liées à
          votre compte pour améliorer l'application pour des prochains
          événements. Aussi, les données d'usage et les données des participants
          sont transmises à nos Partenaires (Pomona et la Franco American Image)
          et elles ne sont plus de la responsabilité d'Arkone après transmission
          (Contact référent Groupe Pomona : f.sambourg@groupe-pomona.fr).
        </p>
        <h3 className="h3-title"> 4.2 Destinataire des données</h3>
        <p className="text-cgu">
          Les données à caractère personnel strictement nécessaires à
          l’accomplissement de leurs missions sont transmises aux services
          habilités de l’Editeur, à savoir les services informatique, juridique
          et à la direction. <br></br>
          Par ailleurs, elles peuvent être communiquées à des prestataires
          agissant en qualité de sous-traitants. <br></br>
          De même, les données peuvent être partagées avec nos partenaires pour
          la bonne gestion de l’événement. <br></br>
          L’Editeur assure la conformité avec les exigences de protection des
          données pour toutes ses sociétés sous-traitantes. Pour autant,
          l’Editeur reste libre du choix de ses sous-traitants techniques et
          commerciaux à la condition qu’ils présentent les garanties suffisantes
          au regard des exigences de la Réglementation. <br></br>
          Enfin, lorsque cela est requis, les données peuvent être communiquées
          aux autorités administratives ou judiciaires. <br></br>
        </p>
        <h3 className="h3-title"> 4.3 Durée de conservation de données</h3>
        <p className="text-cgu">
          Les données sont conservées jusqu’à ce que leurs finalités soient
          atteintes.
        </p>
        <h3 className="h3-title">
          {" "}
          4.4 Droits des utilisateurs sur les données
        </h3>
        <p className="text-cgu">
          Par ailleurs, conformément à la Réglementation, l’Utilisateur dispose
          des droits suivants :<br></br>
          <ul>
            <li>Droit d’accès aux données</li>
            <li>Droit de rectification</li>
            <li>Droit d’opposition</li>
            <li>Droit à l’effacement de données</li>
            <li>Droit à la portabilité des données</li>
            <li>Droit à la limitation du traitement</li>
            <li>Droit de retirer le consentement</li>
          </ul>
          <br></br>
          Pour exercer ces droits, l’Utilisateur doit contacter l’Editeur à
          l’adresse électronique suivante : arkone-france@outlook.fr
        </p>
        <h3 className="h3-title">
          4.5 Données transférées par le Groupe Pomona
        </h3>
        <p className="text-cgu">
          Le Groupe Pomona vous informe que dans le cadre de l’organisation de
          la Convention Pomona 2024, les données personnelles suivantes ont été
          transmises à ARKONE SAS (société en charge de développement de
          l’application) : - Nom, prénom, Adresse e-mail professionnelle et
          Numéro de téléphone professionnel, branche/direction fonctionnelle,
          Site/Succursale. La base légale du traitement est l’intérêt légitime
          du Groupe. Les données seront conservées pendant la durée de la
          Convention par le prestataire et seront ensuite supprimées. Vous
          pouvez accéder aux données vous concernant, les rectifier, demander
          leur effacement ou exercer votre droit à la limitation du traitement
          de vos données auprès du délégué à la protection des données (DPO) à
          l’adresse suivante : Conformite-interne@groupe-pomona.fr
        </p>
      </div>
      <footer>
        <button className="mention" onClick={() => navigate("/")}>
          Accueil
        </button>
        <button className="mention" onClick={() => navigate("/legalnotice")}>
          Mentions Légales
        </button>
        <button
          className="mention"
          onClick={() => window.open("https://arkone.fr", "_blank")}
        >
          Conçu et développé par Arkone
        </button>
      </footer>
    </div>
  );
}
