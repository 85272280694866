import Modal from "react-modal";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import swal, { successSwal } from "./swal";
import api from "./api";
export function NewUserModal({ modalIsOpen, refresh, closeModal }) {
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      users: [
        {
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          companySection: undefined,
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "users",
  });

  const onSubmit = async (data) => {
    try {
      const userFields = {
        _id: undefined,
        firstName: "Prénom",
        lastName: "Nom",
        email: "Email",
        companySection: "Branche",
      };

      for (const user of data.users) {
        for (const field in userFields) {
          if (user[field] === undefined || user[field] === "") {
            swal(`L'information ${userFields[field]} est manquant`);
            return;
          }
        }
      }

      data.users = data?.users.map((u) => ({
        ...u,
      }));
      await api.post("/addParticipant", { ...data.users[0] });
      successSwal("Ajout confirmée");
      closeModal?.();
      refresh();
    } catch (e) {
      swal(e);
    }
  };

  const customStyles = {
    content: {
      top: "10%",
      left: "10%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "80%",
      overflow: "scroll",
      height: "80%",
      alignContent: "center",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div style={{ textAlign: "center" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-div">
            {fields.map((field, index) => (
              <div key={index}>
                <div className="line-form">
                  <p className="text-form">Informations personnelles</p>
                </div>
                <div className="line-form">
                  <div className="div-line-form2">
                    <label className="label-check">ID</label>
                    <Controller
                      name={`users[${index}]._id`}
                      control={control}
                      defaultValue={field._id}
                      render={({ field }) => (
                        <input {...field} className="input-form" type="text" />
                      )}
                    />
                  </div>
                </div>
                <div className="line-form">
                  <div className="div-line-form2">
                    <label className="label-check">Nom</label>
                    <Controller
                      name={`users[${index}].lastName`}
                      control={control}
                      defaultValue={field.lastName}
                      render={({ field }) => (
                        <input {...field} className="input-form" type="text" />
                      )}
                    />
                  </div>
                  <div className="div-line-form">
                    <label className="label-check">Prénom</label>
                    <Controller
                      name={`users[${index}].firstName`}
                      control={control}
                      defaultValue={field.firstName}
                      render={({ field }) => (
                        <input {...field} className="input-form" type="text" />
                      )}
                    />
                  </div>
                </div>
                <div className="line-form">
                  <div className="div-line-form2">
                    <label className="label-check">Email</label>
                    <Controller
                      name={`users[${index}].email`}
                      control={control}
                      defaultValue={field.email}
                      render={({ field }) => (
                        <input {...field} className="input-form" type="text" />
                      )}
                    />
                  </div>
                  <div className="div-line-form">
                    <label className="label-check">Branche</label>
                    <Controller
                      name={`users[${index}].companySection`}
                      control={control}
                      defaultValue={field.companySection}
                      render={({ field }) => (
                        <select
                          {...field}
                          defaultValue={field.companySection}
                          placeholder="Branche"
                          className="input-form"
                          type="text"
                        >
                          {[
                            <option
                              className="option-disabled"
                              disabled
                              selected
                              value={""}
                            >
                              Sélectionnez votre Branche/Direction fonctionnelle
                            </option>,
                            ...[
                              "Biofinesse",
                              "Conseil de Surveillance",
                              "DAF",
                              "DDCOM",
                              "DIS",
                              "Délice & Création",
                              "DQRSE",
                              "DRH",
                              "DSI",
                              "EpiSaveurs",
                              "PassionFroid",
                              "Pomona Export",
                              "Pomona Import",
                              "Pomona Foodtrade",
                              "Pomona Iberia",
                              "Pomona Suisse",
                              "Présidence",
                              "Relais d'Or",
                              "Refaly",
                              "Saveurs d'Antoine",
                              "TerreAzur",
                            ].map((val, i) => (
                              <option key={i} value={val}>
                                {val}
                              </option>
                            )),
                          ]}
                        </select>
                      )}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div>
            <button className="submit">Valider</button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
