import Modal from "react-modal";
import { FormSubTitle } from "./FormSubtitle";

export function ConfirmModal({ modalIsOpen, closeModal, confirm, text }) {
  const customStyles = {
    content: {
      top: "10%",
      left: "30%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "40%",
      overflow: "scroll",
      height: "40%",
      alignContent: "center",
    },
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="div-global-form" style={{ textAlign: "center" }}>
        <FormSubTitle style={{ justifyContent: "center" }} title={text} />

        <button
          className="submit"
          onClick={closeModal}
          style={{ backgroundColor: "#B30000" }}
        >
          Annuler
        </button>
        <button className="submit" onClick={confirm} style={{ marginLeft: 50 }}>
          Confirmer
        </button>
      </div>
    </Modal>
  );
}
