import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useTransition, animated } from "react-spring";
import { useState, useEffect } from "react";
import api from "./api";
import { useLocation } from "react-router-dom";

import logo from "./img/logoHD.png";

export default function CoursePage() {
  const [courseData, setCourseData] = useState([]);
  let query = new URLSearchParams(useLocation().search);
  const short = query.get("short");
  const [isShort, setIsShort] = useState(Boolean(short));

  const refreshData = async () => {
    const res = await api.get("/admin/courseData");
    if (isShort) {
      setCourseData(
        res.data.data.sort((a, b) => a.liveRank - b.liveRank).splice(0, 5)
      );
    } else {
      setCourseData(
        res.data.data.sort((a, b) => a.liveRank - b.liveRank).splice(0, 10)
      );
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshData();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const transitions = useTransition(
    courseData.sort((a, b) => a.liveRank - b.liveRank),
    {
      keys: (item) => item.bib,
      from: { transform: "translateY(-100%)" },
      enter: { transform: "translateY(0%)" },
      leave: { transform: "translateY(100%)" },
      update: (item) => ({
        transform: `translateY(${(item.liveRank - 1) * (isShort ? 90 : 70)}px)`,
      }),
    }
  );

  return (
    <div style={{ position: "relative" }}>
      <img
        src={logo}
        alt="Logo"
        style={{
          display: "block",
          margin: "0 auto",
          width: isShort ? "20%" : "15%",
        }}
      />
      {transitions((style, item) => (
        <animated.div
          style={{
            ...style,
            position: "absolute",
            width: "100%",
          }}
        >
          <Line {...item} isShort={isShort} />
        </animated.div>
      ))}
    </div>
  );
}

const Line = ({ teamName, liveRank, liveTime, isShort }) => {
  let bgColor = "red";
  if (liveRank == 1) {
    bgColor = "#185D8B";
  }
  if (liveRank == 2) {
    bgColor = "#009BB3";
  }
  if (liveRank == 3) {
    bgColor = "#CBBBA0";
  }

  const isLeader = liveRank <= 3;

  return (
    <Stack
      direction={"line"}
      justifyContent={"space-between"}
      style={
        isLeader
          ? {
              backgroundColor: bgColor,
              margin: 30,
              padding: isShort ? 20 : 15,
              borderRadius: isShort ? 16 : 10,
              textAlign: "center",
              color: "white",
            }
          : {
              backgroundColor: "white",
              margin: 30,
              padding: isShort ? 20 : 15,
              borderRadius: isShort ? 16 : 10,
              textAlign: "center",
              color: "#185D8B",
              border: "2px solid #185D8B",
            }
      }
    >
      <Typography
        style={{
          fontSize: isShort ? 20 : 15,
          flex: 1,
          justifyContent: "start",
          display: "flex",
        }}
      >
        {liveRank}
      </Typography>
      <Typography style={{ fontSize: isShort ? 20 : 15, flex: 10 }}>
        {teamName}
      </Typography>
      <Typography
        style={{
          fontSize: isShort ? 20 : 15,
          flex: 1,
          justifyContent: "end",
          display: "flex",
        }}
      >
        {liveTime}
      </Typography>
    </Stack>
  );
};
