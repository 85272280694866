import Modal from "react-modal";
import swal, { successSwal } from "./swal";
import api from "./api";
import { useEffect, useState } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
export function SelectGuestModal({ modalIsOpen, closeModal, user, users }) {
  const [guestId, setGuestId] = useState("");

  useEffect(() => {
    setGuestId(user?.guestId?._id || "");
  }, [user, users]);

  const onSubmit = async () => {
    try {
      await api.post("/assignGuest", { participantId: user._id, guestId });
      successSwal("Information enregistrée");
      closeModal?.();
    } catch (e) {
      swal(e);
    }
  };

  const customStyles = {
    content: {
      top: "10%",
      left: "35%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "30%",
      overflow: "scroll",
      alignContent: "center",
    },
  };

  const handleChange = (e) => {
    setGuestId(e.target.value);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div style={{ textAlign: "center" }}>
        <div
          className="line-form"
          style={{
            wdth: "100%",
            margin: 10,
            justifyContent: "center",
          }}
        >
          <FormControl fullWidth sx={{ m: 1, mt: 3 }}>
            <Select
              fullWidth
              displayEmpty
              value={guestId}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Sélectionnez une personne</em>;
                }
                const user = users?.find((u) => u._id === guestId);
                return `${user?.firstName} ${user?.lastName}`;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>Sélectionnez une personne</em>
              </MenuItem>
              {users?.map((u) => (
                <MenuItem key={u._id} value={u._id}>
                  {`${u.lastName} ${u.firstName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <p>Demande initiale de la personne : {user?.guestName}</p>
        <div style={{ marginTop: 50 }}>
          <button onClick={onSubmit} className="submit">
            Valider
          </button>
        </div>
      </div>
    </Modal>
  );
}
