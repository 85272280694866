import "./Form.css";
import logo from "./img/logo.png";
import { useForm } from "react-hook-form";
import { useState } from "react";
import api from "./api";
import swal from "./swal";
import { useLocation, useNavigate } from "react-router-dom";

export default function Confirm() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state);
  const data = location.state?.data?.users?.[0];
  return (
    <div style={{ textAlign: "center" }}>
      <body style={{ minHeight: "94vh" }}>
        <img className="logo" src={logo}></img>

        <h1 className="h1-title">rendez-vous le</h1>
        <h2 className="h2-title">14 OCTOBER 2023</h2>
        <hr className="hr"></hr>
        <h2 className="p-form" style={{ textAlign: "center" }}>
          Nous avons bien pris en compte votre inscription.<br></br> Pour toute
          information supplémentaire, nous vous invitons à contacter Maëva Fond
          à l'adresse maeva.fond@points-france.fr
        </h2>
      </body>

      {/* <h2 className="h2-title-name">
        {data.lastName} {data.firstName}
      </h2> */}
      {/* {data.number > 0 && (
        <h2 className="h2-title-name">
          You will {!location.state?.data?.isComing && "not "}come with{" "}
          {data.number} guest(s).
        </h2>
      )} */}

      <footer>
        <button className="mention" onClick={() => navigate("/")}>
          Accueil
        </button>
        <button className="mention" onClick={() => navigate("/legalnotice")}>
          Mentions Légales
        </button>
        <button
          className="mention"
          onClick={() => window.open("https://arkone.fr", "_blank")}>
          Conçu et développé par Arkone
        </button>
      </footer>
    </div>
  );
}
