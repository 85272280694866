import Modal from "react-modal";
import swal, { successSwal } from "./swal";
import api from "./api";
import { useEffect, useState } from "react";
export function UpdatePasswordModal({ modalIsOpen, closeModal, userId }) {
  const [password, setPassword] = useState("");

  useEffect(() => {
    setPassword("");
  }, [userId]);
  const onSubmit = async () => {
    try {
      await api.post("/updatePassword", { _id: userId, password });
      successSwal("Mot de passe modifié");
      closeModal?.();
    } catch (e) {
      swal(e);
    }
  };

  const customStyles = {
    content: {
      top: "10%",
      left: "35%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "30%",
      overflow: "scroll",
      alignContent: "center",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div style={{ textAlign: "center" }}>
        <div
          className="line-form"
          style={{
            wdth: "100%",
            margin: 10,
            justifyContent: "center",
          }}
        >
          <div className="div-line-form2">
            <label className="label-check">Nouveau mot de passe</label>
            <input
              className="input-form"
              type="text"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <button onClick={onSubmit} className="submit">
            Valider
          </button>
        </div>
      </div>
    </Modal>
  );
}
