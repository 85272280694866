import { useEffect, useState } from "react";
import { Label, Text } from "recharts";
import api from "./api";
// import { errorSwal } from "../swal";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const COLORS = ["#0088FE", "#FF8042", "#FFBB28", "#00C49F"];
export default function StatCard({ title, data }) {
  const hotelDic = {
    VillaCaroline: "Villa Caroline",
    GoldenTulip: "GOLDEN TULIP",
    WESTOTEL: "WESTOTEL",
    LeRoyal: "Le Royal",
    Mercure: "Mercure",
    CastelMarieLouise: "Castel Marie-Louise",
    BestWestern: "Best Western Garden & Spa",
    HotelDesDunes: "Hôtel des Dunes",
    LHermitage: "L’Hermitage",
  };
  const [stats, setStats] = useState();
  const getData = async () => {
    try {
      const data = await api.get("/stats");
      setStats(data.data);
      console.log(data.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div style={{ flexDirection: "row", direction: "row", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Card style={{ width: "100%" }}>
            <h2>Inscriptions : </h2>
            <PieChart width={450} height={250}>
              <Pie
                data={stats?.participating}
                cx={220}
                cy={120}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                label={({ name, value }) => `${name}: ${value}`}
              >
                {stats?.participating?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
                <Label position="center" color="#000000" fill="#000000">
                  {`Total : ${stats?.participating.reduce(
                    (prev, curr) => prev + curr.value,
                    0
                  )}`}
                </Label>
              </Pie>

              <Legend />
            </PieChart>
          </Card>
        </div>
        <div style={{ flex: 1 }}>
          <Card style={{ width: "100%" }}>
            <h2>Rooming : </h2>
            <PieChart width={450} height={300}>
              <Pie
                data={stats?.roomings}
                cx={220}
                cy={120}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                label={({ name, value }) => `${name}: ${value}`}
              >
                {stats?.roomings?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
                <Label position="center" color="#000000" fill="#000000">
                  {`Total : ${stats?.roomings.reduce(
                    (prev, curr) => prev + curr.value,
                    0
                  )}`}
                </Label>
              </Pie>

              <Legend />
            </PieChart>
          </Card>
        </div>
      </div>
      <div style={{ flexDirection: "row", direction: "row", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Card style={{ flex: 1 }}>
            <h2>Transports Aller: </h2>
            <PieChart width={450} height={300}>
              <Pie
                data={stats?.transportsGo}
                cx={220}
                cy={120}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                label={({ name, value }) => `${name}: ${value}`}
              >
                {stats?.transportsGo?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </Card>
        </div>
        <div style={{ flex: 1 }}>
          <Card style={{ flex: 1 }}>
            <h2>Transports Retour: </h2>
            <PieChart width={450} height={300}>
              <Pie
                data={stats?.transportsBack}
                cx={220}
                cy={120}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                label={({ name, value }) => `${name}: ${value}`}
              >
                {stats?.transportsBack?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </Card>
        </div>
      </div>

      <div style={{ flexDirection: "row", direction: "row", display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Card>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2>Transferts</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BarChart
                    width={400}
                    height={300}
                    data={stats?.planeArrivalHours?.transfert}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                  <h2>Arrivées Avion ({stats?.planeArrivalTransfertCount}) </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BarChart
                    width={400}
                    height={300}
                    data={stats?.planeDepartHours?.transfert}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                  <h2>Retours Avion ({stats?.planReturnTransfert})</h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <h2>Arrivées Train ({stats?.trainArrivalTransfert}) </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BarChart
                    width={400}
                    height={300}
                    data={stats?.trainDepartHours?.transfert}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                  <h2>Retours Train ({stats?.trainReturnTransfert}) </h2>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div style={{ flexDirection: "row", direction: "row", display: "flex" }}>
        <div style={{ flex: 1, display: "flex" }}>
          <Card>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2>Infos : </h2>

              <p>Prolongation Avion : {stats?.planeLeavesAfter}</p>
              <p>Prolongation Train : {stats?.trainLeavesAfter}</p>

              <p>Train Arrivée 15h49 : {stats?.trainArrivesOnTime}</p>
              <p>Arrivée plus tardive : {stats?.trainArrivesLater}</p>
            </div>
          </Card>
        </div>
        <div style={{ flex: 1 }}>
          <Card style={{ width: "100%" }}>
            <h2>Nombre de pax par hôtels : </h2>
            <PieChart width={450} height={300}>
              <Pie
                data={stats?.hotelStats.map((hotelStat) => ({
                  name: hotelDic[hotelStat.hotelId] || hotelStat.hotelId, // Utilisez hotelDic pour obtenir le nom complet, sinon utilisez l'ID
                  value: hotelStat.count,
                }))}
                cx={220}
                cy={120}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                label={({ name, value }) => `${name}: ${value}`}
              >
                {stats?.hotelStats.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </Card>
        </div>
      </div>
    </div>
  );
}

export const Card = (props: any) => {
  return (
    <div
      className="card-stat"
      style={{ flex: 1, flexDirection: "row", margin: 20, display: "flex" }}
    >
      {props.children}
    </div>
  );
};
