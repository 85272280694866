import {
  Card,
  CardContent,
  Chip,
  Paper,
  Typography,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/DeleteForeverRounded";
import api from "../api";
import moment from "moment";
import confirmAction from "../ConfirmAction";
import { FaTrash } from "react-icons/fa6";

export default function SavedMessage({
  users,
  messages,
  refreshMessages,
  hotels,
  restaurants,
}) {
  return (
    <>
      {messages?.map((m) => (
        <MessageCard
          refreshMessages={refreshMessages}
          hotels={hotels}
          restaurants={restaurants}
          users={users}
          message={m}
        />
      ))}
    </>
  );
}

const MessageCard = ({
  message,
  hotels,
  restaurants,
  users,
  refreshMessages,
}) => {
  const filtersKey = [
    "transportsGo",
    "transportsBack",
    "wantsTransfertGo",
    "wantsTransfertBack",
    "hotels",
    "restaurants",
    "filterUser",
    "ignores",
    "isCaptain",
    "participateCourse",
    "hasHotel",
  ];
  const filtersDic = {
    transportsGo: "Transport Aller",
    transportsBack: "Transport Retour",
    wantsTransfertGo: "Transfert Aller",
    wantsTransfertBack: "Transfert Retour",
    hotels: "Hôtel",
    restaurants: "Restaurant",
    filterUser: "Users",
    ignores: "Ignorés",
    isCaptain: "Capitaine",
    participateCourse: "Participe course",
    hasHotel: "A Hôtel",
  };

  return (
    <Card style={{ marginTop: 5 }}>
      <Paper sx={{ display: "flex" }}>
        <CardContent
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "column",
            flex: 15,
          }}
        >
          <Typography variant="h4">
            {message.title ? `Titre : ${message.title}` : ""}
          </Typography>
          <Typography variant="h5">
            Date : {moment(message.sendAt).format("DD/MM/YYYY HH:mm")}
          </Typography>
          <Stack direction={"row"} gap={5}>
            <Chip
              size="small"
              label={message.type}
              style={{
                backgroundColor: message.type === "SMS" ? "#a82555" : "#258ea8",
                color: "white",
                width: "fit-content",
              }}
            />
            {message.addNews && (
              <Chip
                size="small"
                label={"News"}
                style={{
                  backgroundColor: "#254299",
                  color: "white",
                  width: "fit-content",
                }}
              />
            )}
          </Stack>
          {message.redirect && (
            <Chip
              size="small"
              label={"Redirect : " + message.redirect}
              style={{
                backgroundColor: "#f0b75b",
                color: "white",
                width: "fit-content",
              }}
            />
          )}

          <Box
            sx={{
              backgroundColor: "#D4E0ED",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Typography>{message.message}</Typography>
          </Box>
          <Typography variant="h5">Filtres :</Typography>
          <Stack
            direction={"row"}
            display={"flex"}
            flex={1}
            gap={5}
            flexWrap={"wrap"}
          >
            {filtersKey
              ?.filter((key) => {
                if (!message[key]) {
                  return false;
                }
                if (typeof message[key] == "object") {
                  return message[key].length > 0;
                }
              })
              ?.map((key) => {
                return (
                  <Stack spacing={2} direction={"row"}>
                    <Chip
                      size="small"
                      label={`${filtersDic[key]} : ${message[key]?.map(
                        (value) =>
                          getValues({
                            key,
                            value,
                            hotels,
                            restaurants,
                            users,
                          })
                      )}`}
                      style={{
                        backgroundColor: "#FC129E",
                        color: "white",
                        width: "fit-content",
                      }}
                    />
                  </Stack>
                );
              })}
          </Stack>
        </CardContent>
        <Stack
          spacing={8}
          direction={"column"}
          sx={{ padding: "15px", flex: 1 }}
        >
          <IconButton
            sx={{
              backgroundColor: "#D7DCE1",
              padding: "10px",
              borderRadius: "5px",
            }}
            aria-label="edit"
            onClick={() => {
              confirmAction({
                action: async () => {
                  await api.post("/message/delete", { id: message._id });
                  refreshMessages();
                },
                promptText: "Voulez vous supprimer le message ?",
                confirmText: "Le message a bien été supprimé",
              });
            }}
          >
            <FaTrash />
          </IconButton>
        </Stack>
      </Paper>
    </Card>
  );
};

const getValues = ({ key, value, users, hotels, restaurants }) => {
  if (
    [
      "wantsTransfertGo",
      "wantsTransfertBack",
      "isCaptain",
      "participateCourse",
      "hasHotel",
    ].includes(key)
  ) {
    return {
      true: "Oui",
      false: "Non",
    }[value];
  }

  if (
    [
      "transportsGo",
      "transportsBack",
      "hotels",
      "restaurants",
      "filterUser",
      "ignores",
    ].includes(key)
  ) {
    let list;

    if (["transportsGo", "transportsBack"].includes(key)) {
      list = [
        { value: "Avion", name: "Avion" },
        { value: "Train", name: "Train" },
        { value: "Voiture", name: "Voiture" },
        { value: "Je ne sais pas", name: "Je ne sais pas" },
      ];
    }
    if (["filterUser", "ignores"].includes(key)) {
      list = users;
    }
    if (key == "hotels") {
      list = hotels;
    }
    if (key == "restaurants") {
      list = restaurants;
    }

    console.log(key, list);

    return list.find((item) => item.value == value)?.name;
  }
};
