import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
// import LeftMenu from "../Admin/LeftMenu";
import { useState, useEffect } from "react";
import CreateMessage from "./Notifications/CreateMessage";
import SavedMessage from "./Notifications/SavedMessages";
import api from "./api";
import CalendarDisplay from "./Notifications/CalendarDisplay";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function NotificationsPages() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const [filterHotels, setFilterHotels] = useState();
  const [filterRestaurants, setFilterRestaurants] = useState();
  const [filterUsers, setFilterUsers] = useState();

  const [messages, setMessages] = useState([]);

  const refreshMessages = async () => {
    const res = await api.get("/message/get", {});
    setMessages([...res.data.sms, ...res.data.notifs]);
  };

  useEffect(() => {
    const refreshParticipant = async () => {
      try {
        const groups = await api.get("/participants");
        setFilterUsers(
          groups.data
            .filter((g) => !g.masterId)
            .map((g) => ({
              value: g._id,
              name: `${g.lastName} ${g.firstName}`,
            }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    const refreshHotels = async () => {
      try {
        const groups = await api.get("/admin/hotels");
        setFilterHotels(
          groups.data.map((g) => ({
            value: g._id,
            name: g.name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    const refreshRestaurants = async () => {
      try {
        const groups = await api.get("/admin/restaurants");
        setFilterRestaurants(
          groups.data.map((g) => ({
            value: g._id,
            name: g.name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    refreshParticipant();
    refreshHotels();
    refreshRestaurants();
    refreshMessages();
  }, []);

  console.log(filterUsers);

  return (
    <Box sx={{ display: "flex" }}>
      {/* <LeftMenu></LeftMenu> */}
      <Container maxWidth="lg">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={handleChange}>
            <Tab label="Créer un message" {...a11yProps(0)} />
            <Tab label="Messages enregistrés" {...a11yProps(1)} />
            <Tab label="Calendrier" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabIndex} index={0}>
          <CreateMessage
            filterHotels={filterHotels}
            filterRestaurants={filterRestaurants}
            filterUsers={filterUsers}
          ></CreateMessage>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <SavedMessage
            hotels={filterHotels}
            restaurants={filterRestaurants}
            filterUsers={filterUsers}
            users={filterUsers}
            messages={messages}
            refreshMessages={refreshMessages}
          ></SavedMessage>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <CalendarDisplay messages={messages}></CalendarDisplay>
        </CustomTabPanel>
      </Container>
    </Box>
  );
}
