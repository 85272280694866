import { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import "./Backoffice.css";
import { FaPenToSquare, FaTrash, FaTrainTram } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi2";
import api from "./api";
import Modal from "react-modal";
import errorSwal from "./swal";
import moment from "moment";
import ExcelJS from "exceljs";
import confirmAction from "./ConfirmAction";
import Form from "./Form";
import StatCard from "./StatCard";
import { NewUserModal } from "./NewUserModal";
import { UpdatePasswordModal } from "./UpdatePasswordModal";
import useAuthStore from "./useAuthStore";
import { SelectGuestModal } from "./SelectGuestModal";
import { MdOutlineEmail } from "react-icons/md";
import TransportModalAdmin from "./TransportModalAdmin";
import LeftMenu from "./Admin/LeftMenu";
import { HotelRestaurantModal } from "./HotelRestaurantModal";
import { FaHotel } from "react-icons/fa";

const booleanDic = {
  undefined: "",
  true: "Oui",
  false: "Non",
};

const UserCard = ({
  contact,
  setGroupEditing,
  refreshParticipant,
  setUserPasswordUpdate,
  setShowModalGuest,
  setParticipantTransportEditing,
  setParticipantHotelRestaurantEditing,
}) => {
  const hotelDic = {
    VillaCaroline: "Villa Caroline",
    GoldenTulip: "GOLDEN TULIP",
    WESTOTEL: "WESTOTEL",
    LeRoyal: "Le Royal",
    Mercure: "Mercure",
    CastelMarieLouise: "Castel Marie-Louise",
    BestWestern: "Best Western Garden & Spa",
    HotelDesDunes: "Hôtel des Dunes",
    LHermitage: "L’Hermitage",
  };
  const [editingEmail, setEditingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState(contact.email);
  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };
  const updateEmail = async () => {
    try {
      console.log(newEmail);
      await api.post("/updateEmail", { _id: contact._id, newEmail: newEmail });
      setEditingEmail(false);
      refreshParticipant();
    } catch (e) {
      console.error("Erreur lors de la mise à jour de l'email", e);
    }
  };
  return (
    <tr className="transition-colors duration-200 hover:bg-gray-200">
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact?.registeredAt
            ? moment(contact?.createdAt).format("YYYY-MM-DD hh:mm")
            : ""}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.companySection}
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        {editingEmail ? (
          <div>
            <input
              type="email"
              value={newEmail}
              onChange={handleEmailChange}
              onBlur={updateEmail}
            />
            <button onClick={updateEmail}>Valider</button>
          </div>
        ) : (
          <div
            className="text-sm text-gray-900 text-center cursor-pointer"
            onClick={() => setEditingEmail(true)}
          >
            {contact.email}
          </div>
        )}
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.firstName}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.lastName}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {booleanDic[contact.isParticipating]}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {booleanDic[contact.wantToBookAnHotel]}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {hotelDic[contact.hotel]}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.restaurant}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">{contact.table}</div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {booleanDic[contact.wantsDoubleRoom] +
            "" +
            (contact.wantsDoubleRoom && contact?.guestId
              ? ` ( ${contact?.guestId?.lastName} ${contact?.guestId?.firstName} )`
              : contact?.wantsDoubleRoom && contact?.guestName
              ? ` ( ${contact?.guestName} )`
              : "")}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {booleanDic[contact.isSuperAdmin]}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">{contact.team}</div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {booleanDic[contact.isCaptain]}
        </div>
      </td>

      <td
        className="px-4 py-4 text-right text-sm font-medium"
        style={{ display: "flex", justifyContent: "center", gap: 5 }}
      >
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={async (e) => {
            confirmAction({
              action: async () => {
                await api.post("/deleteParticipant", { id: contact._id });
                refreshParticipant();
              },
              promptText: `Voulez vous supprimer le participant ${contact.firstName} ${contact.lastName}?`,
              confirmText: "Participant Supprimé",
            });
          }}
          title="Supprimer"
        >
          <FaTrash />
        </button>
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={(e) => {
            e.preventDefault();
            setGroupEditing(contact._id);
          }}
          title="Modifiez les informations de cette personne."
        >
          <FaPenToSquare />
        </button>

        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={(e) => {
            e.preventDefault();
            setUserPasswordUpdate(contact._id);
          }}
          title="Modifiez le mot de passe de cette personne."
        >
          <RiLockPasswordFill />
        </button>

        {contact.wantsDoubleRoom && (
          <button
            className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
            onClick={(e) => {
              e.preventDefault();
              setShowModalGuest(contact);
            }}
            title="Modifiez l'invité"
          >
            <HiUsers />
          </button>
        )}

        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={(e) => {
            e.preventDefault();
            setParticipantTransportEditing(contact);
          }}
          title="Modifier les informations de transport"
        >
          <FaTrainTram />
        </button>

        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={() => {
            confirmAction({
              action: async () => {
                await api.post("/admin/sendBatchEmails", {
                  mailType: "1",
                  participantIds: [contact._id],
                });
              },
              promptText: "Voulez-vous envoyer le mail de transport",
              confirmText: "Mail envoyé",
            });
          }}
          title="Envoyer le mail de transport"
        >
          <MdOutlineEmail />
        </button>

        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={() => {
            setParticipantHotelRestaurantEditing(contact);
          }}
          title="Modifier Hotel/Restaurant"
        >
          <FaHotel />
        </button>
      </td>
    </tr>
  );
};

const UserList = ({
  groups,
  setGroupEditing,
  setUserCommenting,
  refreshParticipant,
  data,
  participants,
  setData,
  allParticipants,
  setParticipantTransportEditing,
  setParticipantHotelRestaurantEditing,
}) => {
  const [userPasswordUpdate, setUserPasswordUpdate] = useState("");
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalGuest, setShowModalGuest] = useState(undefined);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const refreshParticipantSearch = async () => {
    let newList = [];
    if (search !== "") {
      newList = participants?.filter(
        (participant) =>
          participant?.firstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          String(participant?.table)
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          participant?.birthLastName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          participant?.email.toLowerCase().includes(search.toLowerCase()) ||
          participant?.companyName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          participant?.companySection
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          participant?.phone
            ?.replace(" ", "")
            .toLowerCase()
            .includes(search.replace(" ", "").toLowerCase())
      );
    } else if (participants && participants.length) {
      newList = participants;
    }
    setData(newList);
  };

  useEffect(() => {
    refreshParticipant();
  }, []);

  useEffect(() => {
    refreshParticipantSearch();
  }, [search, participants]);

  const columns = useMemo(
    () => [
      {
        Header: "Date d'inscription",
        accessor: "registeredAt",
        sortType: (rowA, rowB) =>
          rowA.original.registeredAt
            ?.toLowerCase()
            .localeCompare(rowB.original.registeredAt?.toLowerCase()),
      },
      {
        Header: "Branche",
        accessor: "ghg",
        sortType: (rowA, rowB) =>
          rowA.original.companyName
            ?.toLowerCase()
            .localeCompare(rowB.original.companyName?.toLowerCase()),
      },
      {
        Header: "Email",
        accessor: "e",
        sortType: (rowA, rowB) =>
          rowA.original.email
            ?.toLowerCase()
            .localeCompare(rowB.original.email?.toLowerCase()),
      },

      {
        Header: "Prénom",
        accessor: "c",
        sortType: (rowA, rowB) =>
          rowA.original.firstName
            ?.toLowerCase()
            .localeCompare(rowB.original.firstName?.toLowerCase()),
      },
      {
        Header: "Nom",
        accessor: "d",
        sortType: (rowA, rowB) =>
          rowA.original.lastName
            ?.toLowerCase()
            .localeCompare(rowB.original.lastName?.toLowerCase()),
      },
      {
        Header: "Participe",
        accessor: "g",
      },
      {
        Header: "Hotel",
        accessor: "h",
      },
      {
        Header: "Nom Hotel",
        accessor: "hotel",
      },
      {
        Header: "Restaurant",
        accessor: "restaurant",
      },
      {
        Header: "Table",
        accessor: "table",
      },
      {
        Header: "Chambre Double",
        accessor: "j",
        sortType: (rowA, rowB) =>
          rowA.original.wantsDoubleRoom
            ?.toString()
            .localeCompare(rowB.original.wantsDoubleRoom?.toString()),
      },
      {
        Header: "Est un super admin",
        accessor: "i",
      },
      {
        Header: "Equipe",
        accessor: "team",
      },
      {
        Header: "Capitaine",
        accessor: "isCaptain",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,
    usePagination
  );

  return (
    <>
      <NewUserModal
        modalIsOpen={showModalAdd}
        refresh={refreshParticipant}
        closeModal={() => setShowModalAdd(false)}
      ></NewUserModal>
      <UpdatePasswordModal
        modalIsOpen={userPasswordUpdate != ""}
        closeModal={() => setUserPasswordUpdate("")}
        userId={userPasswordUpdate}
      ></UpdatePasswordModal>
      <SelectGuestModal
        modalIsOpen={showModalGuest}
        closeModal={() => setShowModalGuest(undefined)}
        user={showModalGuest}
        users={allParticipants.filter(
          (u) => u.wantsDoubleRoom && u._id !== showModalGuest?._id
        )}
      />
      <div className="flex flex-col body-backoffice">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full ">
            <div className="header-back">
              <h1 className="title-back">Convention Pomona 2024</h1>

              <div className="div-label">
                {/* <ExportCSV participants={allParticipants} /> */}
                <label htmlFor="search" style={{ flex: 2 }}>
                  <input
                    id="Recherche"
                    placeholder="Recherche"
                    type="text"
                    className="search-bar"
                    onChange={handleSearch}
                  />
                </label>
                <div
                  className="button-Backoffice-div"
                  style={{ flexDirection: "row" }}
                >
                  <button
                    onClick={() => setShowModalAdd(true)}
                    className="button-Backoffice"
                  >
                    Ajouter
                  </button>
                </div>
                <FileUploader refresh={refreshParticipant}></FileUploader>
                <FileUploaderHotels
                  refresh={refreshParticipant}
                ></FileUploaderHotels>
                <ExportCSV participants={participants}></ExportCSV>
              </div>
            </div>
            <div className="shadow overflow-hidden border-gray-200 sm:rounded-lg table">
              <table
                className="min-w-full divide-y divide-gray-200"
                style={{ width: "100%" }}
                {...getTableProps()}
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider p-5"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <UserCard
                        refreshParticipant={refreshParticipant}
                        setUserCommenting={setUserCommenting}
                        groups={groups}
                        setGroupEditing={setGroupEditing}
                        key={key}
                        contact={row.original}
                        setUserPasswordUpdate={setUserPasswordUpdate}
                        setShowModalGuest={setShowModalGuest}
                        setParticipantTransportEditing={
                          setParticipantTransportEditing
                        }
                        setParticipantHotelRestaurantEditing={
                          setParticipantHotelRestaurantEditing
                        }
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<"}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">>"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>

                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="ml-4 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Afficher {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <StatCard />
        </div>
      </div>
    </>
  );
};

function ModalEditGroup({ modalIsOpen, closeModal, groupEditing }) {
  const customStyles = {
    content: {
      top: "10%",
      left: "10%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "80%",
      overflow: "scroll",
      height: "80%",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div>
        <Form id={groupEditing} closeModal={closeModal} />
      </div>
    </Modal>
  );
}

export default function Backoffice() {
  const [participants, setParticipants] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [data, setData] = useState([]);
  const [groupEditing, setGroupEditing] = useState();
  const [participantTransportEditing, setParticipantTransportEditing] =
    useState();
  const [
    participantHotelRestaurantEditing,
    setParticipantHotelRestaurantEditing,
  ] = useState();
  const { currentUser } = useAuthStore();
  const refreshParticipant = async () => {
    try {
      const groups = await api.get("/participants");
      setParticipants(groups.data.filter((g) => !g.masterId));
      setData(groups.data.filter((g) => !g.masterId));
      setAllParticipants(groups.data);
    } catch (error) {
      useAuthStore.getState().logout();
    }
  };

  useEffect(() => {
    refreshParticipant();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LeftMenu></LeftMenu>
      <div style={{ flex: 6, marginTop: 40 }}>
        <HotelRestaurantModal
          participant={participantHotelRestaurantEditing}
          modalIsOpen={participantHotelRestaurantEditing != undefined}
          refresh={refreshParticipant}
          closeModal={() => setParticipantHotelRestaurantEditing(undefined)}
        />
        <ModalEditGroup
          modalIsOpen={groupEditing != undefined}
          closeModal={() => {
            setGroupEditing(undefined);
            refreshParticipant();
          }}
          groupEditing={groupEditing}
        ></ModalEditGroup>
        <TransportModalAdmin
          modalIsOpen={participantTransportEditing != undefined}
          closeModal={() => setParticipantTransportEditing()}
          participant={participantTransportEditing}
        ></TransportModalAdmin>

        <UserList
          setGroupEditing={setGroupEditing}
          refreshParticipant={refreshParticipant}
          data={data}
          setData={setData}
          participants={participants}
          allParticipants={allParticipants}
          setParticipantTransportEditing={setParticipantTransportEditing}
          setParticipantHotelRestaurantEditing={
            setParticipantHotelRestaurantEditing
          }
        />

        {currentUser.isSuperAdmin && (
          <>
            <button
              onClick={async () => {
                confirmAction({
                  action: async () => {
                    await api.post("/admin/generateTeams", {});
                  },
                  promptText:
                    "Voulez-vous regénérer les équipes ? Attention : cela effacera les équipes actuelles",
                  confirmText: "Equipes générées",
                });
              }}
              className="button-Backoffice"
            >
              Générer les équipes
            </button>
            <button
              onClick={async () => {
                confirmAction({
                  action: async () => {
                    await api.post("/admin/generateRestaurants", {});
                  },
                  promptText:
                    "Voulez-vous faire la répartition des restaurants ? Attention : cela effacera la répartition actuelle",
                  confirmText: "Done :)",
                });
              }}
              className="button-Backoffice"
            >
              Répartir les restaurants
            </button>
            <button
              onClick={async () => {
                confirmAction({
                  action: async () => {
                    await api.post("/admin/generateTables", {});
                  },
                  promptText:
                    "Voulez-vous faire la répartition des tables ? Attention : cela effacera la répartition actuelle",
                  confirmText: "Done :)",
                });
              }}
              className="button-Backoffice"
            >
              Répartir les tables
            </button>

            <button
              onClick={async () => {
                confirmAction({
                  action: async () => {
                    await api.post("/admin/formatPhoneNumber", {});
                  },
                  promptText: "Voulez-vous formatter les numéros de téléphone",
                  confirmText: "Done :)",
                });
              }}
              className="button-Backoffice"
            >
              Formatter les numéros de téléphone
            </button>
          </>
        )}
      </div>
    </div>
  );
}

const ExportCSV = ({ participants }) => {
  const createWorkbook = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Export");

    const restaurantDic = {
      ponton: "Le Ponton",
      barbarossa: "Barbarossa",
    };

    const hotelDic = {
      VillaCaroline: "Villa Caroline",
      GoldenTulip: "GOLDEN TULIP",
      WESTOTEL: "WESTOTEL",
      LeRoyal: "Le Royal",
      Mercure: "Mercure",
      CastelMarieLouise: "Castel Marie-Louise",
      BestWestern: "Best Western Garden & Spa",
      HotelDesDunes: "Hôtel des Dunes",
      LHermitage: "L’Hermitage",
    };

    const headers = [
      "Matricule unique",
      "Nom",
      "Prénom",
      "Mail",
      "Téléphone",
      "Branche/Dir fonct",
      "Région/Site",
      "Participation",
      "Hébergement",
      "Moyen de Transport Aller",
      "Moyen de Transport Retour",
      "Heure d'arrivée",
      "Heure de départ",
      "Prolongation",
      "Transfert Aller",
      "Transfert Retour",
      "Taille",
      "Contre-Indications Médicales",
      "Chambre Double",
      "Invité",
      "Hotel",
      "Restaurant",
      "Table",
      "Remarque",
      "Esprit de conquête",
      "Equipe",
      "Capitaine",
      "Super Admin",
    ];

    worksheet.addRow(headers);
    worksheet.addRows(
      participants.map((contact) => {
        return [
          contact?._id,
          contact?.lastName,
          contact?.firstName,
          contact?.email,
          contact?.phoneNumber,
          contact?.companySection,
          contact?.city,
          booleanDic[contact?.isParticipating],
          booleanDic[contact?.wantToBookAnHotel],
          contact?.transportGo,
          contact?.transportBack,
          contact.transportGo === "Train"
            ? contact.arrivesOnTimeTrain
              ? "15:49"
              : ""
            : contact.transportGo === "Avion"
            ? contact.arrivesAt
            : "",
          contact.transportBack === "Train"
            ? contact.leavesAfter
              ? ""
              : contact.leavesAt
            : contact.transportBack === "Avion"
            ? contact.leavesAfter
              ? ""
              : contact.leavesAt
            : "",
          ["Train", "Avion"].includes(contact.transportBack) &&
          contact.leavesAfter
            ? "Oui"
            : "",

          contact.hasAnsweredTransport
            ? (contact.transportGo === "Train" &&
                contact.arrivesOnTimeTrain &&
                contact.wantsTransfertGo) ||
              (contact.transportGo === "Avion" && contact.wantsTransfertGo)
              ? "Oui"
              : "Non"
            : "",

          contact.hasAnsweredTransport
            ? (contact.transportBack === "Train" &&
                contact.wantsTransfertBack) ||
              (contact.transportBack === "Avion" && contact.wantsTransfertBack)
              ? "Oui"
              : "Non"
            : "",
          contact?.size,
          contact?.medicalInfo,
          booleanDic[contact?.wantsDoubleRoom],
          contact?.guestName,
          hotelDic[contact?.hotel],
          restaurantDic[contact?.restaurant],
          contact?.table,
          contact?.remarks,
          contact?.conquestFields,
          contact?.team,
          booleanDic[contact?.isCaptain],
          contact?.isSuperAdmin,
        ];
      })
    );

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Export.xlsx";
    link.click();
  };

  return (
    <div className="button-Backoffice-div" style={{ flexDirection: "row" }}>
      <button onClick={createWorkbook} className="button-Backoffice">
        Exporter
      </button>
    </div>
  );
};

const FileUploader = ({ refresh }) => {
  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv,.xlsx";
    input.click();
    input.onchange = async function () {
      if (this.files[0]) {
        const formData = new FormData();
        formData.append("file", this.files[0], "file_upload");
        formData.append("upload_file", true);

        try {
          const response = await api.post("/import", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.status === 200) {
            refresh();
          }
        } catch (e) {
          errorSwal(e?.responseJSON?.message);
        }
      }
    };
  };

  return (
    <div className="button-Backoffice-div">
      <button onClick={importFile} className="button-Backoffice">
        Importer
      </button>
    </div>
  );
};

const FileUploaderHotels = ({ refresh }) => {
  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv,.xlsx";
    input.click();
    input.onchange = async function () {
      if (this.files[0]) {
        const formData = new FormData();
        formData.append("file", this.files[0], "file_upload");
        formData.append("upload_file", true);

        try {
          const response = await api.post("/importHotels", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.status === 200) {
            refresh();
          }
        } catch (e) {
          errorSwal(e?.responseJSON?.message);
        }
      }
    };
  };

  return (
    <div className="button-Backoffice-div">
      <button onClick={importFile} className="button-Backoffice">
        Importer Hôtels
      </button>
    </div>
  );
};
