import { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import "./Backoffice.css";
import { FaPenToSquare, FaTrash } from "react-icons/fa6";
import api from "./api";
import moment from "moment";
import confirmAction from "./ConfirmAction";
import { SurveyGroupModal } from "./Surveys/SurveyGroupModal";
import { HiSwitchHorizontal } from "react-icons/hi";
import { SurveyModal } from "./Surveys/SurveyModal";
import ExcelJS from "exceljs";
import { FaFileDownload } from "react-icons/fa";
const SurveyGroupCard = ({
  contact,
  setSurveyGroupEditing,
  refresh,
  setSelectedSurveyGroup,
  selectedSurveyGroup,
}) => {
  return (
    <tr
      className="transition-colors duration-200 hover:bg-gray-200 quizzRow"
      style={
        selectedSurveyGroup?._id === contact._id
          ? { backgroundColor: "#e7f7fc" }
          : {}
      }
      onClick={() => setSelectedSurveyGroup(contact)}
    >
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {moment(contact?.createdAt).format("YYYY-MM-DD hh:mm")}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">{contact.name}</div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.surveys.length}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.isActive ? "Actif" : "En attente"}
        </div>
      </td>

      <td
        className="px-4 py-4 text-right text-sm font-medium"
        style={{ display: "flex", justifyContent: "center", gap: 5 }}
      >
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={async (e) => {
            confirmAction({
              action: async () => {
                await api.post("/surveygroup/delete", { id: contact._id });
                if (selectedSurveyGroup === contact._id) {
                  setSelectedSurveyGroup(undefined);
                }
                refresh();
              },
              promptText: `Voulez vous supprimer le quiz ${contact.name} ?`,
              confirmText: "Quiz Supprimé",
            });
          }}
          title="Supprimer"
        >
          <FaTrash />
        </button>
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={(e) => {
            e.preventDefault();
            setSurveyGroupEditing(contact);
          }}
          title="Modifiez les informations du quiz"
        >
          <FaPenToSquare />
        </button>

        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={(e) => {
            e.preventDefault();

            confirmAction({
              action: async () => {
                await api.post("/surveygroup/toggle", { id: contact._id });
                refresh();
              },
              promptText: `Voulez vous ${
                contact.isActive ? "désactiver" : "activer"
              } ce quiz ?`,
              confirmText: `Quiz ${contact.isActive ? "désactivé" : "activé"}`,
            });
          }}
          title="Changer le status"
        >
          <HiSwitchHorizontal />
        </button>
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={async (e) => {
            e.preventDefault();
            try {
              const data = await api.get("/survey/get", {
                params: { id: contact?._id },
              });
              exportSurveys({ surveys: data.data, name: contact.name });
            } catch (e) {
              console.log(e);
            }
          }}
          title="Télécharger les réponses"
        >
          <FaFileDownload />
        </button>
      </td>
    </tr>
  );
};

const SurveyGroupList = ({
  surveyGroups,
  setSurveyGroupEditing,
  refresh,
  setSelectedSurveyGroup,
  selectedSurveyGroup,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date de création",
        accessor: "createdAt",
      },
      {
        Header: "Nom",
        accessor: "name",
      },
      {
        Header: "Nombre de questions",
        accessor: "e",
      },

      {
        Header: "Status",
        accessor: "c",
      },
      {
        Header: "Actions",
        accessor: "d",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: surveyGroups,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="flex flex-col body-backoffice">
        <div className="-my-2 overflow-x-auto">
          <div
            className="button-Backoffice-div"
            style={{ flexDirection: "row" }}
          >
            <button
              onClick={() => setSurveyGroupEditing({})}
              className="button-Backoffice"
              style={{ maxWidth: "200px" }}
            >
              Ajouter
            </button>
          </div>
          <div className="py-2 align-middle inline-block min-w-full ">
            <div className="shadow overflow-hidden border-gray-200 sm:rounded-lg table">
              <table
                className="min-w-full divide-y divide-gray-200"
                style={{ width: "100%" }}
                {...getTableProps()}
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider p-5"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <SurveyGroupCard
                        selectedSurveyGroup={selectedSurveyGroup}
                        key={key}
                        contact={row.original}
                        setSurveyGroupEditing={setSurveyGroupEditing}
                        refresh={refresh}
                        setSelectedSurveyGroup={setSelectedSurveyGroup}
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<"}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">>"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>

                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="ml-4 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Afficher {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SurveyCard = ({ contact, setSurveyEditing, deleteSurvey, editIndex }) => {
  const [index, setIndex] = useState(contact.index);

  useEffect(() => {
    setIndex(contact.index);
  }, [contact.index, contact]);

  return (
    <tr className="transition-colors duration-200 hover:bg-gray-200">
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {moment(contact?.createdAt).format("YYYY-MM-DD hh:mm")}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.question}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {{ mcq: "Choix multiple", input: "Libre" }[contact.type]}
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          <input
            style={{ borderRadius: 3, width: 50 }}
            value={index}
            onChange={(e) => {
              setIndex(e.target.value);
            }}
            onBlur={(e) =>
              editIndex({ index: e.target.value, id: contact._id })
            }
          />
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.propositions?.map((p) => (
            <p>{p.text}</p>
          ))}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.answers.length}
        </div>
      </td>
      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.propositions?.map((p) => (
            <p>{p.comment || "-"}</p>
          ))}
        </div>
      </td>

      <td className="px-6 py-4 p-5 fs-small">
        <div className="text-sm text-gray-900 text-center">
          {contact.isMandatory ? "Oui" : "Non"}
        </div>
      </td>

      <td
        className="px-4 py-4 text-right text-sm font-medium"
        style={{ display: "flex", justifyContent: "center", gap: 5 }}
      >
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={() => deleteSurvey(contact)}
          title="Supprimer"
        >
          <FaTrash />
        </button>
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={(e) => {
            e.preventDefault();
            setSurveyEditing(contact);
          }}
          title="Modifiez la question"
        >
          <FaPenToSquare />
        </button>
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2 p-5"
          onClick={(e) => {
            e.preventDefault();
            exportSurveys({ surveys: [contact] });
          }}
          title="Télécharger les réponses"
        >
          <FaFileDownload />
        </button>
      </td>
    </tr>
  );
};

const SurveyList = ({
  surveys,
  setSurveyEditing,
  refresh,
  editIndex,
  deleteSurvey,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date de création",
        accessor: "registeredAt",
      },
      {
        Header: "Question",
        accessor: "question",
      },
      {
        Header: "Type",
        accessor: "type",
      },

      {
        Header: "Index",
        accessor: "index",
      },
      {
        Header: "Propositions",
        accessor: "propositions",
      },
      {
        Header: "Réponses",
        accessor: "answers",
      },
      {
        Header: "Commentaire",
        accessor: "commentText",
      },
      {
        Header: "Obligatoire",
        accessor: "isMandatory",
      },
      {
        Header: "Actions",
        accessor: "d",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: surveys,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="flex flex-col body-backoffice">
        <div className="-my-2 overflow-x-auto">
          <div
            className="button-Backoffice-div"
            style={{ flexDirection: "row" }}
          >
            <button
              onClick={() => setSurveyEditing({})}
              className="button-Backoffice"
              style={{ maxWidth: "200px" }}
            >
              Ajouter
            </button>
          </div>
          <div className="py-2 align-middle inline-block min-w-full ">
            <div className="shadow overflow-hidden border-gray-200 sm:rounded-lg table">
              <table
                className="min-w-full divide-y divide-gray-200"
                style={{ width: "100%" }}
                {...getTableProps()}
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider p-5"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <SurveyCard
                        editIndex={editIndex}
                        key={key}
                        contact={row.original}
                        setSurveyEditing={setSurveyEditing}
                        refresh={refresh}
                        deleteSurvey={deleteSurvey}
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<"}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">>"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>

                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="ml-4 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Afficher {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Quizz() {
  const [surveys, setSurveys] = useState();
  const [surveyEditing, setSurveyEditing] = useState();
  const [surveyGroups, setSurveyGroups] = useState([]);
  const [selectedSurveyGroup, setSelectedSurveyGroup] = useState();
  const [surveyGroupEditing, setSurveyGroupEditing] = useState();

  const refresh = async () => {
    try {
      const data = await api.get("/surveygroup/get");
      setSurveyGroups(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSurveys = async () => {
    if (!selectedSurveyGroup) {
      return;
    }
    try {
      const data = await api.get("/survey/get", {
        params: { id: selectedSurveyGroup?._id },
      });
      console.log(data.data);
      setSurveys(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const editIndex = async ({ index, id }) => {
    await api.post("/survey/editIndex", {
      surveyId: id,
      index,
      surveyGroupId: selectedSurveyGroup._id,
    });
    // refresh();
    fetchSurveys();
  };

  const deleteSurvey = async (survey) => {
    confirmAction({
      action: async () => {
        await api.post("/survey/delete", {
          surveyId: survey._id,
          surveyGroupId: selectedSurveyGroup._id,
        });
        refresh();
        fetchSurveys();
      },
      promptText: `Voulez vous supprimer la question ${survey.question} ?`,
      confirmText: "Question Supprimée",
    });
  };

  useEffect(() => {
    fetchSurveys();
  }, [selectedSurveyGroup]);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <SurveyGroupModal
        modalIsOpen={surveyGroupEditing !== undefined}
        refresh={refresh}
        closeModal={() => setSurveyGroupEditing(undefined)}
        surveyGroup={surveyGroupEditing}
      />
      <SurveyModal
        modalIsOpen={surveyEditing !== undefined}
        refresh={() => {
          fetchSurveys();
          refresh();
        }}
        closeModal={() => setSurveyEditing(undefined)}
        survey={surveyEditing}
        surveyGroup={selectedSurveyGroup}
      />
      <div style={{ flex: 6, marginTop: 40 }}>
        <SurveyGroupList
          selectedSurveyGroup={selectedSurveyGroup}
          surveyGroups={surveyGroups}
          refresh={refresh}
          setSelectedSurveyGroup={setSelectedSurveyGroup}
          setSurveyGroupEditing={setSurveyGroupEditing}
        />
        {surveys && (
          <SurveyList
            deleteSurvey={deleteSurvey}
            editIndex={editIndex}
            surveys={surveys || []}
            setSurveyEditing={setSurveyEditing}
            refresh={fetchSurveys}
          />
        )}
      </div>
    </div>
  );
}

const exportSurveys = async ({ surveys, name }) => {
  const isPontonParticipantDic = {};
  const participants = await api.get("/participants");
  participants.data
    .filter((g) => !g.masterId)
    .map((p) => {
      isPontonParticipantDic[p._id] = p.restaurant == "ponton";
    });

  const workbook = new ExcelJS.Workbook();

  surveys.map((survey, i) => {
    const worksheet = workbook.addWorksheet("Question " + (i + 1));
    const data = [];
    data.push([survey.question]);
    if (survey.type === "mcq") {
      const answersCountAll = survey.answers.reduce((tot, curr) => {
        tot[curr.answer] = (tot[curr.answer] || 0) + 1;
        return tot;
      }, {});
      const answersCountBarb = survey.answers
        .filter((a) => !isPontonParticipantDic[a.participantId])
        .reduce((tot, curr) => {
          tot[curr.answer] = (tot[curr.answer] || 0) + 1;
          return tot;
        }, {});
      const answersCountPonton = survey.answers
        .filter((a) => isPontonParticipantDic[a.participantId])
        .reduce((tot, curr) => {
          tot[curr.answer] = (tot[curr.answer] || 0) + 1;
          return tot;
        }, {});

      const propositionDic = survey.propositions.reduce((tot, curr) => {
        tot[curr._id] = curr.text;
        return tot;
      }, {});

      data.push(["", "Ponton", "Barbarossa", "Total"]);

      survey.propositions.map((prop) => {
        data.push([
          prop.text,
          answersCountPonton[prop._id] || 0,
          answersCountBarb[prop._id] || 0,
          answersCountAll[prop._id] || 0,
        ]);
      });

      data.push([]);
      data.push(["Commentaires Ponton"]);
      survey.answers.map((a) => {
        if (a.comment && isPontonParticipantDic[a.participantId]) {
          data.push([propositionDic[a.answer], a.comment]);
        }
      });
      data.push(["Commentaires Barbarossa"]);
      survey.answers.map((a) => {
        if (a.comment && !isPontonParticipantDic[a.participantId]) {
          data.push([propositionDic[a.answer], a.comment]);
        }
      });
    } else {
      data.push(["Ponton"]);
      data.push(
        ...survey.answers
          .filter((a) => isPontonParticipantDic[a.participantId])
          .map((a) => [a.answer])
      );
      data.push([]);
      data.push(["Barbarossa"]);
      data.push(
        ...survey.answers
          .filter((a) => !isPontonParticipantDic[a.participantId])
          .map((a) => [a.answer])
      );
    }
    console.log(data);
    worksheet.addRows(data);
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name || "Quizz"}.xlsx`;
  link.click();
};
