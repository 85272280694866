import "./App.css";
import Form from "./Form";
import Confirm from "./Confirm";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MentionLegales from "./MentionLegales";
import AdminLogin from "./AdminLogin";
import Backoffice from "./Backoffice";
import Quizz from "./Quizz";
import AuthContainer from "./AuthContainer";
import Home from "./Home";
import Album from "./Album";
import TransportForm from "./Transport";
import Cgu from "./Cgu";
import NotificationsPages from "./Notifications";
import ControlPanel from "./ControlPanel";
import ResetPassword from "./ResetPassword";
import Event from "./Event";
import StatsApp from "./StatApp";
import CoursePage from "./Course";

const routes = [
  {
    label: "Home",
    path: "/",
    element: <Home />,
  },
  {
    label: "Transport",
    path: "/transport",
    element: <TransportForm />,
  },
  {
    label: "Reset Password",
    path: "/resetpassword",
    element: <ResetPassword />,
  },
  {
    label: "Confirm",
    path: "/confirmation",
    element: <Confirm />,
  },
  {
    label: "LegalNotices",
    path: "/legalnotice",
    element: <MentionLegales />,
  },
  {
    label: "Cgu",
    path: "/cgu",
    element: <Cgu />,
  },
  {
    label: "Login",
    path: "/login-back",
    element: <AdminLogin />,
  },
  {
    label: "BackOffice",
    path: "/backoffice",
    element: <Backoffice />,
    requireAuth: true,
  },
  {
    label: "Quiz",
    path: "/quiz",
    element: <Quizz />,
    requireAuth: true,
  },
  {
    label: "Album",
    path: "/albums",
    element: <Album />,
    requireAuth: true,
  },
  {
    label: "Notifications",
    path: "/notifications",
    element: <NotificationsPages />,
    requireAuth: true,
  },
  {
    label: "ControlPanel",
    path: "/controlpanel",
    element: <ControlPanel />,
    requireAuth: true,
  },
  {
    label: "Events",
    path: "/events",
    element: <Event />,
    requireAuth: true,
  },

  {
    label: "Course",
    path: "/course",
    element: <CoursePage />,
    requireAuth: true,
  },

  {
    label: "Stats",
    path: "/stats-page",
    element: <StatsApp />,
    requireAuth: true,
  },
];

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <AuthContainer requireAuth={route.requireAuth}>
                    {route.element}
                  </AuthContainer>
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
