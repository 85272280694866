import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";

const localizer = momentLocalizer(moment);

const Event = ({ event }) => {
  const startTime = moment(event.start).format("HH:mm");

  return (
    <div style={{ fontSize: 12 }}>
      {startTime} {event.title}
    </div>
  );
};

export default function CalendarDisplay({ messages }) {
  return (
    <>
      <Calendar
        localizer={localizer}
        events={messages?.map((m) => ({
          title: m.message,
          allDay: false,
          start: moment(m.sendAt),
          end: moment(m.sendAt).add(1, "hour"),
          type: m.type,
        }))}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800 }}
        views={["month", "agenda"]}
        components={{
          event: Event,
        }}
        eventPropGetter={(m) => {
          console.log(m.type);
          return {
            style: {
              backgroundColor: m.type === "SMS" ? "#a82555" : "#258ea8",
            },
          };
        }}
      />
    </>
  );
}
