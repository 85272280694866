import { useState } from "react";
import Chip from "@mui/material/Chip";

const ColorfulChip = ({ label, color }) => {
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
    setIsDeleted(true);
  };

  if (isDeleted) {
    return null;
  }
  return (
    <Chip
      size="small"
      onDelete={handleDelete}
      label={label}
      style={{ backgroundColor: color, color: "white" }}
    />
  );
};

export default ColorfulChip;
